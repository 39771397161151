import {CategoryItemModel} from "./category-item.model";
export class UserCategoryItemModel {
  id: number = 0;
  userId: number = 0;
  tenantId:number = 0;
  categoryId: number = 0;
  category!: CategoryItemModel;
  level: number = 0;

  fromJson(json: any): UserCategoryItemModel {
    this.id = json.id;
    this.userId = json.userId;
    this.categoryId = json.categoryId;
    this.level = json.level;
    this.category = json.category ? new CategoryItemModel().fromJson(json.category) : new CategoryItemModel();
    this.level = json.level || 0;
    return this;
  }

  mapFrom(categoryItem: CategoryItemModel, userId:number, tenantId:number){
    this.userId = userId;
    this.categoryId = categoryItem.id;
    this.level = categoryItem.level;
    this.tenantId = tenantId;
    return this;
  }

  static flatMapFrom(result:UserCategoryItemModel[], categoryItems: CategoryItemModel[], userId:number, tenantId:number){
    for(let category of categoryItems){
      result.push(new UserCategoryItemModel().mapFrom(category, userId, tenantId));
      if(category.children && category.children.length > 0){
        this.flatMapFrom(result, category.children, userId, tenantId);
      }
    }
  }
}
