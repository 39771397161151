import { Pagination } from './pagination.model';
export class TeamFilterOption extends Pagination  {
  keyword: string = '';
  departmentSelected: number = 0;
  applicationSelected: number = 0;
  functionAreaSelected: number = 0;
  highestLevel: number = 0;
  lowestLevel: number = 0;
  orderASC: boolean = false;
  orderByColumn: string = TeamOrderBy[1];

  constructor(
    itemsPerPage: number = 1000
  ) {
    super(itemsPerPage);
  }

  clear() {
    this.keyword = '';
    this.departmentSelected = 0;
    this.applicationSelected = 0;
    this.functionAreaSelected = 0;
    this.highestLevel = 0;
    this.lowestLevel = 0;
    this.currentPage = 1;
  }
}

export enum TeamOrderBy {
  Name = 1,
  Surname = 2,
  EmailAddress = 3,
  TypeName = 4,
  IsConcurrentUser = 5
}

export class TeamsFilters {
  
  currentPage: number;
  keyWord: string;
  itemsPerPage: number;
  timeZoneOffSet: number;
  status: number;
  projectId: string;
  sortDirection:string;

  constructor() {
    this.currentPage = 1;
    this.itemsPerPage = 20;
    this.timeZoneOffSet = new Date().getTimezoneOffset();
    this.keyWord = '';
    this.status = 0;
  }
}
