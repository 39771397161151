import { Injectable, Injector, OnInit } from "@angular/core";
import { ApiResponse, Constants, LocalStorageConstants } from "../shared/helper/constants";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { catchError, map, timeout } from "rxjs/operators";
import { Utility } from "../shared/helper/utilities";
import { Observable, of, throwError } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class BaseService implements OnInit {
  static _isShowPermissionDenied: boolean = false;
  static _lastShowPermissionDenied: number = 0;
  static injector: Injector;

  langDisplay: any;
  protected domain: string = Constants.DomainURL;
  protected domain_v2: string = Constants.DomainURL_V2;
  protected _section: any;
  // protected _section: ScreenSectionService;


  constructor(protected http: HttpClient) { }

  ngOnInit() {
    // this._translate.get(["SERVICE_MESSAGE"]).subscribe((res: any) => {
    //   this.langDisplay = res.SERVICE_MESSAGE;
    // });
  }

  doGet(
    apiUrl: string,
    params?: string,
    token?: string
  ): Observable<any> {
    const headers = this.createHeader(token);

    const url =
      params === undefined
        ? `${this.domain}/${apiUrl}`
        : `${this.domain}/${apiUrl}/${params}`;

    return this.http.get(Utility.encodingURLParameters(url), { headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        const etag = response.headers.get("ETag"); 
        if (etag) localStorage.setItem("etag", etag); 
        return response.body; 
      }),
      catchError((error) => {
        console.error('doGet method error', error);
        console.error('URL:', url);
        return throwError(() => new Error(error.statusText || 'Server Error'));
      })
    );
  }

  doGetWithETag(
    apiUrl: string,
    params?: string,
    token?: string
  ): Observable<any> {
    let etag = localStorage.getItem(LocalStorageConstants.eTag);
    const headers = this.createHeader(null, token, etag);
    const url =
      params === undefined
        ? `${this.domain}/${apiUrl}`
        : `${this.domain}/${apiUrl}/${params}`;

    return this.http.get(Utility.encodingURLParameters(url), { headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        let etag = response.headers.get("ETag"); 
        if (etag) {
          if(etag.startsWith("\"\"") && etag.endsWith("\"\"")) etag = etag.slice(1, etag.length-1);
          localStorage.setItem(LocalStorageConstants.eTag, etag); 
        } 
        return response.body; 
      }),
      catchError((error) => {
        if(error.status === ApiResponse.NOT_MODIFIED){
          return of(error);
        }else{
          console.error('doGet method error', error);
          console.error('URL:', url);
          return throwError(() => new Error(error.statusText || 'Server Error')); 
        }
      })
    );
  }

  doGetWithCredentials(
    apiUrl: string,
    params?: string,
    token?: string
  ): Observable<any> {
    const headers = this.createHeader('', token);

    const url =
      params === undefined
        ? `${this.domain}/${apiUrl}`
        : `${this.domain}/${apiUrl}/${params}`;

    return this.http
      .get(Utility.encodingURLParameters(url), {
        headers,
        withCredentials: true,
      })
      .pipe(
        map((response) => {
          return response; // HttpClient automatically parses JSON
        }),
        catchError((error) => {
          console.error('doGetWithCredentials method error', error);
          console.error('URL:', url);
          return throwError(() => new Error(error.statusText || 'Server Error'));
        })
      );
  }

  doGetV2(
    apiUrl: string,
    params?: string,
    customBaseDomain?: string,
    token?: string
  ): Observable<any> {
    const headers = this.createHeader(token);

    const baseDomain = customBaseDomain || this.domain_v2;
    const url =
      params === undefined || params === null
        ? `${baseDomain}/${apiUrl}`
        : `${baseDomain}/${apiUrl}/${params}`;

    return this.http
      .get(Utility.encodingURLParameters(url), { headers })
      .pipe(
        catchError((error) => {
          console.error('doGetV2 method error', error);
          console.error('URL:', url);
          return throwError(() => new Error(error.statusText || 'Server Error'));
        })
      );
  }

  // protected doProcessGet(apiUrl: string, params?: string): Observable<any> {
  //   let header: Headers;
  //   header = this.createHeader();

  //   let url =
  //     params === undefined || params === null
  //       ? `${this.domain}/${apiUrl}`
  //       : `${this.domain}/${apiUrl}/${params}`;
  //   return this.http
  //     .get(Utility.encodingURLParameters(url), { headers: header })
  //     .map((response: Response) => {
  //       return response.json();
  //     })
  //     .catch((error: any) => {
  //       console.log("doProcessGet method err ", error);
  //       console.log("url " + url);
  //       this.handleError.bind(this);
  //       return Observable.throw(error.statusText);
  //     });
  // }

  doPostRequest(apiUrl: string, data: any): Observable<any> {
    const bodyString = Utility.parserJson(data); // Convert data to JSON
    const headers = this.createHeader();
  
    const url = `${this.domain}/${apiUrl}`;
  
    return this.http
      .post(Utility.encodingURLParameters(url), bodyString, { headers })
      .pipe(
        map((response) => response), // HttpClient auto-parses JSON
        catchError((error) => {
          console.error('doPostRequest method error:', error);
          console.error('URL:', url);
          return throwError(() => {
            const parsedError =
              error.error && typeof error.error === 'string'
                ? JSON.parse(error.error)
                : error.statusText || 'Server Error';
            return new Error(parsedError);
          });
        })
      );
  }

  doPostWithCredentials(apiUrl: string, data: any): Observable<any> {
    const bodyString = Utility.parserJson(data);
    const headers = this.createHeader();

    const url = `${this.domain}/${apiUrl}`;

    const options = {
      headers,
      withCredentials: true
    };

    return this.http.post<any>(url, bodyString, options)
      .pipe(
        map(response => response),
        catchError(error => {
          console.log("doPostWithCredentials method err ", error);
          console.log("url " + url);
          // Handle error here, e.g., return an error observable, log to a service, etc.
          return throwError(() => error.statusText);
        })
      );
  }

  doPost(apiUrl: string, data: any, isCompleteUrl: boolean = false): Observable<any> {
    const bodyString = Utility.parserJson(data);
    const headers = this.createHeader();

    let url = apiUrl;
    if (!isCompleteUrl) {
      url = `${this.domain}/${apiUrl}`;
    }

    return this.http
      .post(Utility.encodingURLParameters(url), bodyString, { headers })
      .pipe(
        map((response) => {
          return response; // HttpClient directly returns parsed JSON
        }),
        catchError((error) => {
          console.error('doPost method error', error);
          console.error('URL:', url);
          return throwError(() => new Error(error.statusText || 'Server Error'));
        })
      );
  }

  doPostV2(apiUrl: string, data: any): Observable<any> {
    const bodyString = Utility.parserJson(data);
    const headers = this.createHeader();

    const url = `${this.domain_v2}/${apiUrl}`;

    return this.http.post<any>(
      Utility.encodingURLParameters(`${this.domain_v2}/${apiUrl}`),
      bodyString,
      { headers }
    )
      .pipe(
        timeout(1200000),
        map(response => response),
        catchError(error => {
          console.log("doPostV2 method err ", error);
          console.log("url " + url);
          // Handle error here, e.g., return an error observable, log to a service, etc.
          return throwError(() => error.statusText);
        })
      );
  }


  doDeleteV2(apiUrl: string, params?: string): Observable<boolean> {
    const headers = this.createHeader();
    const url =
      params === undefined || params === null
        ? `${this.domain_v2}/${apiUrl}`
        : `${this.domain_v2}/${apiUrl}/${params}`;
  
    return this.http
      .delete(Utility.encodingURLParameters(url), { headers })
      .pipe(
        map(() => {
          return true; // Successful delete returns true
        }),
        catchError((error) => {
          console.error('doDeleteV2 method error:', error);
          console.error('URL:', url);
          return throwError(() => new Error(error.statusText || 'Server Error'));
        })
      );
  }

  doPutV2(apiUrl: string, data: any): Observable<any> {
    const bodyString = Utility.parserJson(data);
    const headers = this.createHeader();

    const url = `${this.domain_v2}/${apiUrl}`;

    return this.http.put<any>(
      Utility.encodingURLParameters(`${this.domain_v2}/${apiUrl}`),
      bodyString,
      { headers }
    )
      .pipe(
        timeout(1200000),
        map(response => response),
        catchError(error => {
          console.log("doPutV2 method err ", error);
          console.log("url " + url);
          // Handle error here, e.g., return an error observable, log to a service, etc.
          return throwError(() => error.statusText);
        })
      );
  }

  private getModelAsFromData(data: any) {
    let dataAsFormData = new FormData();

    // create instance vars to store keys and final output
    let keyArr: any[] = Object.keys(data);

    // loop through the object,
    // pushing values to the return array
    keyArr.forEach((key: any) => {
      dataAsFormData.append(key, data[key]);
    });

    // return the resulting array
    return dataAsFormData;
  }

  doPostFileObject(apiUrl: string, data: any): Observable<any> {
    const formData = this.getModelAsFromData(data); // Ensure the data is correctly converted to FormData
    const headers = this.createHeader('file');
  
    const url = `${this.domain}/${apiUrl}`;
  
    return this.http
      .post(Utility.encodingURLParameters(url), formData, { headers })
      .pipe(
        map((response) => {
          return response; // HttpClient automatically parses JSON if response is JSON
        }),
        catchError((error) => {
          console.error('doPostFileObject method error:', error);
          console.error('URL:', url);
          return throwError(() => new Error(error.statusText || 'Server Error'));
        })
      );
  }
  

  // protected doPostFileObjectV2(apiUrl: string, data: any): Observable<any> {
  //   let formData = this.getModelAsFromData(data);
  //   let headers = this.createHeader("file");

  //   let url = `${this.domain_v2}/${apiUrl}`;
  //   return (
  //     this.http
  //       .post(
  //         Utility.encodingURLParameters(`${this.domain_v2}/${apiUrl}`),
  //         formData,
  //         { headers: headers }
  //       )
  //       .map((response: Response) => {
  //         if (response !== undefined) {
  //           return response.json();
  //         }
  //       })
  //       //.catch(this.handleError.bind(this));
  //       .catch((error: any) => {
  //         console.log("doPostFileObjectV2 method err ", error);
  //         console.log("url " + url);
  //         this.handleError.bind(this);
  //         return Observable.throw(error.statusText);
  //       })
  //   );
  // }

  doPostFile(apiUrl: string, data: any): Observable<any> {
    const headers = this.createHeader('file'); // Set headers for file upload
  
    const url = `${this.domain}/${apiUrl}`;
  
    return this.http
      .post(Utility.encodingURLParameters(url), data, { headers })
      .pipe(
        map((response) => {
          return response; // HttpClient handles JSON response automatically
        }),
        catchError((error) => {
          console.error('doPostFile method error:', error);
          console.error('URL:', url);
          return throwError(() => new Error(error.statusText || 'Server Error'));
        })
      );
  }

  doPut(apiUrl: string, data: any): Observable<any> {
    const bodyString = Utility.parserJson(data); // Ensure this utility formats the data properly
    const headers = this.createHeader();
  
    const url = `${this.domain}/${apiUrl}`;
  
    return this.http
      .put(Utility.encodingURLParameters(url), bodyString, { headers })
      .pipe(
        map((response) => {
          return response; // HttpClient automatically parses JSON
        }),
        catchError((error) => {
          console.error('doPut method error:', error);
          console.error('URL:', url);
          return throwError(() => new Error(error.statusText || 'Server Error'));
        })
      );
  }

  doDelete(apiUrl: string): Observable<any> {
    const headers = this.createHeader();
  
    const url = `${this.domain}/${apiUrl}`;
    
    return this.http
      .delete(Utility.encodingURLParameters(url), { headers })
      .pipe(
        map((response) => {
          return response; // HttpClient automatically parses JSON
        }),
        catchError((error) => {
          console.error('doDelete method error:', error);
          console.error('URL:', url);
          return throwError(() => new Error(error.statusText || 'Server Error'));
        })
      );
  }

  doAuthenticateUser(data: any): Observable<any> {
    const usernameOrEmailAddress = encodeURIComponent(data.UsernameOrEmailAddress);
    const dataRequest = `usernameOrEmailAddress=${usernameOrEmailAddress}&password=${data.Password}&platform=${data.platform}`;
    const headers = this.createHeader("authentication");

    const url = `${this.domain}/${Constants.AuthenticateUser}`;

    return this.http.post<any>(url, dataRequest, { headers })
      .pipe(
        map(response => response),
        catchError(error => {
          console.log("doAuthenticateUser method err ", error);
          console.log("url " + url);
          // Handle error here, e.g., return an error observable, log to a service, etc.
          return throwError(() => error.statusText);
        })
      );
  }

  doImpersonateAuthentication(
    apiUrl: string,
    data: any
  ): Observable<any> {
    const dataRequest = `tenantId=${data.tenantId}&userId=${data.userId}&token=${data.token}&impersonatorSocketChannelId=${data.impersonatorSocketChannelId}`;
    const header = this.createHeader("authentication");

    const url = `${this.domain}/${Constants.ImpersonateAuthenticate}`;

    return this.http
      .post(url, dataRequest, { headers: header })
      .pipe(
        map((response: any) => response),
        catchError((error: any) => {
          console.error("doImpersonateAuthentication method error: ", error);
          console.error("URL: ", url);
          return throwError(() => new Error(error.statusText || "Unknown error"));
        })
      );
  }

  private createHeader(type: string = "", token?: string, etag: string =  "") {
    let _tokenKey =
      token && token.trim() !== "" ? token : localStorage.getItem("token");
    let contentType: string = "";
    let headers: any = {
      "Cache-control": "no-cache",
      Pragma: "no-cache",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, PATCH, DELETE",
      "Access-Control-Allow-Headers":
        "Access-Control-Allow-Headers, Origin,Accept, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
      "Access-Control-Allow-Credentials": "true",
      Source: "Web-portal",
    };

    if (_tokenKey !== undefined && _tokenKey !== null) {
      headers.Authorization = _tokenKey;
    }

    if (this._section) {
      let section = this._section.getSection();

      if (section) {
        headers["X-Section"] = section.name;
      }
    }

    if(etag){
      headers["If-None-Match"] = etag;
    }

    switch (type) {
      case "":
      case "json":
        contentType = "application/json";
        break;
      case "text":
        contentType = "text/plain";
        break;
      case "authentication":
        contentType = "application/x-www-form-urlencoded";
        break;
      case "file":
        contentType = "";
        break;
      default:
        break;
    }

    if (contentType.length > 0) {
      headers["Content-Type"] = contentType;
    }

    return new HttpHeaders(headers);
  }

  // private getModelAsFromData(data: any) {
  //   let dataAsFormData = new FormData();

  //   // create instance vars to store keys and final output
  //   let keyArr: any[] = Object.keys(data);

  //   // loop through the object,
  //   // pushing values to the return array
  //   keyArr.forEach((key: any) => {
  //     dataAsFormData.append(key, data[key]);
  //   });

  //   // return the resulting array
  //   return dataAsFormData;
  // }

  private handleError(error: any) {
    return throwError(() => new Error(error));
    /*Add Logger for error.status*/
    // if (error.status === 417) {
    //   let authToken = localStorage.getItem("token");
    //   let backingToHost = localStorage.getItem("BackingToHost");
    //   // if (backingToHost && backingToHost === "false") {
    //   //   this.doProcessGet(Constants.Account + `/RecorverBlockedImpersonator`)
    //   //     .map((res) => res.result)
    //   //     .subscribe((success: boolean) => {
    //   //       localStorage.setItem("BackingToHost", "true");
    //   //       if (success) {
    //   //         this.doGetWithCredentials(
    //   //           Constants.Account + `/BackToImpersonator`
    //   //         )
    //   //           .map((res) => res.result)
    //   //           .subscribe((token) => {
    //   //             let account = {
    //   //               token: token,
    //   //             };
    //   //             this.doImpersonateAuthentication(
    //   //               Constants.ImpersonateAuthenticate,
    //   //               account
    //   //             ).subscribe((res) => {
    //   //               if (!res.success || !res.result) {
    //   //                 return;
    //   //               }
    //   //               console.log("Error code 417 in handleError");
    //   //               AuthCacheService.remove();

    //   //               let redirectUrl =
    //   //                 LinkActionConstants.landingPageUrl +
    //   //                 "?t=" +
    //   //                 res.result.accessToken +
    //   //                 "&backToImpersonator=true";

    //   //               window.location.href = redirectUrl;
    //   //             });
    //   //           });
    //   //       }
    //   //     });
    //   //   return Observable.throw(
    //   //     "The use is being impersonate by another Admin."
    //   //   );
    //   // }
    // } else if (error.status === 501) {
    //   let title =
    //     this.langDisplay && this.langDisplay.CUSTOMER_PLAN_UPDATED
    //       ? this.langDisplay.CUSTOMER_PLAN_UPDATED
    //       : "Customer Plan Updated";
    //   let message =
    //     this.langDisplay && this.langDisplay.PLAN_ACCESS_UPDATED_PLEASE_RELOGIN
    //       ? this.langDisplay.PLAN_ACCESS_UPDATED_PLEASE_RELOGIN
    //       : "Your access has been updated by the System Administrator. Please re-login to Opus to view the updated permissions.";
    //   // this._notification.showError(message, title).subscribe(() => {
    //   //   console.log("Error code 501 in handleError");
    //   //   AuthCacheService.remove();
    //   //   window.localStorage.removeItem(CookieKey.ChannelID);
    //   //   let redirectUrl =
    //   //     window.location.protocol +
    //   //     "//" +
    //   //     Utility.GetRootDomain(window.location.hostname) +
    //   //     ":" +
    //   //     window.location.port;
    //   //   window.location.href = redirectUrl;
    //   // });
    //   return throwError(() => 
    //     new Error("Your access has been updated by the System Administrator. Please re-login to Opus to view the updated permissions.")
    //   );
    // } else if (error.status === 521) {
    //   if (
    //     !BaseService._isShowPermissionDenied &&
    //     (new Date().getTime() - BaseService._lastShowPermissionDenied) / 1000 >
    //     3
    //   ) {
    //     // 3s wait to make sure user doesn't click close 'button' too fast
    //     BaseService._isShowPermissionDenied = true;
    //     // this._notification
    //     //   .showError(
    //     //     "You do not have permission to access.",
    //     //     "Permission Denied"
    //     //   )
    //     //   .subscribe(() => {
    //     //     BaseService._isShowPermissionDenied = false;
    //     //     BaseService._lastShowPermissionDenied = new Date().getTime();
    //     //   });
    //   }

    //   return of({ result: { success: false } });
    // } else if (error.status === 525 && Constants.IsPartner) {
    //   // console.log("Error code 525 in handleError");
    //   // AuthCacheService.remove();
    //   // window.localStorage.removeItem(CookieKey.ChannelID);
    //   let redirectUrl =
    //     window.location.protocol +
    //     "//" +
    //     Utility.GetRootDomain(window.location.hostname) +
    //     ":" +
    //     window.location.port +
    //     "/?e=ErrorSuspended";
    //   window.location.href = redirectUrl;
    //   return Observable.throw(
    //     "Your account has been suspended. Please contact Epilogue Support to restore your account."
    //   );
    // } else if (error.status === 401 || error.status === 410) {
    //   console.log("Error code 401 or 410 in handleError");
    //   AuthCacheService.remove();
    //   window.localStorage.removeItem(CookieKey.ChannelID);
    //   window.location.href =
    //   window.location.protocol +
    //   "//" +
    //   Constants.RootDomain +
    //   ":" +
    //   window.location.port;
    //   return Observable.throw("Authentication token unavailable");
    // } else if (error.status === 403) {
    //   console.log("Error code 403 in handleError");

    //   if (
    //     !BaseService._isShowPermissionDenied &&
    //     this._router.url !== LinkActionConstants.billingInfoUrl &&
    //     this._router.url.indexOf("/landing?") !== 0 &&
    //     this._router.url !== "/" && // REB-3409: no need show notify for billing page
    //     (new Date().getTime() - BaseService._lastShowPermissionDenied) / 1000 >
    //     3
    //   ) {
    //     // 3s wait to make sure user doesn't click close 'button' too fast
    //     BaseService._isShowPermissionDenied = true;
    //     this._notification
    //       .showError(
    //         "You do not have permission to access.",
    //         "Permission Denied"
    //       )
    //       .subscribe(() => {
    //         BaseService._isShowPermissionDenied = false;
    //         BaseService._lastShowPermissionDenied = new Date().getTime();
    //       });
    //   }
    //   return Observable.of({ result: { success: false } });
    // } else {
    //   // exception handle
    //   if (typeof error.json === "function" && error.status !== 0) {
    //     let jsError = error.json();

    //     console.log("default error in handleError", error);

    //     if (jsError.traceId) {
    //       this._notification
    //         .showErrorAndTraceId(jsError.code || 0, jsError.traceId || "")
    //         .subscribe();
    //       return Observable.empty(); // Create an Observable that emits no items but terminates normally
    //     }
    //   }
    //   return Observable.throw(error);
    // }
  }
}
