import { ProjectMemberDictModel } from './project.model';
import { DocumentBranchModel, DocumentCategoryModel, DocumentDepartmentModel } from './document.model';
import { DocumentTypeModel } from './document-type.model';
import { TaskItemModel } from './task-item.model';
import { PermissionMap, TeamRoleEnum } from './team.model';
import { ElementIssueStatus } from './element-issue.model';
import { DocumentMetadataAttributeModel } from './index';
import { DocumentHistoryEventKind } from './v2/document.model';
import { IPagination } from '../shared/interfaces';

export enum CreateTaskDocumentState {
  None = 0,
  ChooseExistingDocument = 1,
  UploadDocument = 2,
  ImportDocument = 3,
  AddWebLink = 4,
  AddEmptyRecording = 5
}

export class CreateTaskModel {
  chosenDocumentId: number = 0;
  isClone: boolean = false;
  taskDetail!: TaskModel;
  updateTaskStateTo!: TaskState;
  urlDocumentWebLink!: string;
}

export class TaskModel {
  id: number = 0;
  projectId: number = -1;
  title: string = '';
  description: string = '';
  projectName: string = '';
  creationDate: string = '';
  dueDate!: Date;
  taskState: string = '';
  status: string = '';
  priority: string = '';
  assigneeId: number = -1;
  assigneeEmail: string = '';
  assigneeName: string = '';
  assigneeRole: string = '';
  assigneeProfile: any;
  issuesCount: number = 0;
  createNewRecording: string = '';
  chooseExistingDocument: string = '';
  uploadDocument: string = '';
  viewThisDocument: string = '';
  stateList: any[] = [];
  departments: TaskDepartment[] = [];
  categories: TaskCategory[] = [];
  documentTypes: TaskDocumentTypeModel[] = [];
  documentBranch: DocumentBranchModel = new DocumentBranchModel();
  isDocumentMetaData: boolean = false;
  hasFMRecording: boolean = false;
  hasRecording: boolean = false;
}


export class TaskDocumentRevision {
  taskId!: number;
  name!: string;
  size!: number;
  mimeType!: string;
  commitId!: string;
  creationTime!: Date;
  type!: number;
}

export class TaskDetailViewModel {
  id: number = 0;
  projectId: number = -1;
  title: string = '';
  description: string = '';
  projectName: string = '';
  creationDate: string = '';
  dueDate?: Date;
  taskState: string = '';
  status?: TaskStatus;
  state?: TaskState;
  priority: string = '';
  assigneeId: number = -1;
  assigneeEmail: string = '';
  assigneeName: string = '';
  assigneeRole: string = '';
  assigneeTeamRole!: TeamRoleEnum;
  assigneeTeamPermission!: PermissionMap[];
  assigneeProfile: any;
  issuesCount: number = 0;
  issues: IssueListModel[] = [];
  stateList: any[] = [];
  projectMembers: ProjectMemberDictModel[] = [];
  latestDocumentRevision!: TaskDocumentRevision;
  recordingId: string = '';
  documentBranch!: DocumentBranchModel;
  isDocumentMetaData: boolean = false;
  hasFMRecording: boolean = false;
  hasRecording: boolean = false;
  fromJson(json: any) {
    if (!json) {
      return this;
    }

    this.id = json.id || 0;
    this.projectId = json.projectId || 0;
    this.title = json.title || '';
    this.description = json.description || '';
    this.projectName = json.projectName || '';
    this.creationDate = json.creationDate || '';
    this.dueDate = json.dueDate ? new Date(json.dueDate) : undefined;
    this.taskState = json.taskState || 0;
    this.state = json.state || 0;
    this.status = json.status || 0;
    this.priority = json.priority || '';
    this.assigneeId = json.assigneeId || 0;
    this.assigneeEmail = json.assigneeEmail || '';
    this.assigneeName = json.assigneeName || '';
    this.assigneeRole = json.assigneeRole || '';
    this.assigneeTeamRole = json.assigneeTeamRole || '';
    this.assigneeTeamPermission = json.assigneeTeamPermission || '';
    this.assigneeProfile = json.assigneeProfile || '';
    this.issuesCount = json.issuesCount || 0;
    this.stateList = json.stateList || [];
    this.recordingId = json.recordingId || '';
    this.isDocumentMetaData = json.isDocumentMetaData || false;
    this.hasFMRecording = json.hasFMRecording || false;
    this.hasRecording = json.hasRecording || false;
    this.issues = json.issues || [];
    this.latestDocumentRevision = json.latestDocumentRevision || null;
    this.projectMembers = json.projectMembers || [];
    this.documentBranch = json.documentBranch ? new DocumentBranchModel().fromJson(json.documentBranch) : new DocumentBranchModel();

    return this;
  }

  get issueCount(): number {
    if (this.documentBranch) {
      return (this.documentBranch.issues.filter(d => d.status !== ElementIssueStatus.Close).length + this.issues.filter(i => i.status !== TaskIssueStatus.Closed).length);
    }
    return 0;
  }
}

export class TaskDepartment {
  departmentId!: number;
  taskId!: number;
  department: any;
}

export class TaskCategory {
  categoryId!: number;
  taskId!: number;
  category: any;
}

export class TaskDocumentTypeModel {
  taskId!: number;
  documentTypeId!: number;
  documentType!: DocumentTypeModel;

  fromJson(obj: any) {
    if (!obj) {
      return this;
    }

    this.taskId = +obj.taskId;
    this.documentTypeId = obj.documentTypeId;
    this.documentType = new DocumentTypeModel().fromJson(obj.documentType);

    return this;
  }
}

export class TaskUpdateStateRequestModel {
  state!: number;
  assigneeId!: number;
  title!: string;
  isSendEmail!: boolean;
  comment!: string;
  needTemporaryRecordPermission!: boolean;
}

export class EditTaskModel {
  id!: number;
  documentBranch!: DocumentBranchModel;
  dueDate!: Date;
  priority: string = '';
  projectId: number = 0;
}

export class ChangeTaskProjectModel {
  taskIds: any[] = [];
  targetProjectId: number = 0;
  proposedCategoryIds: any[] = [];
  proposedDepartmentIds: any[] = [];

  toJson(): any {
    return {
      taskIds: this.taskIds,
      targetProjectId: this.targetProjectId,
      proposedCategoryIds: this.proposedCategoryIds,
      proposedDocumentIds: this.proposedDepartmentIds,
    };
  }
}

export enum TaskState {
  Create = 1,
  Edit = 2,
  QA = 3,
  FinalReview = 4,
  Published = 5
}

export enum TaskPriority {
  Low = 1,
  Normal = 2,
  High = 3
}

export enum TaskStatus {
  Open = 1,
  //Closed = 2
  Done = 2
}

export enum TaskIssueStatus {
  Open = 1,
  Closed = 2
}

export class TaskStateGroupModel {
  state!: number;
  stateName!: string;
  itemCount!: number;
  items: TaskItemModel[] = [];
}

export class IssueListModel {
  creationTime!: string;
  description!: string;
  id!: number;
  taskId!: number;
  status!: number;
  creatorFullName!: string;
  creatorUserId!: number;
  role!: string;
  permissionName!: string;
}

export class TaskIssueModel {
  TaskId: number = 0;
  Description: string = '';
}

export enum TaskDocumentType {
  Recording = 1,
  DocumentFile = 2
}

export class DocumentTaskFilterModel implements IPagination {
  documentIds: number[] = [];
  departmentIds: number[] = [];
  departmentId: number;
  categoryIds: number[] = [];
  contentTypes: string[] = [];
  categoryId: number;
  state: number = null;
  version: string = "";
  author: string = "";
  createdDocumentDate: string;
  updatedDocumentDate: string;
  updatedDateFrom!: string;
  updatedDateTo!: string;
  keyWord: string = '';
  documentTypeId: number = 0;
  bookId: number = 0;
  customAttributes: DocumentMetadataAttributeModel[] = [];
  ownerNames: string[] = [];
  documentHistoryEventKind: DocumentHistoryEventKind = null;
  //paging
  currentPage: number = 1;
  itemsPerPage: number = 20;
  totalPages: number = 0;
  totalItemCount: number = 0;
  projectId: number = null;

  //sort
  currentSortColumn: number = 3;
  currentSortDirectionAsc: boolean = false;
  defaultSortColumn: number = 3;
  //first column sort
  currentSortMandatoryColumn: number = null;
  isShowDashboard: boolean = false;
  isShowDashboardMostPopular: boolean = false;
  isShowDashboardTopUser: boolean = false;
  isShowDashboardAuthor: boolean = false;
  daysBeforeSystemDeleted: number = 7;
  isRecordingDocument: boolean = false;
  isFollowMe: boolean = true;

  clear() {
    this.departmentIds = null;
    this.categoryIds = null;
    this.contentTypes = [];
    this.createdDocumentDate = null;
    this.updatedDocumentDate = null;
    this.updatedDateFrom='';
    this.updatedDateTo='';
    this.keyWord = '';
    this.currentPage = 1;
    this.totalPages = 1;
    this.totalItemCount = 0;
    this.currentSortColumn = 3;
    this.currentSortDirectionAsc = false;
    this.currentSortMandatoryColumn = null;
    this.state = null;
    this.documentTypeId = 0;
    this.projectId = null;
    this.isShowDashboard = false;
    this.isShowDashboardMostPopular = true;
    this.isShowDashboardTopUser = false;
    this.isShowDashboardAuthor = false;
    this.isRecordingDocument = false;
    this.daysBeforeSystemDeleted = 7;
    this.bookId = 0;
    this.ownerNames = [];
  }

  fromJson(json: any): DocumentTaskFilterModel {
    this.totalPages = json.totalPages;
    this.totalItemCount = json.totalCount;
    return this;
  }
}

export enum DocumentTaskColumn {
  NONE = 0,
  TITLE,
  VERSION,
  UPDATED,
  STATE,
  BRANCH,
  TYPE,
  POPULARITY,
  AUTHOR,
  BranchUpdated,
  OWNER
}

export class DocumentTaskModel {
  id: number = 0;
  title: string = '';
  version: string = '';
  updatedDate: Date;
  taskId: number = 0;
  fileName: string = '';
  mimeType: string = '';
  type: number = TaskDocumentType.DocumentFile;
  size: number = 0;

  fromJson(json: any): DocumentTaskModel {
    this.id = json.id || 0;
    this.title = json.title;
    this.version = json.version;
    this.updatedDate = new Date(json.updatedDate);
    this.taskId = json.taskId;
    this.fileName = json.fileName;
    this.mimeType = json.mimeType;
    this.size = json.size;
    this.type = json.type;
    return this;
  }

  get documentType(): TaskDocumentType {
    return this.type;
  }
}

export class TaskIssueInfoDto {
  id!: number;
  taskId!: number;
  description!: string;
  status!: number;
  creationTime!: Date;
  creatorUser!: string;

  fromJson(json: any): TaskIssueInfoDto {
    this.id = json.id;
    this.taskId = json.taskId;
    this.description = json.description;
    this.status = json.status;
    this.creationTime = new Date(json.updatedDate);
    this.creatorUser = json.creatorUser;
    return this;
  }
}

export class UpcomingTasksModel {
  title!: string;
  taskId!: number;
  description!: string;
  priority!: string;
  status!: number;
  taskState!: string;
  dueDate!: string;
  assigneeFullName!: string;
  isToday!: boolean;
  projectName!: string;
}

export class PassDueTasksModel {
  title!: string;
  taskId!: number;
  description!: string;
  priority!: string;
  status!: number;
  taskState!: string;
  dueDate!: string;
  assigneeFullName!: string;
  isToday!: boolean;
  projectName!: string;
}

export enum TaskColumn {
  DueDate = 1,
  Author = 2,
  Accessed = 3,
  LastModifiedTime = 4
}

