import { ApplicationModel } from './application.model';
import { DepartmentModel } from './department.model';
import { TokenPlatform } from '../models';

export class UserFilterModel {
  keyWord: string = '';
  currentPage: number = 1;
  itemsPerPage: number = 20;
  totalItems: number = 0;
  orderASC: boolean = false;
  status: string = '';
  orderByColumn: string = UserOrderBy[1];

  clear() {
    this.keyWord = '';
    this.status = '';
    this.orderASC = false;
    this.totalItems = 0;
    this.itemsPerPage = 20;
    this.currentPage = 1;
  }
}

export class UserInfoModel {
  id: number = 0;
  name: string = '';
  surname: string = '';
  emailAddress: string = '';
  tenantId: number = 0;
  roleName: string = '';
  status: string = '';
  profileImg: string = '';
  isActive: boolean = false;
  isConcurrentUser: boolean = false;
  lockoutEndDateUtc!: Date;
  isOwnerAccount: boolean = false;
  isEmailConfirmed: boolean = false;
  userLoginStatus: UserLoginStatus = UserLoginStatus.Unknown;

  get fullName(): string {
    return `${this.name} ${this.surname}`;
  }

  fromJson(json: any): UserInfoModel {
    if (!json) {
      return this;
    }

    this.id = json.id || 0;
    this.name = json.name || '';
    this.surname = json.surname || '';
    this.emailAddress = json.emailAddress || '';
    this.roleName = json.roleName || '';
    this.status = json.status || '';
    this.profileImg = json.profileImage || '';
    this.isActive = json.isActive;
    this.isConcurrentUser = json.isConcurrentUser;
    this.isOwnerAccount = json.isOwnerAccount;
    this.isEmailConfirmed = json.isEmailConfirmed;
    this.userLoginStatus = json.userLoginStatus;
    this.tenantId = json.tenantId;
    return this;
  }

  toJson(): any {
    return {
      id: this.id,
      name: this.name,
      surname: this.surname,
      emailAddress: this.emailAddress,
      roleName: this.roleName,
      status: this.status,
      tenantId: this.tenantId
    };
  }
}

export enum UserOrderBy {
  Name = 1,
  Surname = 2,
  EmailAddress = 3,
  DisplayName = 4,
  IsConcurrentUser = 5,
  Status = 6,
  LastLoginUserTime = 7,
  RoleName = 8,
  LastLoginTime = 9

}

export class UserApplicationModel {
  userId!: number;
  tenantId!: number;
  Applications: ApplicationModel[] = [];
}

export class UserDetailModel {
  id: number = 0;
  name: string = '';
  surname: string = '';
  fullname: string = '';
  emailAddress: string = '';
  status: string = '';
  roleName: string = '';
  departments!: DepartmentModel[];
  canPublishChanged: boolean = false;
  totalViews: number = 0;
  profileImage: string = null;
  documentViews = [];

  fromJson(json: any): UserDetailModel {
    if (!json) {
      return this;
    }

    this.id = json.id || 0;
    this.name = json.name || '';
    this.surname = json.surname || '';
    this.fullname = json.name || '' + ' ' + json.surname || ''
    this.emailAddress = json.emailAddress || '';
    this.roleName = json.roleName || '';
    this.status = json.status || '';
    this.departments = json.departments.map((json: any) => new DepartmentModel().fromJson(json));
    this.canPublishChanged = json.canPublishChanged;
    this.documentViews = json.documentViews

    return this;
  }

  toJson(): any {
    return {
      id: this.id,
      name: this.name,
      surname: this.surname,
      emailAddress: this.emailAddress,
      roleName: this.roleName,
      status: this.status
    };
  }
}

export class UserLogTimeModel {
  id!: number;
  creationTime!: string;
  systemType!: TokenPlatform;
  tenantId!: number;
  userId!: number;
  userLogType!: userLogType;

  fromJson(json: any): UserLogTimeModel {
    if (!json) {
      return this;
    }

    this.id = json.id || 0;
    this.creationTime = json.creationTime || '';
    this.systemType = json.systemType || '';
    this.tenantId = json.tenantId || '';
    this.userId = json.userId || '';
    this.userLogType = json.userLogType || '';
    return this;
  }
}

export enum userLogType {
  LogIn = 1,
  LogOut = 2,
  Activity = 3
}

export enum UserLoginStatus {
  Unknown = 0,
  LogIn = 1,
  LogOut = 2,
}

export class DeleteUserErrorModel {
  userId: number = 0;
  errorMessage: string = '';
  success: boolean = false;
}
