import { UserModel } from './team.model';
import { UserInfoModel } from './user.model';

export class DocumentLogModel {
  tenantId: number = 0;
  documentId: number = 0;
  authorPublish: string = '';
  authorships: number = 0;
  actionUser!: UserInfoModel | null;
  actionType!: number;
  actionPerformAt: ActionPerformAt = ActionPerformAt.Document;
  docTypeId: number = 0;
  fromJson(json: any): DocumentLogModel {
    if (!json) {
      return this;
    }

    this.tenantId = +json.tenantId || 0;
    this.documentId = json.documentId || 0;
    this.authorPublish = json.authorPublish;
    this.authorships = +json.authorships;
    this.actionUser = json.actionUser ? new UserInfoModel().fromJson(json.actionUser) : null;
    this.actionType = +json.actionType;
    this.actionPerformAt = +json.actionPerformAt;
    this.docTypeId = +json.docTypeId;
    return this;
  }
}

export enum ActionPerformAt {
  Document = 0,
  Branch = 1
}

