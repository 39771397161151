import { CategoryItemModel } from './category-item.model';
import { FilterTime, IPagination } from './../shared/interfaces';
import { DocumentTypeModel } from './document-type.model';
import { DepartmentModel } from './department.model';
import { UserInfoModel } from './user.model';
import { PermissionMap, TeamPermissionText, TeamRoleEnum, TeamRoleMap, TeamRoleOrder } from './team.model';
import { LocaleModel } from './locale.model';
import { TaskState } from './task.model';
import { getMedian, GridId } from '../shared/helper/constants';
import { generate } from 'rxjs';
import { SortOrder, Utility } from '../shared/helper';


export class ProjectModel {
  id: number;
  name: string;
  tenantId: number;
  creationTime: Date;
  description: string;
  dueDate: Date;
  departmentList: Array<ProjectDepartmentModel> = [];
  taskList: Array<ProjectTaskModel> = [];
  userList: Array<ProjectMemberModel> = [];
  status: number = ProjectStatus.Active;
  departments: Array<ProjectDepartmentModel> = [];
  hierarchyCategories: Array<CategoryItemModel> = [];
  categories: Array<ProjectCategoriesModel> = [];
  members: Array<ProjectMemberModel> = [];
  canCreateNewTask: boolean = false;
  canEditProject: boolean = false;
  documentTypes: Array<ProjectDocumentTypeModel> = [];
  languages: Array<ProjectLanguageModel> = [];
  defaultLanguageId: number;
  attributes: Array<ProjectAttributesModel> = [];
  roles: Array<ProjectRoleModel> = [];

  projectAdmin: string = "";
  openIssues: number = 0;
  progress: number = 0;

  get departmentName(): string {
    return this.departmentList.sort((a, b) => DepartmentModel.sortByDisplayName(a.deparment, b.deparment)).map(d => d.deparment != undefined ? d.deparment.displayName : "").join(', ');
  }

  get categoryFullName(): string {
    return this.categories.sort((a, b) => CategoryItemModel.sortByStringNameAsc(a.category, b.category)).map(c => c.category != undefined ? c.category.categoryFullName : "").join(', ');
  }

  get statusText(): string {
    return ProjectStatus[this.status];
  }

  get hasProjectAdmin(): boolean {
    let result: boolean = false;
    this.members.some(function (user) {
      result = user.isProjectAdmin;
      return user.isProjectAdmin;
    });

    return result;
  }

  get statusColor(): string {
    if (this.status === ProjectStatus.Active) {
      return '#71D545';
    } else if (this.status === ProjectStatus.Archived) {
      return '#E32B2C';
    } else {
      return '';
    }
  }

  public clear() {
    this.id = null;
    this.name = '';
    this.tenantId = null;
    this.creationTime = new Date();
    this.description = '';
    this.dueDate = null;
    this.taskList = [];
    this.userList = [];
    this.departments = [];
    this.categories = [];
    this.members = [];
    this.status = ProjectStatus.Active;
    this.canCreateNewTask = false;
    this.documentTypes = [];
    this.defaultLanguageId = null;
    this.attributes = [];
    this.roles = [];
  }

  isProjectAdmin(userId: number): boolean {
    return this.members.findIndex(m => m.role === TeamRoleEnum.ProjectAdmin && m.memberId === userId) >= 0;
  }

  isTeamLead(userId: number): boolean {
    return this.members.findIndex(m => m.role === TeamRoleEnum.TeamLead && m.memberId === userId) >= 0;
  }

  fromJson(json: any): ProjectModel {
    if (!json) {
      return this;
    }

    this.id = json.id;
    this.name = json.name;
    this.creationTime = new Date(json.creationTime);
    this.dueDate = json.dueDate ? new Date(json.dueDate) : null;
    this.description = json.description;
    this.status = json.status;
    this.tenantId = json.tenantId;

    this.departmentList = json.departments ? json.departments.map((json: any) => new ProjectDepartmentModel().fromJson(json)) : [];
    if (json.categories) {
      let categories: CategoryItemModel[] = json.categories
        .map((json: any) => new ProjectCategoriesModel().fromJson(json)).filter(function (projectCategoriesModel) {
          if (projectCategoriesModel.category) {
            return true; 
          }
          return false;// skip
        })
        .map((c: ProjectCategoriesModel) => c.category);
      if (categories) {
        this.hierarchyCategories = CategoryItemModel.mapToTree(categories);
      }
    }
    this.categories = json.categories ? json.categories.map((json: any) => new ProjectCategoriesModel().fromJson(json)) : [];
    this.taskList = json.tasks ? json.tasks.map((json: any) => new ProjectTaskModel().fromJson(json)) : null;
    this.members = json.members ? json.members.map((json: any) => new ProjectMemberModel().fromJson(json)) : [];
    this.members.forEach(member => {
      member.projectId = this.id;
    });

    if (json.documentTypes) {
      this.documentTypes = json.documentTypes ? json.documentTypes.map((json: any) => new ProjectDocumentTypeModel().fromJson(json)) : null;
    }

    if (json.languages) {
      this.languages = json.languages.map((json: any) => new ProjectLanguageModel().fromJson(json));
    }

    if (json.attributes) {
      this.attributes = json.attributes.map((json: any) => new ProjectAttributesModel().fromJson(json));
    }

    this.defaultLanguageId = json.defaultLanguageId;

    if (json.roles) {
      this.roles = json.roles ? json.roles.map((json: any) => new ProjectRoleModel().fromJson(json)) : null;
    }

    return this;
  }
}

export enum ProjectStatus {
  Active = 1,
  Archived = 2
}

export enum ProjectColumn {
  DueDate = 1,
  LastModificationTime = 2
}




export class ProjectDepartmentModel {
  id: number;
  deparment: DepartmentModel;
  projectId: number;
  departmentId: number;

  fromJson(json: any): ProjectDepartmentModel {
    this.id = json.id;
    this.deparment = new DepartmentModel().fromJson(json.department);
    this.projectId = json.projectId;
    return this;
  }

  toJson(): any {
    return {
      id: this.id,
      deparment: this.deparment ? this.deparment.toJson() : null,
      projectId: this.projectId,
      departmentId: this.departmentId
    };
  }

}

export class ProjectCategoriesModel {
  public projectId: number = 0;
  public categoryId: number = 0;
  public category: CategoryItemModel = null;

  fromJson(json: any): ProjectCategoriesModel {
    this.projectId = json.projectId;
    this.categoryId = json.categoryId;
    this.category = new CategoryItemModel().fromJson(json.category);
    return this;
  }

  toJson(): any {
    return {
      projectId: this.projectId,
      categoryId: this.categoryId,
      category: this.category ? this.category.toJson(): null
    }
  }

}

export class ProjectAttributesModel {
  public projectId: number = 0;
  public customAttributeId: number = 0;
  public value: string = '';
  public isRequired: boolean = false;

  constructor () {
    this.projectId = 0;
    this.customAttributeId = 0;
    this.value = '';
    this.isRequired = false;
  }

  fromJson(json: any): ProjectAttributesModel {
    this.projectId = json.projectId;
    this.customAttributeId = json.customAttributeId;
    this.value = json.value;
    return this;
  }

  toJson(): any {
    return {
      projectId: this.projectId,
      customAttributeId: this.customAttributeId,
      value: this.value
    };
  }
}

export class ProjectDocumentTypeModel {
  public projectId: number = 0;
  public documentTypeId: number = 0;
  public documentType: DocumentTypeModel = null;

  fromJson(json: any): ProjectDocumentTypeModel {
    this.projectId = json.projectId;
    this.documentTypeId = json.documentTypeId;
    this.documentType = new DocumentTypeModel().fromJson(json.documentType);
    return this;
  }
}

export class ProjectTaskModel {
  id: number = 0;
  title: string = '';
  state: number = 0;
  description: string = '';
  priority: number = 0;
  projectId: number = 0;
  _assigneeId: number = 0;

  fromJson(json: any): ProjectTaskModel {
    if (!json) {
      return this;
    }

    this.id = json.id;
    this.title = json.title;
    this.description = json.description;
    this.priority = json.priority;
    this.projectId = json.projectId;
    this.state = json.state;
    this._assigneeId = json.assigneeId || 0;

    return this;
  }

  toJson(): any {
    return {
      id: this.id,
      title: this.title,
      description: this.description,
      priority: this.priority,
      projectId: this.projectId,
      state: this.state,
      _assigneeId: this.assigneeId
    };
  }

  get assigneeId(): number {
    return this._assigneeId;
  }
}

export class ProjectMemberModel {
  memberId: number = 0;
  role: number = 0;
  permissions: number = 0;
  projectId: number = 0;
  member: UserInfoModel = null;
  memberFullName: string;

  get roleName(): string {
    return TeamRoleMap[this.role];
  }

  get permissionText(): string {
    if (this.role !== TeamRoleEnum.TeamLead) {
      return '';
    }

    let permissionText = [PermissionMap.Record, PermissionMap.Edit, PermissionMap.QA, PermissionMap.Master]
    .filter(p => this.permissions & p)
    .map(p => PermissionMap[p])
    .join(', ');

    return permissionText.replace('Master', 'Final Review / Publish Task');
  }

  fromJson(json: any): ProjectMemberModel {
    this.memberId = json.memberId;
    this.role = json.role;
    this.permissions = json.permissions;
    this.projectId = json.projectId || 0;
    this.member = json.member ? new UserInfoModel().fromJson(json.member) : null;
    this.memberFullName = json.memberFullName;

    return this;
  }

  toJson(): any {
    return {
      memberId: this.memberId,
      role: this.role,
      permissions: this.permissions,
      projectId: this.projectId
    }
  }

  get isProjectAdmin(): boolean {
    return (this.role == TeamRoleEnum.ProjectAdmin);
  }

  get teamRoleEnumOrder(): number {
    return TeamRoleOrder.findIndex( (val) => val === this.role);
  }
}

export class ProjectFilterModel implements FilterTime, IPagination {
  departmentId: number = 0;
  categoryId: number = 0;
  creationDate: string;
  dueDate: string;
  keyWord: string = '';
  status: ProjectStatus = ProjectStatus.Active;
  timeZoneOffSet: number = new Date().getTimezoneOffset();
  //paging
  currentPage: number = 1;
  itemsPerPage: number = 8;
  totalPages: number = 0;
  totalItemCount: number = 0;
  //sort
  currentSortColumn: number = 2;
  currentSortDirectionAsc: boolean = false;

  lastModifiedDateFrom: string = "";
  lastModifiedDateTo: string = "";

  clear() {
    this.departmentId = 0;
    this.departmentId = 0;
    this.creationDate = null;
    this.dueDate = null;
    this.keyWord = '';
    this.status = ProjectStatus.Active;
    this.currentPage = 1;
    this.totalPages = 1;
    this.totalItemCount = 0;
    this.timeZoneOffSet = new Date().getTimezoneOffset();
  }

  fromJson(json: any): ProjectFilterModel {
    this.totalPages = json.totalPages;
    this.totalItemCount = json.totalCount;
    return this;
  }
}

export class ProjectMemberDictModel {
  memberId: number = 0;
  memberDislayName: string = '';
  role: number = 0;
  permissions: number = 0;

  get permissionNames(): string {
    let permissions: string[] = [];
    let permissionNames: string = '';

    TeamPermissionText.forEach(permission => {
      if (this.permissions & permission.id) {
        permissions.push(permission.text);
      }
    });

    permissionNames = permissions.join(', ');
    return permissionNames;
  }

  fromJson(json: any) {
    if (!json) {
      return this;
    }

    this.memberId = json.memberId;
    this.memberDislayName = json.memberDislayName;
    this.role = json.role;
    this.permissions = json.permissions;

    return this;
  }
}

export class ProjectLanguageModel {
  public projectId: number = 0;
  public localeId: number = 0;
  public locale: LocaleModel = null;

  fromJson(json: any): ProjectLanguageModel {
    if (!json) {
      return this;
    }
    this.projectId = json.projectId;
    this.localeId = json.localeId;
    this.locale = new LocaleModel().fromJson(json.locale);

    return this;
  }
}


export class ProjectTaskStateTimeModel {
  public id: number = 0;
  public name: string = '';
  public taskStateList: Array<TaskStateModel> = [];
  public medianCreate: number = 0.0;
  public medianEdit: number = 0;
  public medianQA: number = 0;
  public medianFinalReview: number = 0;
  fromJson(json: any): ProjectTaskStateTimeModel {
    if (!json) {
      return this;
    }
    this.id = json.id;
    this.name = json.name;
    this.taskStateList = json.tasks.map((json: any) => new TaskStateModel().fromJson(json));
    /*calculate median of TaskState: Create,Edit,QA,FinalReview of a Project */
    let day: number = 1 * 24 * 60 * 60 * 1000; //milliseconds to day
    let listCreate: number[] = [];
    let listEdit: number[] = [];
    let listQA: number[] = [];
    let listFinalReview: number[] = [];
    for (let index in this.taskStateList) {
      listCreate.push(this.taskStateList[index].medianCreate);
      listEdit.push(this.taskStateList[index].medianEdit);
      listQA.push(this.taskStateList[index].medianQA);
      listFinalReview.push(this.taskStateList[index].medianFinalReview);
    }
    this.medianCreate = parseFloat((getMedian(listCreate)/day).toFixed(1));
    this.medianEdit = parseFloat((getMedian(listEdit)/day).toFixed(1));
    this.medianQA = parseFloat((getMedian(listQA)/day).toFixed(1));
    this.medianFinalReview = parseFloat((getMedian(listFinalReview)/day).toFixed(1));
    return this;
  }
}
export class TaskStateModel {
  public id: number = 0;
  public projectId: string = '';
  public stateTimeList: Array<StateTimeModel> = [];
  public medianCreate: number = 0;
  public medianEdit: number = 0;
  public medianQA: number = 0;
  public medianFinalReview: number = 0;
  fromJson(json: any): TaskStateModel {
    if (!json) {
      return this;
    }
    this.id = json.id;
    this.projectId = json.projectId;
    this.stateTimeList = json.taskStateTimes.map((json: any) => new StateTimeModel().fromJson(json));
    /*calculate median of TaskState: Create,Edit,QA,FinalReview of a Task */
    let listCreate: number[] = [];
    let listEdit: number[] = [];
    let listQA: number[] = [];
    let listFinalReview: number[] = [];
    for (let index in this.stateTimeList) {
      switch (this.stateTimeList[index].stateFrom) {
        case TaskState.Create:
          listCreate.push(this.stateTimeList[index].timeSpent);
          break;
        case TaskState.Edit:
          listEdit.push(this.stateTimeList[index].timeSpent);
          break;
        case TaskState.QA:
          listQA.push(this.stateTimeList[index].timeSpent);
          break;
        case TaskState.FinalReview:
          listFinalReview.push(this.stateTimeList[index].timeSpent);
          break;
      }
    }
    this.medianCreate = getMedian(listCreate);
    this.medianEdit = getMedian(listEdit);
    this.medianQA = getMedian(listQA);
    this.medianFinalReview = getMedian(listFinalReview);
    return this;
  }
}
export class StateTimeModel {
  public id: number;
  public taskId: string = '';
  public stateFrom: TaskState;
  public stateTo: TaskState;
  public timeSpent: number = 0;
  fromJson(json: any): StateTimeModel {
    if (!json) {
      return this;
    }
    this.id = json.id;
    this.taskId = json.taskId;
    this.stateFrom = json.stateFrom;
    this.stateTo = json.stateTo;
    this.timeSpent = json.timeSpent;
    return this;
  }
}

export class ProjectRoleModel {
  id: number;
  projectId: number;
  roleId: number;

  fromJson(json: any): ProjectRoleModel {
    this.id = json.id;
    this.roleId = json.roleId;
    this.projectId = json.projectId;
    return this;
  }

  toJson(): any {
    return {
      id: this.id,
      projectId: this.projectId,
      roleId: this.roleId
    };
  }

}