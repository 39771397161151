import {Roles } from './authorization.model';

export class Role {
  id: number = 0;
  name: string = '';
  displayName: string = '';

  static fromJson(json: any): Role {
    return new Role().fromJson(json);
  }

  fromJson(json: any): Role {
    this.id = json.id || 0;
    this.name = json.name || '';
    this.displayName = json.displayName || '';
    return this;
  }

  isEpilogueAdmin(): boolean {
    return this.displayName === Roles.EpilogueAdminRoleName;
  }

  isEpilogueSupportUser(): boolean {
    return this.displayName === Roles.EpilogueSupportUserRoleName;
  }

  isTranslator(): boolean {
    return this.displayName === Roles.EpilogueTranslatorRoleName;
  }

  isCustomerAdmin(): boolean {
    return this.displayName === Roles.CustomerAdminRoleName;
  }

  isContentPowerUser(): boolean{
    return this.displayName === Roles.CustomerPowerUserRoleName;
  }

  isContentUser(): boolean {
    return this.displayName === Roles.CustomerContentUserRoleName;
  }

  isPublicApiAdmin(): boolean {
    return this.displayName === Roles.PublicApiAdmin;
  }
}

export enum RoleMap {
  'ProjectAdmin' = 1,
  'TeamLead' = 2,
  'Editor' = 3,
  'Recorder' = 4,
  'QA' = 5
}
