import { Router } from "@angular/router";
import { AuthCacheService, SignedUrlType } from "../../services/auth-cache.service";
import { AwsDefines } from "./aws-constants";
import { Constants, LinkActionConstants } from "./constants";
import { LoginService } from "../../services/login.service";
import { SettingService } from "../../services/setting.service";
import { CategoryItemModel, DocumentCategoryModel, DocumentModel, MatchedDocumentContextModel, StepAction, UserDetailProfile } from "../../models";
import { MenuService } from "../../services/menu.service";
import { IMultiSelectTexts } from "../components/dropdown-multiselect/dropdown-multiselect.component";
import { from, Observable } from "rxjs";
import moment from "moment";

export class Utility {

  public static blockedFileExtensions: string[] = [".ade", ".adp", ".app", ".asx", ".asmx", ".ascx", ".master", ".xap", ".aspx", ".asp", ".bas", ".bat", ".cer", ".chm",
    ".cla", ".class", ".cmd", ".cnt", ".com", ".cpl", ".crt", ".csh", ".der", ".exe", ".fxp", ".gadget", ".grp", ".hlp",
    ".hpj", ".hta", ".inf", ".ins", ".isp", ".its", ".jar", ".js", ".jse", ".ksh", ".lnk", ".mad", ".maf", ".mag",
    ".mam", ".maq", ".mar", ".mas", ".mat", ".mau", ".mav", ".maw", ".mcf", ".mda", ".mdb", ".mde", ".mdt", ".mdw",
    ".mdz", ".msc", ".msh", ".msh1", ".msh1xml", ".msh2", ".msh2xml", ".mshxml", ".msi", ".msp", ".mst", ".ocx", ".ops",
    ".osd", ".pcd", ".pif", ".pl", ".plg", ".prf", ".prg", ".ps1", ".ps1xml", ".ps2", ".ps2xml", ".psc1", ".psc2",
    ".pst", ".reg", ".scf", ".scr", ".sct", ".shb", ".shs", ".tar", ".tmp", ".url", ".vb", ".vbe", ".vbp", ".vbs", ".vsmacros",
    ".ws", ".wsc", ".wsf", ".wsh", ".xbap", ".xnk", ".xsf", ".apk", ".appx", ".appxbundle", ".cab", ".diagcab", ".diagcfg",
    ".diagpack", ".dll", ".dmg", ".ex", ".ex_", ".hta", ".img", ".ins", ".iso", ".jnlp", ".lib", ".msix", ".msixbundle",
    ".nsh", ".pif", ".sys", ".vhd", ".vxd", ".xll", ".application", ".appref-ms", ".bgi", ".py", ".bin", ".macbin", ".jsp"];

  public static blockedFileExtensionAndMimeTypes: any[] = [
    {
      ext: '.adp',
      mimeType: ['audio/adpcm']
    },
    {
      ext: '.cer',
      mimeType: ['application/pkix-cert']
    },
    {
      ext: '.chm',
      mimeType: ['application/vnd.ms-htmlhelp']
    },
    {
      ext: '.cla',
      mimeType: ['application/vnd.claymore']
    },
    {
      ext: '.class',
      mimeType: ['application/java-vm']
    },
    {
      ext: '.csh',
      mimeType: ['application/x-csh']
    },
    {
      ext: '.der',
      mimeType: ['application/x-x509-ca-cert']
    },
    {
      ext: '.exe',
      mimeType: ['application/x-exe']
    },
    {
      ext: '.fxp',
      mimeType: ['application/vnd.adobe.fxp']
    },
    {
      ext: '.hlp',
      mimeType: ['application/winhlp']
    },
    {
      ext: '.jar',
      mimeType: ['application/java-archive']
    },
    {
      ext: '.js',
      mimeType: ['application/javascript']
    },
    {
      ext: '.mag',
      mimeType: ['application/vnd.ecowin.chart']
    },
    {
      ext: '.mdb',
      mimeType: ['application/x-msaccess']
    },
    {
      ext: '.msh',
      mimeType: ['model/mesh']
    },
    {
      ext: '.prf',
      mimeType: ['application/pics-rules']
    },
    {
      ext: '.tar',
      mimeType: ['application/x-tar']
    },
    {
      ext: '.xbap',
      mimeType: ['application/x-ms-xbap']
    }
  ];

  

  public static CheckFromDateGreaterOrEqualToDate(fromDate: Date, toDate: Date, Type: moment.unitOfTime.Diff = 'day'): boolean {
    fromDate.setHours(23);
    fromDate.setMinutes(59);
    fromDate.setSeconds(59);
    fromDate.setMilliseconds(999);
    toDate.setHours(23);
    toDate.setMinutes(59);
    toDate.setSeconds(59);
    toDate.setMilliseconds(999);
    let fDate = moment(fromDate);
    let tDate = moment(toDate);
    let result: number = tDate.diff(fDate, Type);
    if (result < 0) {
      return true;
    }
    return false;
  }

  //get full of today: include time
  public static getToday(): Date {
    let today = moment().toDate();
    today.setHours(23);
    today.setMinutes(59);
    today.setSeconds(59);
    today.setMilliseconds(999);
    return today;
  }

  public static CheckFromDateGreaterToDate(fromDate: Date, toDate: Date, Type: moment.unitOfTime.Diff = 'day'): boolean {
    let fDate = moment(fromDate);
    let tDate = moment(toDate);
    let result: number = tDate.diff(fDate, Type);
    if (result < 1) {
      return true;
    }
    return false;
  }

  public static setDueDate(dueDate: Date): Date {
    let date = moment(dueDate).toDate();
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    return date;
  }

  public static GetCategoryTaskItemIds(categories: CategoryItemModel []): DocumentCategoryModel [] {
    let result: DocumentCategoryModel [] = [];

    for (let parent of categories) {
      if (parent.children.length > 0) {
        for (let children1 of parent.children) {
          if (children1.children.length > 0) {
            for (let children2 of children1.children) {
              let temp: DocumentCategoryModel = new DocumentCategoryModel();
              temp.categoryId = children2.id;
              result.push(temp);
            }
          }
          let temp: DocumentCategoryModel = new DocumentCategoryModel();
          temp.categoryId = children1.id;
          result.push(temp);
        }
      }

      let temp: DocumentCategoryModel = new DocumentCategoryModel();
      temp.categoryId = parent.id;
      result.push(temp);
    }

    return result;
  }

  public static updateMatadata(source: any, destination: any, fieldUpdate: string[] = null) {
    let fieldNeedToUpdate = ['name', 'orderNumber', 'isRequired'];
    if (fieldUpdate && fieldUpdate.length > 0) {
      fieldNeedToUpdate = fieldUpdate;
    }

    fieldNeedToUpdate.forEach(attr => {
      destination[attr] = source[attr];
    });
  }

  public static isBlockedFile(ext: string, file: any): Observable<boolean> {
    let promise = new Promise<boolean>((resolve) => {
      if (Utility.blockedFileExtensions.indexOf(ext) !== -1) {
        resolve(true);
        return;
      }

      let reader = new FileReader();
      reader.onloadend = (event: any) => {
        let type = Utility.getRealMineTypeImageFile(
          event && event.target && event.target.result ? event.target.result : new ArrayBuffer(0));

        if (Utility.blockedFileExtensionAndMimeTypes.some((b: any) => {
          return b.mimeType.indexOf(type) !== -1;
        })) {
          resolve(true);
          return;
        }

        resolve(false);
      };
      reader.readAsArrayBuffer(file);
    });

    return from(promise);
  }

  public static hasVerticalScroll(node: any) {
    if (node == undefined) {
      if (window.innerHeight) {
        return document.body.offsetHeight > window.innerHeight;
      }
      else {
        return document.documentElement.scrollHeight >
          document.documentElement.offsetHeight ||
          document.body.scrollHeight > document.body.offsetHeight;
      }
    }
    else {
      return node.scrollHeight > node.offsetHeight;
    }
  }

  public static isJsonString(str: any) {
    if (!str) return false;
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  public static isMediaManager(url) {
    return (url.indexOf(LinkActionConstants.mediaManagerPageUrl) !== -1);
  }

  public static isBook(url) {
    let isBook =
      /\/books\/\d+/.test(url) &&
      url.indexOf("isDocViewer") === -1;

    return isBook;
  }

  public static isEditor(url) {
    let isEditor =
      url.indexOf(LinkActionConstants.editorPageUrl) !== -1 &&
      url.indexOf("/books/") === -1 &&
      url.indexOf("isDocViewer") === -1;

    return isEditor;
  }

  public static getMultiLanguages(langDisplay: any, value: string): string {
    if (value === '' || value === undefined) {
      return '';
    }
    if (value === '_BLANK_') {
      return '';
    }

    let key = value.toUpperCase().replace(/ /ig, '_');
    return langDisplay && langDisplay[key] ? langDisplay[key] : value;
  }

  public static detectIE() {
    const match = navigator.userAgent.search(/(?:Edge|MSIE|Trident\/.*; rv:)/);
    let isIE = false;

    if (match !== -1) {
      isIE = true;
    }

    return isIE;
  }

  public static getRealMineTypeImageFile(arrayBuffer: ArrayBuffer) {
    if (!arrayBuffer.byteLength)
      return null;
    let arr = (new Uint8Array(arrayBuffer)).subarray(0, 4);
    let header = '';
    for (let i = 0; i < arr.length; i++) {
      header += arr[i].toString(16);
    }
    let type = 'unknown';
    switch (header) {
      case '89504e47':
        type = 'image/png';
        break;
      case '47494638':
        type = 'image/gif';
        break;
      case 'ffd8ffe0':
      case 'ffd8ffe1':
      case 'ffd8ffe2':
        type = 'image/jpeg';
        break;
      case '0010':
        type = 'image/x-icon';
        break;
      case '4d5a900':
        type = 'application/x-exe';
        break;
      case '436f6e73':
        type = 'application/x-tar';
        break;
      case 'cafebabe':
        type = 'application/java-vm';
        break;
      default:
        type = '';
        break;
    }
    return type;
  }

  public static scaleRect(rect, scaleFactor) {
    if (rect && typeof rect.left === 'number' && typeof rect.top === 'number' &&
      typeof rect.width === 'number' && typeof rect.height === 'number') {
      rect.left = Math.round(rect.left * scaleFactor);
      rect.top = Math.round(rect.top * scaleFactor);
      rect.width = Math.round(rect.width * scaleFactor);
      rect.height = Math.round(rect.height * scaleFactor);
    }
  }

  public static addPrefixBeforeUrl(url: string) {
    if (url && !/^(f|ht)tps?:\/\//i.test(url)) {
      url = 'http://' + url;
    }
    return url;
  }

  public static getDefaultLogo(isHostUser: boolean) {
    if (isHostUser) {
      return "/assets/opus-nxt-logo.svg";
    }
    else {
      return "/assets/opus-nxt-logo.svg";
    }
  }

  public static copyToClipBoard(value: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  public static isBrowserApp(process: string) {
    if (!process) {
      process = '';
    }

    let result = false;
    for (let i = 0, length = Constants.BROWSER_PROCESSES.length; i < length; i++) {
      result = Constants.BROWSER_PROCESSES[i].toLowerCase() === process.toLowerCase();

      if (result) {
        break;
      }
    }

    return result;
  }

  public static getAsteriskURLPattern(url: string) {
    url = url.replace(/[-\/\\^$+?.()|[\]{}]/g, '\\$&').replace(/\*+/g, '.*').replace('\\.*', '');
    if (url.indexOf('*') !== -1) {
      url = '^' + url + '$';
    }

    return url;
  }

  public static capitalizeFirstLetter(str: string) {
    return str[0].toUpperCase() + str.slice(1);
  }


  public static getHTMLDecode(caption: string) {
    let tag = window.document.createElement('DIV');
    tag.innerHTML = caption;
    return tag.innerText;
  }

  public static getContentsBy(contents: DocumentModel[], context: MatchedDocumentContextModel, matchingThreshold: number) {
    if (!contents || !context || !context.processName) {
      return [];
    }

    if (!context.threshold && context.threshold !== 0) {
      context.threshold = matchingThreshold;
    }

    let additionalContexts = [];

    if (context.additionalContext) {
      try {
        additionalContexts = JSON.parse(context.additionalContext);
      } catch (e) {
      }
    }

    return contents.filter(doc => {
      if (doc.currentBranch.contexts) {
        let keep = doc.currentBranch.contexts.filter(
          con => {
            if (!con.processName) {
              con.processName = '';
            }
            return context.processName.toUpperCase() === con.processName.toUpperCase()
              || (this.isBrowserApp(context.processName) && con.processName.toUpperCase() === Constants.SPECIAL_BROWSER);
          });
        return keep.length > 0;
      }
      return false;
    }).filter(doc => {
      if (doc.currentBranch.contexts) {
        let keep = doc.currentBranch.contexts.filter(
          con => {
            if (!con.processName) {
              con.processName = '';
            }
            if (!con.caption) {
              con.caption = '';
            }
            if (!con.url) {
              con.url = '';
            }
            if (!con.additionalContext) {
              con.additionalContext = '';
            }
            let processNameCondition = context.processName.toUpperCase() === con.processName.toUpperCase()
              || (this.isBrowserApp(context.processName) && con.processName.toUpperCase() === Constants.SPECIAL_BROWSER);

            if (!processNameCondition) {
              return false;
            }

            let additionalContextCondition = !context.additionalContext
              || additionalContexts.indexOf(con.additionalContext) !== -1;

            if (!additionalContextCondition) {
              return false;
            }

            con.caption = con.caption.split('').filter((c) => {
              return c.charCodeAt(0) < 2000;
            }).join('');

            Constants.COMMON_CAPTIONS.forEach((caption: string) => {
              con.caption = con.caption.toLowerCase().replace(caption.toLowerCase(), '').trim();
            });

            con.caption = this.getHTMLDecode(con.caption);
            context.caption = this.getHTMLDecode(context.caption);

            let urlCondition = false;
            if (con.url.trim().length === 0 || context.url.trim().length === 0) {
              urlCondition = true;
            }
            else if (con.url.indexOf('*') !== -1) {
              try {
                let regex = new RegExp(this.getAsteriskURLPattern(con.url));
                urlCondition = regex.test(context.url);
              } catch (e) { }
            }
            else {
              urlCondition = context.url.toUpperCase() === con.url.toUpperCase() || !con.url || !context.url;
            }

            if (!urlCondition) {
              return false;
            }

            let captionCondition = false;
            if (con.caption.trim().length === 0 || context.caption.trim().length === 0) {
              captionCondition = true;
            }
            else if (con.caption.indexOf('*') !== -1) {
              try {
                let regex = new RegExp(this.getAsteriskCaptionPattern(con.caption), 'i');
                captionCondition = regex.test(context.caption);
              } catch (e) { }
            }
            else {
              let similarity = this.getSimilarity(con.caption, context.caption);
              captionCondition = similarity >= context.threshold;
            }

            return captionCondition;
          });
        return keep.length > 0;
      }
      return false;
    });
  }

  public static getLevenshteinDistance(s: string, t: string) {
    if (!s.length) return t.length;
    if (!t.length) return s.length;

    let matrix = [];

    // increment along the first column of each row
    let i, length;
    for (i = 0, length = t.length; i <= length; i++) {
      matrix[i] = [i];
    }

    // increment each column in the first row
    let j;
    for (j = 0, length = s.length; j <= length; j++) {
      matrix[0][j] = j;
    }

    for (i = 1; i <= t.length; i++) {
      for (j = 1; j <= s.length; j++) {
        if (t.charAt(i - 1) === s.charAt(j - 1)) {
          matrix[i][j] = matrix[i - 1][j - 1];
        } else {
          matrix[i][j] = Math.min(matrix[i - 1][j - 1] + 1, // substitution
            Math.min(matrix[i][j - 1] + 1, // insertion
              matrix[i - 1][j] + 1)); // deletion
        }
      }
    }

    return matrix[t.length][s.length];
  }

  public static getSimilarity(caption1: string, caption2: string) {
    caption1 = caption1.toLowerCase();
    caption2 = caption2.toLowerCase();
    let maxLen = Math.max(caption1.length, caption2.length);
    if (!maxLen) {
      return 100;
    }
    let distance = this.getLevenshteinDistance(caption1, caption2);
    return (maxLen - distance) * 100 / maxLen;
  }

  public static getAsteriskCaptionPattern(caption: string) {
    // handle special chars
    caption = caption.replace(/[-\/\\^$+?.()|[\]{}]/g, '\\$&');

    if (caption.indexOf('*') !== -1) {
      caption = caption.replace(/\*+/g, '.*');
      caption = caption.replace('|', '\\|');
      caption = '^' + caption + '$';
    }

    return caption;
  }

  public static updateFaviconForPartner() {
    if (!Constants.IsPartner) return;
    let url = Utility.getFaviconUrl(Constants.SystemName);
    let link = document.querySelector("link[rel*='icon']");
    if (link) {
      link['href'] = Utility.getImageUrlNoneCache(url); // `data:image/png;base64,${image}`;
    }
  }

  public static getFaviconUrl(tenantName: string = ''): string {
    let signedUrl = AuthCacheService.getSignedUrl(SignedUrlType.uploads);
    const url = `${AwsDefines.AWS_S3_BUCKET_NAME_LOGO}/`.replace(signedUrl.bucketName, signedUrl.cloudfront);

    if (!tenantName || tenantName === '' || Constants.IsPartner) {
      return Utility.getImageUrlNoneCache(url + `recorder-logo.png?${signedUrl.value}`);
    }

    return Utility.getImageUrlNoneCache(url + `${tenantName}.png?${signedUrl.value}`);
  }

  public static convertHTMLEntitiesToUnicodeString(html: string) {
    let e = document.createElement('div');
    e.innerHTML = html;
    return e.innerHTML;
  }

  public static getQueryParams(url: string): any {
    if (url.indexOf('?') > -1) {
      const queryString = url.split('?')[1];
      if (queryString.indexOf('=') > -1) {
        const queryArray = queryString.split('&');
        const params: any = {};
        queryArray.forEach(query => {
          const qArr = query.split('=');
          params[`${qArr[0]}`] = qArr[1];
        });
        return params;
      }
    }
    return {};
  }

  public static flattenAray(array: any): any {
    return array.reduce((flat: any, toFlattern: any) => {
      return flat.concat(Array.isArray(toFlattern) ? Utility.flattenAray(toFlattern) : toFlattern);
    }, []);
  }

  public static getImageUrlNoneCache(url: string): string {
    if (!url) {
      return url;
    }
    if (url.indexOf('?') >= 0) {
      return `${url}&d=${Date.now().toString()}`;
    }
    return `${url}?d=${Date.now().toString()}`;
  }

  public static setLocalStorageWithExpiry(key: any, value: any, ttl: any) {
    const now = new Date()

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    }

    localStorage.setItem(key, JSON.stringify(item))
  }

  public static sortAttributeOptions = (a: any, b: any) => {
    let nameA = a.orderNumber;
    let nameB = b.orderNumber;
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  public static getLogoUrl(tenantName: string = ''): string {
    let signedUrl = AuthCacheService.getSignedUrl(SignedUrlType.uploads);
    const url = `${AwsDefines.AWS_S3_BUCKET_NAME_LOGO.replace(signedUrl.bucketName, signedUrl.cloudfront)}/`;

    if (!tenantName || tenantName === '') {
      if (Constants.IsPartner) {
        return url + `logo.png?${signedUrl.value}`;
      } else {
        return url + `${Constants.tenantHostName}?${signedUrl.value}`;
      }
    }

    return url + `${tenantName}.png?${signedUrl.value}`;
  }

  public static parserJson(data: any): any {
    return JSON.stringify(data);
  }

  public static encodingURLParameters(url: string) {
    if (url == null || url === '') return url;

    let protocol = '';
    let domain = '';
    let path = '';

    if (url.startsWith('http')) {
      protocol = url.substring(0, url.indexOf('//') + 2);
      path = url.substring(protocol.length, url.length);
      domain = path.substring(0, path.indexOf('/') + 1);
      path = path.substring(domain.length, path.length);
    } else {
      path = url;
    }

    let queryStrIndex = path.indexOf('?');

    let queryString = queryStrIndex > 0 ? path.substring(queryStrIndex + 1, path.length) : '';

    path = queryStrIndex > 0 ? path.substring(0, queryStrIndex) : path;

    let queryStringParams = queryString.length === 0 ? [] : (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    let pathParams = path.split('/');

    queryString = '';
    queryStringParams.forEach(param => {
      let pair = param.split('=');
      queryString += `${pair[0]}=${encodeURIComponent(pair[1] || '')}&`;
    });
    if (queryString.length > 0) queryString = queryString.substring(0, queryString.length - 1);

    path = '';
    pathParams.forEach(param => {
      path += `${encodeURIComponent(param)}/`;
    });
    if (path.length > 0) path = path.substring(0, path.length - 1);

    if (queryString.length > 0) queryString = '?' + queryString;

    url = protocol + domain + path + queryString;

    return url;
  }

  public static getLocalStorageWithExpiry(key: any) {
    try {
      const itemStr = localStorage.getItem(key);
      // if the item doesn't exist, return null
      if (!itemStr) {
        return null;
      }
      const item = JSON.parse(itemStr);
      const now = new Date();
      // compare the expiry time of the item with the current time
      if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key);
        return null;
      }
      return item.value;
    } catch (error) {
      return null;
    }
  }

  public static isEmptyGuid(guid: string): boolean {
    if (!guid) {
      return true;
    }

    // Match with guid empty
    if (guid === '00000000-0000-0000-0000-000000000000' || guid === '00000000000000000000000000000000') {
      return true;
    }

    return false;
  }

  public static Guid(): string {
    let s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    };

    return s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4();
  }

  public static GetTenantFromUrl(url: string) {
    let domainName: string = '';
    let tenantName: string = '';
    let dotSymbol = 0;

    for (let iPos = 0; iPos < url.length; iPos++) {
      if ((url[iPos] === '/') || (url[iPos] === ':')) {
        domainName = url.substring(0, iPos);
        break;
      }
    }
    if (domainName.length === 0) {
      domainName = url;
    }

    /* Take tenant name in domain*/
    for (let iPos = domainName.length - 1; iPos >= 0; iPos--) {
      if (dotSymbol >= 2) {
        tenantName = domainName[iPos] + tenantName;
      }
      if (domainName[iPos] === '.') {
        dotSymbol++;
      }
    }
    if (tenantName.indexOf('.') > -1) { // 4 levels
      return tenantName.split('.')[0];
    }
    else { // 3 levels
      return Constants.IsPartner ? '' : tenantName;
    }
  }

  public static disableFirstLoginUpdateDoneStepAndRedirectToDashBoard(loginService: LoginService, settingService: SettingService, _router: Router, currentUser: UserDetailProfile, menuService: MenuService) {
    loginService.disableContentUserFirstLogin().subscribe();
    settingService.updateUserGettingStartedStep(currentUser.tenant.id, currentUser.id ?? 0, StepAction.DONE_ALL_STEP).subscribe();
    menuService.getMenuSystem().subscribe(menuLists => {
      if (menuLists.result && menuLists.result.data && menuLists.result.data.length && menuLists.result.data[0].childs && menuLists.result.data[0].childs.length) {
        let urlRedirect = menuLists.result.data[0].childs[0].action.replace(/[.]/g, '');
        _router.navigate([urlRedirect]);
      } else {
        _router.navigate([LinkActionConstants.dashboardPageUrl]);
      }
    }, error => {
      menuService.clearMenus();
      _router.navigate([LinkActionConstants.dashboardPageUrl]);
    });
  }

  public static getDocumentTypeTextSelection(array: any): IMultiSelectTexts {
    return {
      allSelected: array && array.EDIT_DOCTYPE_SELECTED_ALL ? array.EDIT_DOCTYPE_SELECTED_ALL : 'All doctype selected',
      checked: array && array.EDIT_DOCTYPE_SELECTED ? array.EDIT_DOCTYPE_SELECTED : 'doctype selected',
      checkedPlural: array && array.EDIT_DOCTYPES_SELECTED ? array.EDIT_DOCTYPES_SELECTED : 'doctypes selected',
      defaultTitle: array && array.EDIT_DOCTYPE_CHOOSE ? array.EDIT_DOCTYPE_CHOOSE : 'Choose doctype'
    };
  }
  public static compare2Arrays(array1: number[], array2: number[]) {
    let temp = [];
    for (let i in array1) {
      if (array2.indexOf(array1[i]) === -1) temp.push(array1[i]);
    }
    for (let i in array2) {
      if (array1.indexOf(array2[i]) === -1) temp.push(array2[i]);
    }
    return temp.length === 0;
  }

  public static generateGUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

}
