import { environment } from "../../../environments/environment";

export class AwsConstants {
  public static awsDomain = environment.awsDomain;
  public static readonly awsPartnerDomain = environment.awsPartnerDomain;
  public static readonly ENV = environment.ENV;
  public static readonly isDev = environment.isDev;
}
export class AwsDefines {
  public static AWS_S3_BUCKET_LINK = environment.AWS_S3_BUCKET_LINK;
  public static AWS_S3_BUCKET_NAME_RECORDING = environment.AWS_S3_BUCKET_NAME_RECORDING;
  public static AWS_S3_BUCKET_NAME_PUBLISHED_DOCUMENT = environment.AWS_S3_BUCKET_NAME_PUBLISHED_DOCUMENT;
  public static AWS_S3_BUCKET_NAME_IMAGES = environment.AWS_S3_BUCKET_NAME_IMAGES;
  public static AWS_S3_BUCKET_NAME_SCREENSHOT_SHARED = environment.AWS_S3_BUCKET_NAME_SCREENSHOT_SHARED;
  public static AWS_S3_BUCKET_NAME_LOGO = environment.AWS_S3_BUCKET_NAME_LOGO;
  public static AWS_S3_BUCKET_WEBSITE_STATIC_LINK = environment.AWS_S3_BUCKET_WEBSITE_STATIC_LINK;
  public static AWS_S3_BUCKET_NAME_PDF = environment.AWS_S3_BUCKET_NAME_PDF;

}
