export class AddressModel {
  address: string;
  address2: string;
  city: string;
  county: string;
  state: string;
  zipCode: string;

  constructor() {
    this.address = '';
    this.address2 = '';
    this.city = '';
    this.zipCode = '';
    this.county = '';
  }
}

export class AddressFormatModel {
  id: number;
  description: string;

  fromJson(json: any): AddressFormatModel {
    if (!json) {
      return this;
    }

    this.id = json.id;
    this.description = json.description;
    return this;
  }
}
