import { ActionKind, ControlEvent, ControlTypeKind, ImageEvent, KeyEvent, RecorderEvent } from './recorder-event.model';
import { Rect } from './rect.model';
import { Point } from './point.model';
import { Utility } from '../shared/helper/utilities';
import { AuthCacheService, SignedUrlType } from '../services/auth-cache.service';
import { ElementTypeKind } from './element-type.model';
import { ElementTypeId } from './element-type.model';
import * as _ from "lodash";

export class DocumentElement {
  public static readonly EmptyResource: string = '%%ControlImage%%';

  defaultDisplayText: string = '';
  elementTypeId: number = 0;
  id: string = '';
  kind: ElementTypeKind = ElementTypeKind.Generic;
  overrideDisplaytext: string = '';
  parentId: string | null = null;
  parent: DocumentElement | null = null;
  manualDisplayText: string | null = null;
  subElements: DocumentElement [] = [];
  name: string = '';
  manualName: string = '';
  isBookPrint: boolean = false;
  isSelected?: boolean = false;
  isStaticContentElementLoading: boolean = false;
  included: boolean = false;
  elementPageNumber : number = 0;

  constructor() {
    this.id = Utility.Guid();
    this.kind = ElementTypeKind.Generic;
  }

  get displayName(): string {
    return this.manualName ? this.manualName : this.name;
  }

  set displayName(text: string) {
    this.manualName = text;
  }

  get displayText(): string {
    if (this.manualDisplayText === null || typeof this.manualDisplayText === 'undefined') {
      return DocumentElement.replaceImgTagCallToS3(this.defaultDisplayText);
    }

    return DocumentElement.replaceImgTagCallToS3(this.manualDisplayText);
  }

  set displayText(text: string) {
    if (this.defaultDisplayText !== Utility.convertHTMLEntitiesToUnicodeString(text) || this.defaultDisplayText.length === 0) {
      this.manualDisplayText = DocumentElement.replaceImgTagCallToCloudfront(text);
    }
  }

  get screenImageId(): string {
    if (this.kind === ElementTypeKind.ConsolidateTableStep) {
      let stepIndex = this.subElements.findIndex(child => child instanceof StepElement);
      return stepIndex > -1 ? (this.subElements[stepIndex] as StepElement).screenImage : '';
    }

    return '';
  }

  get screenArea(): Rect {
    if (this.kind === ElementTypeKind.ConsolidateTableStep) {
      let top = ~~Math.min(...this.subElements.filter(e => e instanceof StepElement).map(e => (e as StepElement).screenRect.top));
      let left = ~~Math.min(...this.subElements.filter(e => e instanceof StepElement).map(e => (e as StepElement).screenRect.left));
      let width = ~~Math.max(...this.subElements
        .filter(e => e instanceof StepElement)
        .map(e => (e as StepElement).screenRect.width + (e as StepElement).screenRect.left)) - left;
      let height = ~~Math.max(...this.subElements
        .filter(e => e instanceof StepElement)
        .map(e => (e as StepElement).screenRect.height + (e as StepElement).screenRect.top)) - top;

      return new Rect(left, top, width, height);
    }

    return new Rect();
  }

  get originalScreenArea(): Rect {
    if (this.kind === ElementTypeKind.ConsolidateTableStep) {
      let stepIndex = this.subElements.findIndex(child => child instanceof StepElement);
      return stepIndex > -1 ? (this.subElements[stepIndex] as StepElement).originalScreenRect : new Rect();
    }

    return new Rect();
  }

  get stepCaption(): string {
    if (this.kind === ElementTypeKind.ConsolidateTableStep) {
      let stepIndex = this.subElements.findIndex(child => child instanceof StepElement);
      return stepIndex > -1 ? (this.subElements[stepIndex] as StepElement).screenImage : '';
    }

    return '';
  }

  static fromDto(dto: any): DocumentElement | null {
    if (!dto) {
      return null;
    }
    if (dto.kind === ElementTypeKind.Step) {
      return new StepElement().fromJson(dto);
    } else if (dto.kind === ElementTypeKind.Group) {
      return new GroupElement().fromJson(dto);
    } else if (dto.kind === ElementTypeKind.Note) {
      return new NoteElement().fromJson(dto);
    } else if (dto.kind === ElementTypeKind.Audio) {
      return new AudioElement().fromJson(dto);
    } else if (dto.kind === ElementTypeKind.Video) {
      return new VideoElement().fromJson(dto);
    } else if (dto.kind === ElementTypeKind.DocumentLink) {
      return new DocumentLinkElement().fromJson(dto);
    } else if (dto.kind === ElementTypeKind.CoreDocument) {
      return new CoreDocumentElement().fromJson(dto);
    } else if (dto.kind === ElementTypeKind.Section) {
      return new SectionElement().fromJson(dto);
    } else if (dto.kind === ElementTypeKind.TrueFalseQuiz) {
      return new QuizElement(ElementTypeKind.TrueFalseQuiz).fromJson(dto);
    } else if (dto.kind === ElementTypeKind.MultipleQuiz) {
      return new QuizElement(ElementTypeKind.MultipleQuiz).fromJson(dto);
    } else if (dto.kind === ElementTypeKind.FillInTheBlankQuiz) {
      return new QuizElement(ElementTypeKind.FillInTheBlankQuiz).fromJson(dto);
    } else if (dto.kind === ElementTypeKind.PowerPointSlideStep) {
      return new PowerPointSlideStepElement().fromJson(dto);
    } else if (dto.kind === ElementTypeKind.Fork) {
      return new ForkElement().fromJson(dto);
    } else if (dto.kind === ElementTypeKind.Path) {
      return new PathElement().fromJson(dto);
    }
    else {
      return new DocumentElement().fromJson(dto);
    }
  }

  static getSelectableElementIndex(element: DocumentElement, elements: DocumentElement[]): number {
    let array = _.flatten(elements.map(e => {
      if (e instanceof GroupElement) {
        return e.subElements;
      } else if (e instanceof ForkElement) {
        let childElements: any = [];
        if (e.subElements && e.subElements.length) {
          e.subElements.forEach(p => {
            if (p.subElements && p.subElements.length) {
              childElements = childElements.concat(p.subElements);
            }
          });
        }
        return childElements;
      } else {
        return e;
      }
    }));
    return array.findIndex((e: DocumentElement) => e.id === element.id);
  }

  static getSelectableElements(from: number, to: number, elements: DocumentElement[]): DocumentElement[] {
    return Utility.flattenAray(elements.map(e => {
      if (e instanceof GroupElement) {
        return e.subElements;
      } else if (e instanceof ForkElement) {
        let childElements: any = [];
        if (e.subElements && e.subElements.length) {
          e.subElements.forEach(p => {
            if (p.subElements && p.subElements.length) {
              childElements = childElements.concat(p.subElements);
            }
          });
        }
        return childElements;
      } else {
        return e;
      }
    })).slice(from, to + 1);
  }

  static getStepIndex(element: DocumentElement, elements: DocumentElement []): number {
    let array = _.flatten(elements.map(e => (e instanceof GroupElement) ? e.subElements : e));
    array = _.flatten(array.map((e: any) => e.kind === ElementTypeKind.ConsolidateTableStep ? e.subElements : e));
    array = array.filter((e: DocumentElement) => {
      if (e instanceof StepElement) {
        if (e.parent && e.parent.kind === ElementTypeKind.Path) {
          let stepElements = e.parent.subElements.filter(el => el.kind === ElementTypeKind.Step);
          if (stepElements.findIndex(sub => sub.id === e.id) === 0) {
            return true;
          }
        } else {
          return true;
        }
      }
      return false;
    });
    if (element) {
      return array.findIndex((e: any) => e.id === element.id);
    }
    return 0;
  }

  static getActiveElement(element: DocumentElement) {
    if (element.kind === ElementTypeKind.Step && (element as StepElement).inactive) {
      return null;
    } else if (element.subElements.length) {
      element.subElements = element.subElements.map((el) => {
        return DocumentElement.getActiveElement(el);
      }).filter(el => el !== null);

      if (element.kind === ElementTypeKind.Group && element.subElements.length <= 1) {
        if (element.subElements.length === 1 && element.subElements[0].kind !== ElementTypeKind.ConsolidateTableStep) {
          element = element.subElements[0];
        } else if (element.subElements.length === 0) {
          element = null as any;
        }
      }
    }
    return element;
  }

  static flattenArraySectionElement(element: SectionElement, elements: DocumentElement[]) {
    elements.push(element);
    if (element.subElements.length > 0) {
      element.subElements.forEach(e => {
        if (e instanceof SectionElement) {
          elements = elements.concat(this.flattenArraySectionElement(e, []));
        } else {
          elements.push(e);
        }
      });
    }
    return elements;
  }


  static decreaseIndentElement(id: string, elements: DocumentElement[]) {
    let len = elements.length;
    for (let index = 0; index < len; index++) {
      let element = elements[index];
      for (let subIndex = 0; subIndex < element.subElements.length; subIndex++) {
        let subElement = element.subElements[subIndex];
        if (subElement.id === id) {
          if (!element.parent) {
            element.subElements.splice(subIndex, 1);
            subElement.parent = element.parent;
            elements.splice(index + 1, 0, subElement);
          } else {
            let parentIndex = element.parent.subElements.findIndex(e => e.id === element.id);
            element.subElements.splice(subIndex, 1);
            subElement.parent = element.parent;
            element.parent.subElements.splice(parentIndex + 1, 0, subElement);
          }
          return;
        }
      }
      if (element.subElements.length > 0) {
        this.decreaseIndentElement(id, element.subElements);
      }
    }
  }


  static increaseIndentElement(id: string, elements: DocumentElement[]) {
    let preSection: DocumentElement | null = null;
    for (let index = 0; index < elements.length; index++) {
      let element = elements[index];
      if (element instanceof SectionElement && element.id !== id) {
        preSection = element;
      }
      if (element.id === id) {
        if (index > 0 && preSection) {
          elements.splice(index, 1);
          element.parent = preSection;
          preSection.subElements.push(element);
          return;
        }
      }
      if (element.subElements.length > 0) {
        this.increaseIndentElement(id, element.subElements);
      }
    }
  }

  static moveElementsInsideSection(elements: DocumentElement[]) {
    let element = this.checkIfElementOutsideSection(elements);
    if (!element) {
      return;
    }
    this.moveElementInsideSection(element.id, elements);
    this.moveElementsInsideSection(elements);
  }

  static moveElementInsideSection(id: string, elements: DocumentElement[]) {
    for (let index = 0; index < elements.length; index++) {
      let element = elements[index];
      if (element.id === id) {
        elements.splice(index, 1);
        let preElement = elements[index - 1];
        this.moveElementAtLastSection(element, preElement);
        return;
      }
      if (element.subElements.length > 0) {
        this.moveElementInsideSection(id, elements[index].subElements);
      }
    }
  }

  static moveElementAtLastSection(element: DocumentElement, preElement: DocumentElement) {
    let sections = preElement.subElements.filter(e => e.kind === ElementTypeKind.Section);
    let lastSection = sections.length > 0 ? sections[sections.length - 1] : null;
    if (!lastSection) {
      element.parent = preElement;
      preElement.subElements.push(element);
      return;
    } else {
      this.moveElementAtLastSection(element, lastSection);
    }
  }

  static checkIfElementOutsideSection(elements: DocumentElement[]): DocumentElement | null {
    let lastSectionIndex = -1;
    let result: DocumentElement | null = null;
    for (let index = 0; index < elements.length; index++) {
      let element = elements[index];
      if (element instanceof SectionElement) {
        lastSectionIndex = index;
      } else {
        let checkConslution = false;
        if (element.kind === ElementTypeKind.Conclusion) {
          checkConslution = true;
          let sectionElements = elements.filter(e => e.kind === ElementTypeKind.Section);
          let lastSection = sectionElements.length > 0 ? sectionElements[sectionElements.length - 1] : null;
          if (lastSection && elements.lastIndexOf(lastSection) < elements.lastIndexOf(element) && element.parent === null) {
            checkConslution = false;
          }
        }
        if (lastSectionIndex >= 0 && (element.kind === ElementTypeKind.CoreDocument || element.kind === ElementTypeKind.Introduction || element.kind === ElementTypeKind.DocumentLink || element.kind === ElementTypeKind.TableOfContent || checkConslution)) {
          return element;
        }
      }
      result = this.checkIfElementOutsideSection(element.subElements);
      if (result) {
        return result;
      }
    }
    return result;
  }

  static deleteElementRemainChildren(id: string, elements: DocumentElement[], parent: DocumentElement): [DocumentElement | null, DocumentElement[]] | null {
    let result: [DocumentElement | null, DocumentElement[]] | null = null;
    for (let index = 0; index < elements.length; index++) {
      let element = elements[index];
      if (element.id === id) {
        let subs = element.subElements;
        elements.splice(index, 1, ...subs);
        subs.forEach(s => s.parent = parent);
        return [parent, subs];
      }
      result = this.deleteElementRemainChildren(id, element.subElements, element);
      if (result) {
        return result;
      }
    }
    return result;
  }

  static deleteElementMoveChildrenToMainStepList(id: string, targetElements: DocumentElement[], elements: DocumentElement[], positionalElementIndex: number): [DocumentElement | null, DocumentElement[]] | null {
    let result: [DocumentElement | null, DocumentElement[]] | null = null;

    for (let index = 0; index < elements.length; index++) {
      let element = elements[index];
      if (element.id === id) {
        let subs = element.subElements;
        elements.splice(index, 1);
        targetElements.splice(positionalElementIndex, 0, ...subs);
        subs.forEach(s => s.parent = null);
        return [null, subs];
      }
      result = this.deleteElementMoveChildrenToMainStepList(id, targetElements, element.subElements, positionalElementIndex);
      if (result) {
        return result;
      }
    }
    return result;
  }

  static updateSectionTitleRenamed(elements: DocumentElement[]) {
    elements.forEach(e => {
      if (e instanceof SectionElement) {
        if (!e.manualDisplayText) {
          e.manualDisplayText = e.defaultDisplayText;
        }
      }
      if (e.subElements.length > 0) {
        this.updateSectionTitleRenamed(e.subElements);
      }
    });
  }

  static renameSectionElements(elements: DocumentElement[]) {
    let index: number = 0;
    elements.forEach(element => {
      if (element instanceof SectionElement) {
        let e = element as SectionElement;
        let parent = e.parent && e.parent instanceof SectionElement ? e.parent as SectionElement : null;
        index++;
        let sectionIndex = parent !== null ? parent.sectionNumber + '.' + index : index + '';
        e.sectionNumber = sectionIndex;
        let level = (sectionIndex.match(/\./g) || []).length;
        switch (level) {
          case 0: {
            e.sectionDefaultName = 'Chapter';
            break;
          }
          case 1: {
            e.sectionDefaultName = 'Section';
            break;
          }
          default: {
            e.sectionDefaultName = 'Sub-Section';
            break;
          }
        }
        if (!e.manualDisplayText) {
          this.editSectionTitle(e);
        }
        if (e.manualName){
          e.sectionDefaultName = e.manualName;
          e.sectionNumber = '';
        }
        e.sectionTitle = e.sectionDefaultName;
        if (e.subElements.length > 0) {
          this.renameSectionElements(e.subElements);
        }
      }
    });
  }

  static editSectionTitle(element: SectionElement) {
    let contentHtml = document.createElement('div');
    contentHtml.innerHTML = DocumentElement.replaceImgTagCallToS3(element.manualDisplayText ? element.manualDisplayText : element.defaultDisplayText);
    let titleDefaultIndex =  contentHtml.getElementsByClassName('section_default_title_index');
    let defaultIndex = titleDefaultIndex.length > 0 ? titleDefaultIndex[0].innerHTML.trim() : '';
    let titleDefaultName = contentHtml.getElementsByClassName('section_default_title_name');
    let defaultName = titleDefaultName.length > 0 ? titleDefaultName[0].innerHTML.trim() : '';
    let titleUserFilled = contentHtml.getElementsByClassName('section_title_user_filled');
    let userTitle = titleUserFilled.length > 0 ? titleUserFilled[0].innerHTML.trim() : '';
    if (!element.manualDisplayText) {
      element.manualDisplayText = element.defaultDisplayText;
      if (defaultIndex && element.sectionNumber) {
        element.manualDisplayText = element.manualDisplayText.replace(defaultIndex, element.sectionNumber);
      }
      if (titleDefaultName && element.sectionDefaultName) {
        element.manualDisplayText = element.manualDisplayText.replace(defaultName, element.sectionDefaultName);
        defaultName = element.sectionDefaultName;
      }
    } else {
      if (defaultIndex && element.sectionNumber && element.overrideSectionNumber.length == 0) {
        if(element.displaySectionIndex == true){
          element.manualDisplayText = element.manualDisplayText.replace(defaultIndex, element.sectionNumber);
        }
      }
      if (element.sectionDefaultName && defaultName && element.overrideSectionDefaultName.length == 0){
        element.manualDisplayText = element.manualDisplayText.replace(defaultName, element.sectionDefaultName);
        defaultName = element.sectionDefaultName;
      }
    }
    element.sectionTitle = defaultName + ' ' + userTitle;
  }

  static setSectionTitleFromManual(elements: DocumentElement[]) {
    elements.forEach(element => {
      if (element instanceof SectionElement) {
        if (element.manualDisplayText) {
          let contentHtml = document.createElement('div');
          contentHtml.innerHTML = element.manualDisplayText;
          let titleDefaultName = contentHtml.getElementsByClassName('section_default_title_name');
          let defaultName = titleDefaultName && titleDefaultName.length > 0 ? titleDefaultName[0].innerHTML.trim() : '';
          let titleUserFilled = contentHtml.getElementsByClassName('section_title_user_filled');
          let userTitle = titleUserFilled && titleUserFilled.length > 0 ? titleUserFilled[0].innerHTML.trim() : '';
          element.sectionDefaultName = defaultName;
          element.sectionTitle = defaultName + ' ' + userTitle;
          if (element.subElements.length > 0) {
            this.setSectionTitleFromManual(element.subElements);
          }
        }
      }
    });
  }

  static removeElement(id: string, elements: DocumentElement[]) {
    for (let index = 0; index < elements.length; index++) {
      let element = elements[index];
      if (element.id === id) {
        elements.splice(index, 1);
        break;
      } else if (element.subElements.length > 0) {
        this.removeElement(id, element.subElements);
      }
    }
  }

  static getLastIndexOfElementInList(ids: string[], elements: DocumentElement[]): number {
    let lastIndex: number = 0;
    for (let index = 0; index < elements.length; index++) {
      if (this.isHavingElementsInElement(ids, elements[index])) {
        lastIndex = index;
      }
    }
    return lastIndex;
  }

  static isHavingElementsInElement(ids: string[], element: DocumentElement): boolean {
    if (ids.indexOf(element.id) > -1) {
      return true;
    }
    let isHaving: boolean = false;
    element.subElements.forEach(e => {
      if (isHaving) {
        return;
      } else {
        isHaving = this.isHavingElementsInElement(ids, e);
      }
    });
    return isHaving;
  }

  static fetchAllElementMatchedElementType(typeKind: ElementTypeKind, fromElements: DocumentElement[], fetchingElements: DocumentElement[] = []): DocumentElement[] {
    fromElements.forEach(e => {
      if (e.kind === typeKind) {
        fetchingElements.push(e);
      }

      if (e.subElements.length > 0) {
        this.fetchAllElementMatchedElementType(typeKind, e.subElements, fetchingElements);
      }
    });
    return fetchingElements;
  }

  static suppressIncludedInstructionStep(elements: DocumentElement[]): DocumentElement[] {
    elements = elements.filter(s => !(s instanceof StepElement) || !s.included);
    elements.forEach(e => {
      if (e.subElements.length > 0) {
        e.subElements = DocumentElement.suppressIncludedInstructionStep(e.subElements);
      }
    });
    return elements;
  }

  static convertToElementsConsolidateTable(elements: DocumentElement[]): DocumentElement[] {
    let clonedElements = elements.map(e => e.clone());
    let groups = clonedElements.filter(e => e instanceof GroupElement).map(e => (e as GroupElement));
    groups.forEach(group => {
      group.subElements = group.consolidateSteps;
    });
    let actionTypeSteps = clonedElements.filter(e => e instanceof StepElement && (e.action === ActionKind.Type || e.action === ActionKind.Change)).map(e => (e as StepElement));
    actionTypeSteps.forEach(step => {
      step.defaultDisplayText = step.tempConsolidateDisplayText;
    });
    return clonedElements;
  }

  static replaceImgTagCallToS3(html: string) {
    const regex = /<img.*?src="([^">]*\/([^">]*?))".*?>/g;
    let matched;
    while ((matched = regex.exec(html)) !== null) {
      let src = matched[1];
      let signedUrl = AuthCacheService.getSignedUrl(SignedUrlType.recordings);
      if (src.indexOf(signedUrl.bucketName) >= 0) {
        let srcMissDomain = src.replace(`${signedUrl.s3Link}${signedUrl.bucketName}`, '');
        let newSrc = `${signedUrl.cloudfront}${srcMissDomain}?${signedUrl.value}`;
        html = html.replace(src, newSrc);
        regex.lastIndex -= src.length - newSrc.length;
      }
    }
    return html;
  }

  static replaceImgTagCallToCloudfront(html: string) {
    const regex = /<img.*?src="([^">]*\/([^">]*?))".*?>/g;
    let matched;
    while ((matched = regex.exec(html)) !== null) {
      let src = matched[1];
      let signedUrl = AuthCacheService.getSignedUrl(SignedUrlType.recordings);
      if (src.indexOf(signedUrl.cloudfront) >= 0) {
        let domain = signedUrl.cloudfront;
        let newSrc = src.replace(`${domain}`, `${signedUrl.s3Link}${signedUrl.bucketName}`).split('?')[0];
        html = html.replace(src, newSrc);
        regex.lastIndex -= src.length - newSrc.length;
      }
    }
    return html;
  }

  fromJson(json: any): DocumentElement {
    if (!json) {
      return this;
    }

    this.defaultDisplayText = json.defaultDisplayText ? json.defaultDisplayText : '';
    this.elementTypeId = +json.elementTypeId;
    this.id = json.id;
    this.kind = +json.kind;
    this.manualDisplayText = json.manualDisplayText;
    this.subElements = json.subElements ? json.subElements.map((element: any) => DocumentElement.fromDto(element)) : [];
    this.subElements.forEach(e => e.parent = this);
    this.name = json.name;
    this.manualName = json.manualName;
    this.included = json.included;
    this.isBookPrint = json.isBookPrint;
    this.elementPageNumber = json.elementPageNumber;
    return this;
  }

  fromJsonKeepNumberOfSubElement(json: any): DocumentElement {
    if (!json) {
      return this;
    }

    if (this instanceof SectionElement) {
      this.sectionDefaultName = json.sectionDefaultName;
      this.sectionNumber = json.sectionNumber;
    }
    this.defaultDisplayText = json.defaultDisplayText ? json.defaultDisplayText : '';
    this.elementTypeId = +json.elementTypeId;
    this.id = json.id;
    this.kind = +json.kind;
    this.manualDisplayText = json.manualDisplayText;
    this.name = json.name;
    this.manualName = json.manualName;
    this.included = json.included;
    this.isBookPrint = json.isBookPrint;
    if (json.subElements.length >= this.subElements.length) {
      this.subElements = json.subElements ? json.subElements.map((element: any) => DocumentElement.fromDto(element)) : [];
      this.subElements.forEach(e => e.parent = this);
    }

    return this;
  }

  toJson() {
    let json: any = {};

    json.defaultDisplayText = this.defaultDisplayText;
    json.elementTypeId = this.elementTypeId;
    json.id = this.id;
    json.kind = this.kind;
    json.manualDisplayText = this.manualDisplayText;
    json.subElements = this.subElements.map(element => element.toJson());
    json.name = this.name;
    json.manualName = this.manualName;
    json.included = this.included;
    json.isBookPrint = this.isBookPrint;
    json.elementPageNumber = this.elementPageNumber;
    return json;
  }

  clone(): DocumentElement {
    let element = new DocumentElement().fromJson(this.toJson());
    element.overrideDisplaytext = this.overrideDisplaytext;
    element.name = this.name;
    return element;
  }

  replaceAllRecordingCallToCloudfront() {
    let doReplace = (element: DocumentElement) => {
      if (element.manualDisplayText != null) {
        element.manualDisplayText = DocumentElement.replaceImgTagCallToCloudfront(element.manualDisplayText);
      }

      if (element.defaultDisplayText != null) {
        element.defaultDisplayText = DocumentElement.replaceImgTagCallToCloudfront(element.defaultDisplayText);
      }

      if (element instanceof StepElement) {
        (element as StepElement).variantTitle = DocumentElement.replaceImgTagCallToCloudfront((element as StepElement).variantTitle);
      }

      if (element instanceof DocumentLinkElement) {
        (element as DocumentLinkElement).links.forEach(link => {
          link.replaceUrlCallToCloudfront();
        });
      }

      if (element.subElements.length > 0) {
        element.subElements.forEach(subElement => {
          doReplace(subElement);
        });
      }
    };
    doReplace(this);
  }
}

export enum ElementErrorLevel {
  Normal = 0,
  Warning = 1,
  Error = 2
}

export enum ElementErrorDetailLevel {
  ValueMissing = 1,
  LabelMissing = 2,
  ActionMissing = 3,
  ControlMissing = 4,
  ControlCoordinatesNotCaptured = 5
}

export enum RecordingStepEventType {
  Event = 0,
  Note = 99,
  FreeText = 100
}

export enum ElementListContextMenuItem {
  GroupStepsFirstImage = 1,
  GroupStepsLastImage = 5,
  DeleteSteps = 2,
  UnGroupSteps = 3,
  ForkSteps = 4,
  RestoreSteps = 6,
  ChooseStepsFirstImage = 7,
  ChooseStepsLastImage = 8,
  HideStep = 9,
  UnhideStep = 10
}

export class GroupElement extends DocumentElement {
  screenShotSource: GroupScreenShotSource = GroupScreenShotSource.FromFirstStep;
  consolidateSteps: DocumentElement [] = [];

  constructor() {
    super();
    this.kind = ElementTypeKind.Group;
  }

  override get screenImageId(): string {
    let subElements = Utility.flattenAray(this.subElements.map(e => e.kind === ElementTypeKind.ConsolidateTableStep ? e.subElements : e));
    if (this.screenShotSource === GroupScreenShotSource.FromLastStep) {
      subElements = subElements.reverse();
    }

    let stepIndex = subElements.findIndex((child: any) => child instanceof StepElement);
    return stepIndex > -1 ? (subElements[stepIndex] as StepElement).screenImage : '';
  }

  get mainStep() {
    let subElements = Utility.flattenAray(this.subElements.map(e => e.kind === ElementTypeKind.ConsolidateTableStep ? e.subElements : e));
    if (this.screenShotSource === GroupScreenShotSource.FromLastStep) {
      subElements = subElements.reverse();
    }

    let stepIndex = subElements.findIndex((child: any) => child instanceof StepElement);
    return stepIndex > -1 ? subElements[stepIndex] : null;
  }

  override get screenArea(): Rect {
    let subElements = Utility.flattenAray(this.subElements.map(e => e.kind === ElementTypeKind.ConsolidateTableStep ? e.subElements : e));

    if (this.screenShotSource === GroupScreenShotSource.FromLastStep) {
      subElements = subElements.filter((s: any) => s instanceof StepElement).reverse();
    }

    let top = ~~Math.min(...subElements.filter((e: any) => e instanceof StepElement).map((e: any) => (e as StepElement).screenRect.top));
    let left = ~~Math.min(...subElements.filter((e: any) => e instanceof StepElement).map((e: any) => (e as StepElement).screenRect.left));
    let width = ~~Math.max(...subElements
      .filter((e: any) => e instanceof StepElement)
      .map((e: any) => (e as StepElement).screenRect.width + (e as StepElement).screenRect.left)) - left;
    let height = ~~Math.max(...subElements
      .filter((e: any) => e instanceof StepElement)
      .map((e: any) => (e as StepElement).screenRect.height + (e as StepElement).screenRect.top)) - top;

    if (subElements) {
      let subElement = subElements.find((e: any) => e.kind === ElementTypeKind.Step);
      if (subElement && subElement.screenRect) {
        width = subElement.screenRect.width;
        height = subElement.screenRect.height;
      }
    }

    return new Rect(left, top, width, height);
  }

  override get originalScreenArea(): Rect {
    let subElements = Utility.flattenAray(this.subElements.map(e => e.kind === ElementTypeKind.ConsolidateTableStep ? e.subElements : e));
    if (this.screenShotSource === GroupScreenShotSource.FromLastStep) {
      subElements = subElements.reverse();
    }
    let stepIndex = subElements.findIndex((child: any) => child instanceof StepElement);
    return stepIndex > -1 ? (subElements[stepIndex] as StepElement).originalScreenRect : new Rect();
  }

  override get stepCaption(): string {
    let step = this.stepSource;
    return step != null ? step.windowCaption : '';
  }

  get stepSource(): StepElement | null {
    let step: StepElement | null = null;
    let subElements = Utility.flattenAray(this.subElements.map(e => e.kind === ElementTypeKind.ConsolidateTableStep ? e.subElements : e));
    if (this.screenShotSource === GroupScreenShotSource.FromFirstStep) {
      let stepIndex = subElements.findIndex((child: any) => child instanceof StepElement);
      step = stepIndex > -1 ? subElements[stepIndex] : null;
    } else {
      let stepElements = subElements.filter((child: any) => child instanceof StepElement);
      if (stepElements && stepElements.length) {
        step = stepElements[stepElements.length - 1];
      }
    }
    return step;
  }

  override clone(): DocumentElement {
    let element = new GroupElement().fromJson(this.toJson());
    element.overrideDisplaytext = this.overrideDisplaytext;
    return element;
  }

  override fromJson(obj: any) {
    if (!obj) {
      return this;
    }

    super.fromJson(obj);
    this.consolidateSteps = obj.consolidateSteps ? obj.consolidateSteps.map((element: any) => DocumentElement.fromDto(element)) : [];
    this.consolidateSteps.forEach(e => e.parent = this);
    this.screenShotSource = obj.screenShotSource;

    return this;
  }

  override toJson() {
    let json: any = super.toJson();
    json.screenShotSource = this.screenShotSource;
    json.consolidateSteps = this.consolidateSteps.map(element => element.toJson());
    return json;
  }

  override fromJsonKeepNumberOfSubElement(json: any): GroupElement {
    if (!json) {
      return this;
    }

    this.screenShotSource = json.screenShotSource;
    super.fromJsonKeepNumberOfSubElement(json);

    return this;
  }
}

export class StepElement extends DocumentElement {
  action: ActionKind = ActionKind.LogControl;
  actionText: string = '';
  columnHeader: string = '';
  context: string = '';
  controlImage: string = '';
  controlRect: Rect = new Rect();
  controlType: ControlTypeKind = ControlTypeKind.Unknown;
  controlTypeText: string = '';
  duration: number = 0;
  eventType: RecordingStepEventType = RecordingStepEventType.Event;
  focusPoint: Point = new Point();
  imageDescription: string = '';
  labelRect: Rect = new Rect();
  labelText: string = '';
  originalScreenRect: Rect = new Rect();
  screenImage: string = '';
  screenRect: Rect = new Rect();
  sequence: number = 0;
  simRect: Rect = new Rect();
  timestamp: Date | null = new Date();
  title: string = '';
  userControlImageForUserText: boolean = false;
  userNote1: string = '';
  userNote2: string = '';
  userText1: string = '';
  userText2: string = '';
  valueRect: Rect = new Rect();
  valueText: string = '';
  variantTitle: string = '';
  windowCaption: string = '';
  windowChange: boolean = false;
  actualConfidence: number = 0;
  // Step library fields
  stepLibItemChecked: boolean = false;
  stepLibItemId: number = 0;
  stepLibLastModifiedTimeInMs: number = 0;
  // Doctype filter
  specificToDocTypes: boolean = false;
  availableDocTypes: any = [];
  instructionArea: Rect = new Rect();
  stepNumberBubbleArea:Rect = new Rect();
  convertedFromFrame: boolean = false;
  inactive: boolean = false;
  createdTime: Date | null = null;
  flashAsNewStep: boolean = false;
  drawing: string = '';
  controlPath: ControlInfo[] = [];
  additionalText: string = '';
  tempConsolidateDisplayText: string = '';
  isContextUpdated: boolean = false;

  pageContext: any = null;
  objectContext: any = null;

  constructor() {
    super();
    this.kind = ElementTypeKind.Step;
    this.timestamp = new Date();
  }

  get estimatedConfidence(): number {
    let c = 0;

    if (this.labelText) c += 20;
    if (this.action) c += 20;
    if (this.controlType) c += 20;
    if (this.columnHeader) c += 20;
    if (this.valueText) {
      c += 25;
    } else {
      if ((this.controlType !== ControlTypeKind.Button) && (this.controlType !== ControlTypeKind.TabButton)) c -= 25;
    }

    return c;
  }

  get confidence(): number {
    return Math.max(this.actualConfidence, this.estimatedConfidence);
  }

  set confidence(c: number) {
    if (c >= 100) {
      this.actualConfidence = 100;
    } else {
      this.actualConfidence = this.estimatedConfidence;
    }
  }

  override get screenImageId(): string {
    if (this.parent && this.parent.kind !== ElementTypeKind.Path)
      return this.parent.screenImageId;
    else
      return this.screenImage;
  }

  override get screenArea(): Rect {
    if (this.parent && this.parent.kind !== ElementTypeKind.Path)
      return this.parent.screenArea;
    else
      return this.screenRect;
  }

  override get originalScreenArea(): Rect {
    return this.parent ? this.parent.originalScreenArea : this.originalScreenRect;
  }

  override get stepCaption(): string {
    return this.parent ? this.parent.stepCaption : this.windowCaption;
  }

  get isBuildAStepElement(): boolean {
    return (!this.screenImage || (!this.controlType && this.controlType !== 0) || !this.action) && !this.convertedFromFrame;
  }

  private static getDropDownControlRect(controlRect: Rect, originalControlRect: Rect): Rect {
    let w = controlRect.width - originalControlRect.width;
    let l = originalControlRect.left - w;
    return new Rect(l, originalControlRect.top, controlRect.width, originalControlRect.height);
  }

  private static getEventConfidence(event: ControlEvent): number {
    let c = 0;

    if (event.labelText) c += 20;
    if (event.action) c += 20;
    if (event.controlType) c += 20;
    if (event.columnHeader) c += 20;
    if (event.valueText) {
      c += 25;
    } else {
      if ((event.controlType !== ControlTypeKind.Button) && (event.controlType !== ControlTypeKind.TabButton)) c -= 25;
    }

    if (event.source.toLowerCase().indexOf('sap') >= 0) c += 5;
    if (event.source.toLowerCase().indexOf('msaa') >= 0) c += 5;
    if (event.source.toLowerCase().indexOf('ie') >= 0) c += 5;
    if (event.source.toLowerCase().indexOf('win32') >= 0) c += 5;

    return c;
  }

  fromEvent(event: RecorderEvent): StepElement {
    if (!event) {
      return this;
    }
    this.eventType = RecordingStepEventType.Event;
    this.duration = event.duration;
    this.sequence = event.sequence;
    this.timestamp = event.timestamp;
    this.windowChange = event.windowChange;
    this.windowCaption = event.caption;
    this.context = event.imageContext;

    if (event instanceof ImageEvent) {
      let imageEvent = event as ImageEvent;
      this.screenRect = imageEvent.screenRect;
      this.originalScreenRect = this.screenRect;
      this.screenImage = imageEvent.screenshot;
      this.displayText = 'Screen Shot';
    } else if (event instanceof ControlEvent) {
      let controlEvent = event as ControlEvent;
      this.action = controlEvent.action;
      this.focusPoint = controlEvent.pointer;
      this.screenImage = controlEvent.screenshot;
      this.screenRect = controlEvent.screenRect;
      this.originalScreenRect = this.screenRect;
      this.controlType = controlEvent.controlType;
      this.columnHeader = controlEvent.columnHeader ? controlEvent.columnHeader.trim() : '';
      this.controlImage = controlEvent.controlImage;
      this.labelRect = controlEvent.labelRect;
      this.valueRect = controlEvent.valueRect;
      this.simRect = controlEvent.valueRect;
      this.valueText = controlEvent.valueText ? controlEvent.valueText.trim() : '';
      this.confidence = StepElement.getEventConfidence(controlEvent);

      if (controlEvent.controlType === ControlTypeKind.ComboBox) {
        this.controlRect = StepElement.getDropDownControlRect(this.controlRect, controlEvent.controlRect);
      } else {
        this.controlRect = controlEvent.controlRect;
      }

      if (controlEvent.labelText.trim().toLowerCase().endsWith(this.controlTypeText.trim().toLowerCase())) {
        this.labelText = controlEvent.labelText.replace(/\s*$/, '')
          .substr(0, controlEvent.labelText.length - this.controlTypeText.length);
      } else {
        this.labelText = controlEvent.labelText;
      }
    } else if (event instanceof KeyEvent) {
      let keyEvent = event as KeyEvent;
      this.action = ActionKind.Press;
      this.controlType = ControlTypeKind.Key;
      //TODO: port key converter from .net code and set value text
      //this.valueText = KeysConverter.ConvertToString()

      this.screenImage = keyEvent.screenshot;
      this.screenRect = keyEvent.screenRect;
      this.originalScreenRect = this.screenRect;
    }

    //TODO: refine control and value rectangle

    return this;
  }

  override fromJsonKeepNumberOfSubElement(obj: any): StepElement {
    if (!obj) {
      return this;
    }

    super.fromJsonKeepNumberOfSubElement(obj);

    this.action = obj.action;
    this.actionText = obj.actionText;
    this.columnHeader = obj.columnHeader;
    this.context = obj.context;
    this.controlImage = obj.controlImage;
    this.controlRect = this.controlRect.fromString(obj.controlRect);
    this.controlType = obj.controlType;
    this.controlTypeText = obj.controlTypeText;
    this.duration = obj.duration;
    this.eventType = obj.eventType;
    this.focusPoint = this.focusPoint.fromString(obj.focusPoint);
    this.imageDescription = obj.imageDescription;
    this.labelRect = this.labelRect.fromString(obj.labelRect);
    this.labelText = obj.labelText;
    this.originalScreenRect = this.originalScreenRect.fromString(obj.originalScreenRect);
    this.screenImage = obj.screenImage;
    this.screenRect = this.screenRect.fromString(obj.screenRect);
    this.sequence = obj.sequence;
    this.simRect = this.simRect.fromString(obj.simRect);
    this.timestamp = new Date(obj.timestamp);
    this.title = obj.title;
    this.userControlImageForUserText = obj.userControlImageForUserText;
    this.userNote1 = obj.userNote1;
    this.userNote2 = obj.userNote2;
    this.userText1 = obj.userText1;
    this.userText2 = obj.userText2;
    this.valueRect = this.valueRect.fromString(obj.valueRect);
    this.valueText = obj.valueText;
    this.variantTitle = obj.variantTitle;
    this.windowCaption = obj.windowCaption;
    this.windowChange = obj.windowChange;
    this.stepLibItemChecked = obj.stepLibItemChecked;
    this.stepLibItemId = obj.stepLibItemId;
    this.stepLibLastModifiedTimeInMs = obj.stepLibLastModifiedTimeInMs;
    this.specificToDocTypes = obj.specificToDocTypes;
    this.availableDocTypes = obj.availableDocTypes;
    this.instructionArea = new Rect().fromString(obj.instructionArea);
    this.stepNumberBubbleArea = new Rect().fromString(obj.stepNumberBubbleArea);
    this.convertedFromFrame = obj.convertedFromFrame;
    this.inactive = obj.inactive || false;
    this.drawing = obj.drawing || '';
    this.tempConsolidateDisplayText = obj.tempConsolidateDisplayText || '';
    if (isNaN(this.timestamp.getTime())) {
      this.timestamp = new Date();
    }

    return this;
  }

  override fromJson(obj: any): StepElement {
    if (!obj) {
      return this;
    }
    super.fromJson(obj);

    this.action = obj.action;
    this.actionText = obj.actionText;
    this.columnHeader = obj.columnHeader;
    this.context = obj.context;
    this.controlImage = obj.controlImage;
    this.controlRect = this.controlRect.fromString(obj.controlRect);
    this.controlType = obj.controlType;
    this.controlTypeText = obj.controlTypeText;
    this.duration = obj.duration;
    this.eventType = obj.eventType;
    this.focusPoint = this.focusPoint.fromString(obj.focusPoint);
    this.imageDescription = obj.imageDescription;
    this.labelRect = this.labelRect.fromString(obj.labelRect);
    this.labelText = obj.labelText;
    this.originalScreenRect = this.originalScreenRect.fromString(obj.originalScreenRect);
    this.screenImage = obj.screenImage;
    this.screenRect = this.screenRect.fromString(obj.screenRect);
    this.sequence = obj.sequence;
    this.simRect = this.simRect.fromString(obj.simRect);
    this.timestamp = new Date(obj.timestamp);
    this.title = obj.title;
    this.userControlImageForUserText = obj.userControlImageForUserText;
    this.userNote1 = obj.userNote1;
    this.userNote2 = obj.userNote2;
    this.userText1 = obj.userText1;
    this.userText2 = obj.userText2;
    this.valueRect = this.valueRect.fromString(obj.valueRect);
    this.valueText = obj.valueText;
    this.variantTitle = obj.variantTitle;
    this.windowCaption = obj.windowCaption;
    this.windowChange = obj.windowChange;
    this.stepLibItemChecked = obj.stepLibItemChecked;
    this.stepLibItemId = obj.stepLibItemId;
    this.stepLibLastModifiedTimeInMs = obj.stepLibLastModifiedTimeInMs;
    this.specificToDocTypes = obj.specificToDocTypes;
    this.availableDocTypes = obj.availableDocTypes;
    this.instructionArea = new Rect().fromString(obj.instructionArea);
    this.stepNumberBubbleArea = new Rect().fromString(obj.stepNumberBubbleArea);
    this.convertedFromFrame = obj.convertedFromFrame;
    this.inactive = obj.inactive || false;
    this.createdTime = new Date(obj.createdTime);
    this.drawing = obj.drawing || '';
    this.additionalText = obj.additionalText;
    this.tempConsolidateDisplayText = obj.tempConsolidateDisplayText || '';
    this.pageContext = obj.pageContext || null;
    this.objectContext = obj.objectContext || null;
    this.isContextUpdated = obj.isContextUpdated || false;

    if (!obj.controlPath) {
      this.controlPath = [];
    } else {
      this.controlPath = obj.controlPath.map((c: any) => new ControlInfo().fromJson(c));
    }

    if (isNaN(this.timestamp.getTime())) {
      this.timestamp = new Date();
    }
    return this;
  }

  override toJson() {
    let json: any = super.toJson();

    json.action = this.action;
    json.actionText = this.actionText;
    json.columnHeader = this.columnHeader;
    json.context = this.context;
    json.controlImage = this.controlImage;
    json.controlRect = this.controlRect.toString();
    json.controlType = this.controlType;
    json.controlTypeText = this.controlTypeText;
    json.duration = this.duration;
    json.eventType = this.eventType;
    json.focusPoint = this.focusPoint.toString();
    json.imageDescription = this.imageDescription;
    json.labelRect = this.labelRect.toString();
    json.labelText = this.labelText;
    json.originalScreenRect = this.originalScreenRect.toString();
    json.screenImage = this.screenImage;
    json.screenRect = this.screenRect.toString();
    json.sequence = this.sequence;
    json.simRect = this.simRect.toString();
    json.timestamp = !this.timestamp || isNaN(this.timestamp.getTime()) ? new Date() : this.timestamp;
    json.title = this.title;
    json.userControlImageForUserText = this.userControlImageForUserText;
    json.userNote1 = this.userNote1;
    json.userNote2 = this.userNote2;
    json.userText1 = this.userText1;
    json.userText2 = this.userText2;
    json.valueRect = this.valueRect.toString();
    json.valueText = this.valueText;
    json.variantTitle = this.variantTitle;
    json.windowCaption = this.windowCaption;
    json.windowChange = this.windowChange;
    json.stepLibItemChecked = this.stepLibItemChecked;
    json.stepLibItemId = this.stepLibItemId;
    json.stepLibLastModifiedTimeInMs = this.stepLibLastModifiedTimeInMs;
    json.specificToDocTypes = this.specificToDocTypes;
    json.availableDocTypes = this.availableDocTypes;
    json.instructionArea = this.instructionArea.toString();
    json.stepNumberBubbleArea = this.stepNumberBubbleArea.toString();
    json.convertedFromFrame = this.convertedFromFrame;
    json.inactive = this.inactive;
    json.drawing = this.drawing;
    json.additionalText = this.additionalText;
    json.tempConsolidateDisplayText = this.tempConsolidateDisplayText;
    json.isContextUpdated = this.isContextUpdated;

    json.pageContext = this.pageContext;
    json.objectContext = this.objectContext;

    if (!this.controlPath) {
      json.controlPath = [];
    } else {
      json.controlPath = this.controlPath.map(c => c.toJson());
    }
    return json;
  }

  override clone(): StepElement {
    let step = new StepElement().fromJson(this.toJson());
    step.overrideDisplaytext = this.overrideDisplaytext;
    return step;
  }
}

export class NoteElement extends DocumentElement {
  bounds: Rect = new Rect();
  refBackgroundStepElement: StepElement = new StepElement();
  isCustomBackground: boolean = false;
  isCustomSize: boolean = false;

  constructor() {
    super();
    this.kind = ElementTypeKind.Note;
    this.refBackgroundStepElement.timestamp = new Date();
  }

  override fromJson(obj: any) {
    if (!obj) {
      return this;
    }

    super.fromJson(obj);
    this.bounds = new Rect().fromString(obj.bounds);

    if (obj.refBackgroundStepElement) {
      this.refBackgroundStepElement = new StepElement().fromJson(obj.refBackgroundStepElement);
    }

    this.isCustomBackground = obj.isCustomBackground;
    this.isCustomSize = obj.isCustomSize || false;

    return this;
  }

  override toJson() {
    let json: any = super.toJson();
    json.bounds = this.bounds.toString();
    json.refBackgroundStepElement = this.refBackgroundStepElement.toJson();
    json.isCustomBackground = this.isCustomBackground;
    json.isCustomSize = this.isCustomSize || false;

    return json;
  }

  override clone() {
    let note = new NoteElement();
    let parent = super.clone();

    note.bounds = this.bounds.clone();
    note.refBackgroundStepElement = this.refBackgroundStepElement.clone();
    note.isCustomBackground = this.isCustomBackground;
    note.isCustomSize = this.isCustomSize || false;
    note.defaultDisplayText = parent.defaultDisplayText;
    note.elementTypeId = parent.elementTypeId;
    note.id = parent.id;
    note.kind = parent.kind;
    note.manualDisplayText = parent.manualDisplayText;
    note.subElements = parent.subElements.map(se => se.clone());
    note.name = parent.name;
    note.elementPageNumber = parent.elementPageNumber;
    return note;
  }

  override fromJsonKeepNumberOfSubElement(json: any): NoteElement {
    if (!json) {
      return this;
    }

    this.bounds = new Rect().fromString(json.bounds);

    if (json.refBackgroundStepElement) {
      this.refBackgroundStepElement = new StepElement().fromJson(json.refBackgroundStepElement);
    }


    this.isCustomBackground = json.isCustomBackground;
    this.isCustomSize = json.isCustomSize || false;
    super.fromJsonKeepNumberOfSubElement(json);

    return this;
  }
}

export class AudioElement extends DocumentElement {
  // Unit: byte
  public static readonly MaxSize: 12582912;
  resourceId: string = '';
  resourceType: string = '';

  constructor() {
    super();
    this.elementTypeId = ElementTypeId.AudioElement;
    this.kind = ElementTypeKind.Audio;
  }

  public static IsAvailableUploadFile(type: string) {
    let types = ['mp3', 'MPA', 'mpeg', 'mpa-robust', 'wav'];

    return types.findIndex(t => t.toLowerCase() === type.toLowerCase()) > -1;
  }

  override fromJson(obj: any) {
    if (!obj) {
      return this;
    }

    super.fromJson(obj);
    this.resourceId = obj.resourceId;
    this.resourceType = obj.resourceType;

    return this;
  }

  override toJson() {
    let json: any = super.toJson();
    json.resourceId = this.resourceId;
    json.resourceType = this.resourceType;

    return json;
  }

  override clone() {
    let audio = new AudioElement();
    let parent = super.clone();

    audio.resourceId = this.resourceId;
    audio.resourceType = this.resourceType;
    audio.defaultDisplayText = parent.defaultDisplayText;
    audio.elementTypeId = parent.elementTypeId;
    audio.id = parent.id;
    audio.kind = parent.kind;
    audio.manualDisplayText = parent.manualDisplayText;
    audio.subElements = parent.subElements.map(se => se.clone());
    audio.name = parent.name;
    audio.elementPageNumber = parent.elementPageNumber;

    return audio;
  }
}

export class VideoElement extends DocumentElement {
  videoUrl: string = '';
  header: string = '';

  constructor() {
    super();
    this.elementTypeId = ElementTypeId.VideoElement;
    this.kind = ElementTypeKind.Video;
  }

  override fromJson(obj: any) {
    if (!obj) {
      return this;
    }

    super.fromJson(obj);
    this.videoUrl = obj.videoUrl;
    this.header = obj.header;
    return this;
  }

  override toJson() {
    let json: any = super.toJson();
    json.videoUrl = this.videoUrl;
    json.header = this.header;
    return json;
  }

  override clone() {
    let video = new VideoElement();
    let parent = super.clone();

    video.videoUrl = this.videoUrl;
    video.defaultDisplayText = parent.defaultDisplayText;
    video.elementTypeId = parent.elementTypeId;
    video.id = parent.id;
    video.kind = parent.kind;
    video.manualDisplayText = parent.manualDisplayText;
    video.subElements = parent.subElements.map(se => se.clone());
    video.name = parent.name;
    video.header = this.header;
    video.elementPageNumber = parent.elementPageNumber;
    return video;
  }

  override fromJsonKeepNumberOfSubElement(obj: any): VideoElement {
    if (!obj) {
      return this;
    }

    super.fromJsonKeepNumberOfSubElement(obj);
    this.videoUrl = obj.videoUrl;
    this.header = obj.header;
    return this;
  }
}

export class DocumentLinkElement extends DocumentElement {
  links: DocumentLink [] = [];

  constructor() {
    super();
    this.kind = ElementTypeKind.DocumentLink;
  }

  get isGrayedOut() {
    return (!this.links || this.links.length === 0) || this.links.findIndex(k => k.isPublished) === -1;
  }

  override fromJson(obj: any) {
    if (!obj) {
      return this;
    }

    super.fromJson(obj);
    this.links = obj.links.map((link: any) => new DocumentLink().fromJson(link));

    return this;
  }

  override fromJsonKeepNumberOfSubElement(obj: any) {
    if (!obj) {
      return this;
    }

    super.fromJsonKeepNumberOfSubElement(obj);
    this.links = obj.links.map((link: any) => new DocumentLink().fromJson(link));

    return this;
  }

  override toJson() {
    let json: any = super.toJson();
    json.links = this.links.map((link: DocumentLink) => link.toJson());

    return json;
  }

  override clone() {
    let documentLink = new DocumentLinkElement();
    let parent = super.clone();

    documentLink.links = this.links.slice();
    documentLink.defaultDisplayText = parent.defaultDisplayText;
    documentLink.elementTypeId = parent.elementTypeId;
    documentLink.id = parent.id;
    documentLink.kind = parent.kind;
    documentLink.manualDisplayText = parent.manualDisplayText;
    documentLink.subElements = parent.subElements.map(se => se.clone());
    documentLink.name = parent.name;
    return documentLink;
  }
}

export class CoreDocumentElement extends DocumentElement {
  links: DocumentLink [] = [];
  isUnpublishedLink: boolean = false;
  isDeletedLink: boolean = false;
  isFromBookPublished: boolean = false;

  constructor() {
    super();
    this.kind = ElementTypeKind.CoreDocument;
  }

  get isGrayedOut() {
    return (!this.links || this.links.length === 0) || this.isUnpublishedLink || this.isDeletedLink;
  }

  override fromJson(obj: any) {
    if (!obj) {
      return this;
    }

    super.fromJson(obj);
    this.links = obj.links ? obj.links.map((link: any) => new DocumentLink().fromJson(link)) : [];

    return this;
  }

  override fromJsonKeepNumberOfSubElement(obj: any) {
    if (!obj) {
      return this;
    }

    super.fromJsonKeepNumberOfSubElement(obj);
    this.links = obj.links ? obj.links.map((link: any) => new DocumentLink().fromJson(link)) : [];

    return this;
  }

  override toJson() {
    let json: any = super.toJson();
    json.links = this.links.map((link: DocumentLink) => link.toJson());

    return json;
  }

  override clone() {
    let coreDocument = new CoreDocumentElement();
    let parent = super.clone();

    coreDocument.links = this.links.slice();
    coreDocument.defaultDisplayText = parent.defaultDisplayText;
    coreDocument.elementTypeId = parent.elementTypeId;
    coreDocument.id = parent.id;
    coreDocument.kind = parent.kind;
    coreDocument.manualDisplayText = parent.manualDisplayText;
    coreDocument.subElements = parent.subElements.map(se => se.clone());
    coreDocument.name = parent.name;
    coreDocument.elementPageNumber = parent.elementPageNumber;
    return coreDocument;
  }
}

export class BookTitleElement extends DocumentElement {

}

export class SectionElement extends DocumentElement {
  sectionNumber: string = '';
  sectionDefaultName: string = '';
  sectionTitle: string = '';
  overrideSectionNumber : string = '';
  overrideSectionDefaultName : string = '';
  displaySectionIndex : boolean = true;

  links: DocumentLink [] = [];

  constructor() {
    super();
    this.kind = ElementTypeKind.Section;
  }

  override fromJson(obj: any) {
    if (!obj) {
      return this;
    }

    super.fromJson(obj);
    this.sectionNumber = obj.sectionNumber || '';
    this.sectionDefaultName = obj.sectionDefaultName || '';
    this.sectionTitle = obj.sectionTitle || '';

    this.links = obj.links ? obj.links.map((link: any) => new DocumentLink().fromJson(link)) : [];
    return this;
  }

  override fromJsonKeepNumberOfSubElement(obj: any) {
    if (!obj) {
      return this;
    }

    super.fromJsonKeepNumberOfSubElement(obj);
    this.sectionNumber = obj.sectionNumber || '';
    this.sectionDefaultName = obj.sectionDefaultName || '';
    this.sectionTitle = obj.sectionTitle || '';

    this.links = obj.links ? obj.links.map((link: any) => new DocumentLink().fromJson(link)) : [];
    return this;
  }

  override toJson() {
    let json: any = super.toJson();
    json.sectionNumber = this.sectionNumber;
    json.sectionDefaultName = this.sectionDefaultName || '';
    json.sectionTitle = this.sectionTitle || '';

    json.links = this.links.map((link: DocumentLink) => link.toJson());
    return json;
  }

  override clone() {
    let section = new SectionElement();
    let parent = super.clone();

    section.links = this.links.slice();
    section.sectionNumber = this.sectionNumber;
    section.sectionDefaultName = this.sectionDefaultName;
    section.sectionTitle = this.sectionTitle;
    section.defaultDisplayText = parent.defaultDisplayText;
    section.elementTypeId = parent.elementTypeId;
    section.id = parent.id;
    section.kind = parent.kind;
    section.manualDisplayText = parent.manualDisplayText;
    section.subElements = parent.subElements.map(se => se.clone());
    section.name = parent.name;
    section.elementPageNumber = parent.elementPageNumber;
    return section;
  }

  get isGrayedOut(): boolean {
    if(this.isBookPrint){
      return false;
    }

    if (!this.subElements || this.subElements.length === 0) {
      return false;
    }

    let hasPublish = this.subElements.findIndex(e =>
      (e instanceof CoreDocumentElement && (e.links && e.links.length > 0) && !e.isDeletedLink && !e.isUnpublishedLink)
      || (e instanceof SectionElement && !e.isGrayedOut)
      || (e instanceof DocumentLinkElement && (e.links && e.links.length > 0) && e.links.findIndex(k => k.isPublished) > -1)
      || e.kind === ElementTypeKind.Introduction) > -1;
    return !hasPublish;
  }
}

export class QuizElement extends DocumentElement {
  trueFalseAnswer: boolean;
  multipleQuizType: MultipleQuizType = MultipleQuizType.MultipleChoice;
  multipleQuizAnswerOptions: string[] = [];
  multipleQuizAnswers: number[] = [];
  blankQuizAnswerOptionKeys: string[] = [];
  blankQuizAnswers: number[] = [];

  constructor(kind: ElementTypeKind) {
    super();
    this.kind = kind;
    this.trueFalseAnswer = true;
  }

  override fromJson(obj: any) {
    if (!obj) {
      return this;
    }

    super.fromJson(obj);
    this.trueFalseAnswer = obj.trueFalseAnswer;
    this.multipleQuizType = obj.multipleQuizType;
    this.multipleQuizAnswerOptions = obj.multipleQuizAnswerOptions;
    this.multipleQuizAnswers = obj.multipleQuizAnswers;
    this.blankQuizAnswerOptionKeys = obj.blankQuizAnswerOptionKeys;
    this.blankQuizAnswers = obj.blankQuizAnswers;

    return this;
  }

  override fromJsonKeepNumberOfSubElement(obj: any) {
    if (!obj) {
      return this;
    }

    super.fromJsonKeepNumberOfSubElement(obj);
    this.trueFalseAnswer = obj.trueFalseAnswer;
    this.multipleQuizType = obj.multipleQuizType;
    this.multipleQuizAnswerOptions = obj.multipleQuizAnswerOptions;
    this.multipleQuizAnswers = obj.multipleQuizAnswers;
    this.blankQuizAnswerOptionKeys = obj.blankQuizAnswerOptionKeys;
    this.blankQuizAnswers = obj.blankQuizAnswers;

    return this;
  }

  override toJson() {
    let json: any = super.toJson();
    json.trueFalseAnswer = this.trueFalseAnswer;
    json.multipleQuizType = this.multipleQuizType;
    json.multipleQuizAnswerOptions = this.multipleQuizAnswerOptions;
    json.multipleQuizAnswers = this.multipleQuizAnswers;
    json.blankQuizAnswerOptionKeys = this.blankQuizAnswerOptionKeys;
    json.blankQuizAnswers = this.blankQuizAnswers;

    return json;
  }

  override clone() {
    let quiz = new QuizElement(this.kind);
    let parent = super.clone();

    quiz.trueFalseAnswer = this.trueFalseAnswer;
    quiz.multipleQuizType = this.multipleQuizType;
    quiz.multipleQuizAnswerOptions = this.multipleQuizAnswerOptions;
    quiz.multipleQuizAnswers = this.multipleQuizAnswers;
    quiz.blankQuizAnswerOptionKeys = this.blankQuizAnswerOptionKeys;
    quiz.blankQuizAnswers = this.blankQuizAnswers;
    quiz.defaultDisplayText = parent.defaultDisplayText;
    quiz.elementTypeId = parent.elementTypeId;
    quiz.id = parent.id;
    quiz.kind = parent.kind;
    quiz.manualDisplayText = parent.manualDisplayText;
    quiz.subElements = parent.subElements.map(se => se.clone());
    quiz.name = parent.name;
    quiz.elementPageNumber = parent.elementPageNumber;
    return quiz;
  }
}

export class PowerPointSlideStepElement extends DocumentElement {
  slideImage: string = '';
  slideTitle: string = '';
  slideImageRect: Rect | null = null;
  slideDescriptionBound: Rect | null = null;
  slideAudios: string[] = [];
  slideVideos: PowerPointSlideVideoItem[] = [];
  originalSlideImageRect: Rect | null = null;
  specificToDocTypes: boolean = false;
  availableDocTypes: any = [];

  constructor() {
    super();
    this.kind = ElementTypeKind.PowerPointSlideStep;
  }

  override fromJson(obj: any) {
    if (!obj) {
      return this;
    }

    this.slideImage = obj.slideImage;
    this.slideTitle = obj.slideTitle;

    if (typeof obj.slideImageRect === 'string') {
      this.slideImageRect =
        obj.slideImageRect ? new Rect().fromString(obj.slideImageRect) : new Rect();
    } else if (obj.slideImageRect instanceof Rect) {
      this.slideImageRect = obj.slideImageRect;
    }

    if (typeof obj.slideDescriptionBound === 'string') {
      this.slideDescriptionBound =
        obj.slideDescriptionBound ? new Rect().fromString(obj.slideDescriptionBound) : new Rect();
    } else if (obj.slideDescriptionBound instanceof Rect) {
      this.slideDescriptionBound = obj.slideDescriptionBound;
    }

    this.slideAudios = obj.slideAudios || [];
    this.slideVideos = obj.slideVideos
      ? obj.slideVideos.map((json: any) => new PowerPointSlideVideoItem().fromJson(json)) : [];

    if (typeof obj.originalSlideImageRect === 'string') {
      this.originalSlideImageRect =
        obj.originalSlideImageRect ? new Rect().fromString(obj.originalSlideImageRect) : new Rect();
    } else if (obj.originalSlideImageRect instanceof Rect) {
      this.originalSlideImageRect = obj.originalSlideImageRect;
    }

    if (!this.slideImageRect || this.slideImageRect.empty) {
      this.slideImageRect = this.originalSlideImageRect?.clone() ?? null;
    }

    this.specificToDocTypes = obj.specificToDocTypes;
    this.availableDocTypes = obj.availableDocTypes;

    super.fromJson(obj);
    return this;
  }

  override fromJsonKeepNumberOfSubElement(obj: any) {
    if (!obj) {
      return this;
    }

    this.slideImage = obj.slideImage;
    this.slideTitle = obj.slideTitle;
    this.slideImageRect = obj.slideImageRect;
    this.slideDescriptionBound = obj.slideDescriptionBound;
    this.slideAudios = obj.slideAudios;
    this.slideVideos = obj.slideVideos;
    this.originalSlideImageRect = obj.originalSlideImageRect;
    this.specificToDocTypes = obj.specificToDocTypes;
    this.availableDocTypes = obj.availableDocTypes;
    super.fromJsonKeepNumberOfSubElement(obj);

    return this;
  }

  override toJson() {
    let json: any = super.toJson();

    json.slideImage = this.slideImage;
    json.slideTitle = this.slideTitle;
    json.slideImageRect = this.slideImageRect?.toString();
    json.slideDescriptionBound = this.slideDescriptionBound ? this.slideDescriptionBound.toString() : new Rect().toString();
    json.slideAudios = this.slideAudios;
    json.slideVideos = this.slideVideos.map(video => {
      return video instanceof PowerPointSlideVideoItem ? video.toJson() : video;
    });
    json.originalSlideImageRect = this.originalSlideImageRect?.toString();
    json.specificToDocTypes = this.specificToDocTypes;
    json.availableDocTypes = this.availableDocTypes;

    return json;
  }

  override clone() {
    return new PowerPointSlideStepElement().fromJson(this.toJson());
  }
}

export class PathElement extends DocumentElement {
  isStopSimulation!: boolean;
  movedKey!: string;
  screenImageUrl!: string;

  constructor() {
    super();
    this.kind = ElementTypeKind.Path;
  }

  override fromJson(obj: any) {
    if (!obj) {
      return this;
    }

    super.fromJson(obj);
    this.isStopSimulation = obj.isStopSimulation;
    return this;
  }

  override toJson() {
    let json: any = super.toJson();
    json.isStopSimulation = this.isStopSimulation;

    return json;
  }

  override clone(): PathElement {
    let element = new PathElement().fromJson(this.toJson());
    return element;
  }

  override fromJsonKeepNumberOfSubElement(obj: any): PathElement {
    if (!obj) {
      return this;
    }
    super.fromJsonKeepNumberOfSubElement(obj);
    this.isStopSimulation = obj.isStopSimulation;
    return this;
  }
}

export class ForkElement extends DocumentElement {
  movedKey!: string;
  description!: string;
  private _isEmptyFork: boolean | null = null;

  get isEmptyFork() {
    if (this._isEmptyFork === null) {
      this._isEmptyFork = true;
      if (this.subElements) {
        this._isEmptyFork = this.subElements.every((path) => {
          return !path.subElements || !path.subElements.length;
        });
      }
    }
    return this._isEmptyFork;
  }

  constructor() {
    super();
    this.kind = ElementTypeKind.Fork;
  }

  override fromJson(obj: any) {
    if (!obj) {
      return this;
    }
    super.fromJson(obj);
    this.description = obj.description;
    this.movedKey = Utility.Guid();
    this.subElements.forEach((e: any) => e['movedKey'] = this.movedKey);
    return this;
  }

  override toJson() {
    let json: any = super.toJson();
    json.description = this.description;
    return json;
  }

  override clone(): DocumentElement {
    let element = new ForkElement().fromJson(this.toJson());
    return element;
  }

  override fromJsonKeepNumberOfSubElement(obj: any): ForkElement {
    if (!obj) {
      return this;
    }
    super.fromJsonKeepNumberOfSubElement(obj);
    this.description = obj.description;
    return this;
  }
}

export class PowerPointSlideVideoItem {
  slideVideo: string = '';
  slideVideoBound!: Rect;

  fromJson(json: any) {
    if (!json) {
      return this;
    }

    this.slideVideo = json.slideVideo;
    this.slideVideoBound = json.slideVideoBound
      ? new Rect().fromString(json.slideVideoBound) : new Rect();
    return this;
  }

  toJson() {
    let json: any = {};

    json.slideVideo = this.slideVideo;
    json.slideVideoBound = this.slideVideoBound.toString();
    return json;
  }
}

export class DocumentLink {
  documentId: number = 0;
  documentName: string = '';
  currentBranchName: string = '';
  title: string = '';
  url: string = '';
  type: string = '';
  isPublished: boolean = false;
  isUnpublished : boolean = false;

  GetDocumentLinkFromId() {
    let originUrl = window.location.origin;

    if (!originUrl) {
      originUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}`;
    }
    return `${originUrl}/app/content/tasks/${this.documentId}`;
  }

  GetDocumentLinkFromPackageDownload() {
    let originUrl = window.location.origin;

    if (!originUrl) {
      originUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}`;
    }
    return `${originUrl}/app/export-document/${this.documentId}`;
  }


  fromJson(json: any) {
    if (!json) {
      return this;
    }

    this.url = json.url;
    this.documentName = json.documentName;
    this.title = json.title;
    this.documentId = json.documentId;
    this.type = json.type;
    this.isPublished = json.isPublished;
    this.isUnpublished = json.isUnpublished;
    return this;
  }

  toJson() {
    return {
      url: this.url,
      title: this.title,
      documentId: this.documentId,
      documentName: this.documentName,
      type: this.type,
      isPublished: this.isPublished,
      isUnpublished : this.isUnpublished
    };
  }

  replaceUrlCallToCloudfront() {
    if (!this.url) {
      return;
    }

    if (this.url.indexOf('.pdf') >= 0) {
      const signedUrl = AuthCacheService.getSignedUrl(SignedUrlType.system);
      var hasViewFith = this.url.indexOf('#view=fith') >= 0;
      this.url = this.url.split('?')[0].replace(signedUrl.cloudfront, `${signedUrl.s3Link}${signedUrl.bucketName}`);
      if (hasViewFith) {
        this.url = `${this.url.replace(/#view=fith/g, '')}#view=fith`;
      }
    } else if (this.url.indexOf('.html')) {
      const signedUrl = AuthCacheService.getSignedUrl(SignedUrlType.uploads);
      let queryParams = Utility.getQueryParams(this.url);
      if (queryParams['sourcepath']) {
        this.url = queryParams['sourcepath'].replace(signedUrl.cloudfront, `${signedUrl.s3Link}${signedUrl.bucketName}`);
      }
    }
  }
}

export class BadStepError extends Error {
}

export class ControlTypeModel {
  localeId: number = 0;
  controlType: ControlTypeKind = ControlTypeKind.Unknown;
  controlTypeText: string = '';

  fromJson(obj: any) {
    if (!obj) {
      return this;
    }

    this.localeId = obj.localeId;
    this.controlType = obj.kind;
    this.controlTypeText = obj.text;

    return this;
  }
}

export class ActionVariant {
  actionType: ActionKind = ActionKind.Unknown;
  actionTypeText: string = '';
  displayText: string = '';
  variantTitle: string = '';

  fromJson(obj: any) {
    if (!obj) {
      return this;
    }

    this.actionType = +obj.actionType;
    this.actionTypeText = obj.actionTypeText;
    this.displayText = obj.displayText;
    this.variantTitle = obj.variantTitle;

    return this;
  }
}

export class ControlInfo {
  className: string = '';
  controlTypeId: number = 0;
  name: string = '';
  automationId: string = '';

  fromJson(obj: any): ControlInfo {
    if (!obj) {
      return this;
    }

    this.className = obj.className || '';
    this.controlTypeId = obj.controlTypeId || 0;
    this.name = obj.name || '';
    this.automationId = obj.automationId || '';

    return this;
  }

  toJson(): any {
    return {
      className: this.className,
      controlTypeId: this.controlTypeId,
      name: this.name,
      automationId: this.automationId
    };
  }
}

export enum AudioMode {
  Prepare = 0,
  Normal = 1,
  Recording = 2,
  Preview = 3,
  Play = 4
}

export enum VideoMode {
  Preview = 1,
  Edit = 2
}

export enum QuizMode {
  Preview = 1,
  Edit = 2,
  View = 3,
  VerifyAnswer = 4
}

export enum MultipleQuizType {
  MultipleChoice = 1,
  MultipleMark = 2
}

export enum FinishProcessAudioFlag {
  IsRecording = 1,
  IsUploadFile = 2,
  ShowLoadingSpinner = 3
}

export enum ElementScreenAction {
  LockControlArea = 0,
  ShowControlArea = 1,
  ShowSimulationArea = 2,
  UploadImage = 3,
  CopyImage = 4,
  ResetCropArea = 5
}

export enum GroupScreenShotSource {
  FromFirstStep = 1,
  FromLastStep = 2,
  External = 3
}

