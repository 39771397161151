import { UserProfile } from './user-profile.model';

export enum DocumentHistoryEventKind {
  DocumentCreated = 1,
  StateChanged = 2,
  Published = 3,
  Unpublished = 4,
  Exported = 5,
  Cloned = 6,
  TitleChanged = 7,
  DescriptionChanged = 8,
  ContextChanged = 9,
  DepartmentChanged = 10,
  CategoryChanged = 11,
  DocumentDeleted = 12,
  ChangesDiscarded = 13,
  VersionRestored = 14,
  FileUploaded = 15,
  NewStepRecorded = 16,
  RecordingEdited = 17,
  AuthorChanged = 18,
  DoctypeChanged = 19,
  RestoreDeletedContent = 20,
  AttributeChanged = 21,
  RoleChanged = 22,
  WebLinkChanged = 23,
  OwnerChanged = 24,
  Publishing = 25,
  HTMLDownloaded = 26,
  CVSDownloaded = 27,
  PDFRequested = 28,
  MP4Requested = 29,
  Scorm2004Requested = 30,
  Scorm12Requested = 31,
  ProjectChanged = 34
}

export class DocumentHistoryEventModel {
  oldValue: string = '';
  newValue: string = '';
  additionalValue: string = '';
  eventKind: DocumentHistoryEventKind | 0 = 0;
  creationTime: Date | null = null;
  creatorUserId: number = 0;
  creatorUser: UserProfile = new UserProfile();

  fromJson(json: any) {
    if (!json) {
      return this;
    }

    this.oldValue = json.oldValue;
    this.newValue = json.newValue;
    this.additionalValue = json.additionalValue;
    this.eventKind = json.eventKind;
    this.creationTime = json.creationTime ? new Date(json.creationTime) : null;
    this.creatorUser = json.creatorUser ? new UserProfile().fromJson(json.creatorUser) : new UserProfile();
    this.creatorUserId = +json.creatorUserId;

    return this;
  }

  toJson(): any {
    return {
      oldValue : this.oldValue,
      newValue : this.newValue,
      additionalValue : this.additionalValue,
      eventKind : this.eventKind,
      creationTime : this.creationTime,
      creatorUser : this.creatorUser,
      creatorUserId : this.creatorUserId
    }
  }
}
