import { Tenant } from './tenant.model';
import { Role } from './role.model';
import { PermissionName, Permissions } from './authorization.model';
import { DepartmentModel } from './department.model';
import { advisorSearchFiltersSettings } from './advisor-search-filters-settings.model';
import { StepTitleType } from './document.model';

export class UserProfileModel {
  id: number;
  emailAddress: string;
  name: string;
  surname: string;
  profile: string;
  tenancyName: string;
  availableLanguageIds: number[];
  isShowSuggestion: boolean;
  isTargetAppAnonymous: boolean;
  isHasSsoFeature: boolean;

  constructor() {
    this.id = 0;
    this.emailAddress = '';
    this.name = '';
    this.surname = '';
    this.profile = '';
    this.tenancyName = '';
    this.isShowSuggestion = false;
    this.isTargetAppAnonymous = false;
    this.isHasSsoFeature = false;
    this.availableLanguageIds = [];
  }
}

export class UserProfile {
  id?: number;
  userName: string = '';
  name: string = '';
  surname: string = '';
  emailAddress: string = '';
  phoneNumber = '';
  profilePictureUrl: string = '';
  profileImg: string = '';
  logo: string = '';
  logoData: any;
  loginName: string = '';
  accountName: string = '';
  isFirstLogin: boolean = false;
  defaultLoginTenancy: string = '';
  _tenant!: Tenant;
  roles: Role[] = [];
  suspendedType?: number;
  isActiveSso: boolean = false;
  tenantId!: number;
  isShowSuggestion: boolean = false;
  isTargetAppAnonymous: boolean = false;
  applicationUserConfigurable: boolean = false;
  departmentUserConfigurable: boolean = false;
  userType!: number;
  bookType!: number;
  enableBookFooter: boolean = false;
  defaultStepTitleType: StepTitleType = 0;

  get displayName(): string {
    if (!this.name && !this.surname) {
      return this.userName;
    }

    return `${this.name} ${this.surname}`;
  }

  get tenant(): Tenant {
    return this._tenant;
  }

  static fromJson(json: any): UserProfile {
    return new UserProfile().fromJson(json);
  }

  fromJson(json: any): UserProfile {
    this.id = json.id || 0;
    this.userName = json.userName || '';
    this.name = json.name || '';
    this.surname = json.surname || '';
    this.emailAddress = json.emailAddress || '';
    this.phoneNumber = json.phoneNumber || '';
    this.profilePictureUrl = json.profilePictureUrl || '';
    this.logo = json.logo || '';
    this.isFirstLogin = json.isFirstLogin;
    this._tenant = json.tenant ? Tenant.fromJson(json.tenant) : new Tenant();
    if(!this._tenant) {
      this._tenant = json._tenant ? Tenant.fromJson(json._tenant) : new Tenant();
    }
    this.roles = json.roles
      ? json.roles.filter((r: any) => r !== null && r !== undefined && r !== '').map((r: any) => Role.fromJson(r)) : [];
    this.suspendedType = json.suspendedType;
    this.isActiveSso = json.isActiveSso;
    this.loginName = json.loginName;
    this.accountName = json.accountName;
    this.defaultLoginTenancy = json.defaultLoginTenancy;
    this.isShowSuggestion = json.isShowSuggestion;
    this.isTargetAppAnonymous = json.isTargetAppAnonymous;
    this.applicationUserConfigurable = json.applicationUserConfigurable;
    this.departmentUserConfigurable = json.departmentUserConfigurable;

    if (json.profilePictureUrl && json.profilePictureUrl.length > 0) {
      this.profilePictureUrl = json.profilePictureUrl;
      this.profileImg = json.profilePictureUrl;
    }

    if (this.logo !== null && this.logo !== undefined && this.logo.length > 0) {
      this.logoData = this.logo;
    } else {
      this.logoData = null;
    }
    this.tenantId = json.tenantId;
    this.userType = json.userType;
    this.bookType = json.bookType;
    this.enableBookFooter = json.enableBookFooter;
    this.defaultStepTitleType = json.defaultStepTitleType;
    return this;
  }

  isCustomerAdmin(): boolean {
    return this.roles.some(r => r.isCustomerAdmin());
  }

  isEpilogueAdmin(): boolean {
    return this.roles.some(r => r.isEpilogueAdmin());
  }

  isEpilogueSupportUser (): boolean {
    return this.roles.some(r => r.isEpilogueSupportUser());
  }
  isTranslator (): boolean {
    return this.roles.some(r => r.isTranslator());
  }
  isContentPowerUser(): boolean{
    return this.roles.some(r => r.isContentPowerUser());
  }

  isContentUser(): boolean {
    return this.roles.some(r => r.isContentUser());
  }

  isEpilogueUser(): boolean {
    return this._tenant === null;
  }

  isOpusAIFeatureEnable(): boolean {
    if (!this._tenant){
      return false;
    }
    return this._tenant.isOpusAIFeatureEnable;
  }

  get isPublicApiAdmin(): boolean {
    return this.roles.some(r => r.isPublicApiAdmin());
  }
}

export enum AuthenticationTypeForEA
{
    Anonymous = 1,
    OpusAccountAuthentication = 2,
    ControlledByHostApplication = 3
}

export class EmbeddedAdvisorSettings {
  authenticationTypeForEA?: AuthenticationTypeForEA;
  hideArrowsInsideWidget?: boolean;
  static fromJson(json: any): EmbeddedAdvisorSettings {
    return new EmbeddedAdvisorSettings().fromJson(json);
  }
  fromJson(json: any) : this {
    if (!json) return this;
    this.authenticationTypeForEA = json.authenticationTypeForEA;
    this.hideArrowsInsideWidget = json.hideArrowsInsideWidget;
    return this;
  }
}
export enum AiEnabledRoles
{
    None = 0,
    Admin = 1,
    PowerUser = 2,
    User = 4,
    PublicApiAdmin = 8
}

export class UserDetailProfile extends UserProfile {
  static DefaultDateFormat: string = 'MM/dd/yyyy';

  isHostUser: boolean = false;
  isMasterUser: boolean = false;
  permissions: PermissionName[] = [];
  impersonator!: UserDetailProfile | null;
  countryCode!: string;
  dateFormat!: string;
  override logoData: string = '';
  allowAutoUpdate: boolean = false;
  availableLanguageUserConfigurable: boolean = false;
  allowFollowMeAdivsor: boolean = false;
  enableContentRequest: boolean = true;
  enableAI: boolean = false;
  aiEnabledRoles: number = 1;
  authenticationTypeForEA: AuthenticationTypeForEA = AuthenticationTypeForEA.Anonymous;
  advisorSearchFiltersSettings: advisorSearchFiltersSettings = new advisorSearchFiltersSettings();
  static override fromJson(json: any): UserDetailProfile {
    return new UserDetailProfile().fromJson(json);
  }

  override fromJson(json: any): UserDetailProfile {
    if (!json) return this;
    super.fromJson(json);
    this.isHostUser = json.isHostUser;
    this.isMasterUser = json.isMasterUser;
    this.permissions = json.permissions;
    this.countryCode = json.countryCode;
    this.dateFormat = json.dateFormat || UserDetailProfile.DefaultDateFormat;
    this.impersonator = json.impersonator ? new UserDetailProfile().fromJson(json.impersonator) : null;
    this.allowAutoUpdate = json.allowAutoUpdate;
    this.availableLanguageUserConfigurable = json.availableLanguageUserConfigurable;
    this.allowFollowMeAdivsor = json.allowFollowMeAdivsor;
    if (json.enableContentRequest === undefined){
      json.enableContentRequest = true;
    }
    this.enableContentRequest = json.enableContentRequest;
    if (json.advisorSearchFiltersSettings) {
      this.advisorSearchFiltersSettings = json.advisorSearchFiltersSettings;
    }
    if (json.enableAI === undefined){
      json.enableAI = false;
    }
    this.enableAI = json.enableAI;
    this.aiEnabledRoles = json.aiEnabledRoles
    if (json.authenticationTypeForEA === undefined){
      json.authenticationTypeForEA = AuthenticationTypeForEA.Anonymous;
    }
    this.authenticationTypeForEA = json.authenticationTypeForEA;
    return this;
  }

  isProjectsMaster() {
    return this.isGranted(Permissions.WorkflowProjectManagePermissionName);
  }

  hasDocumentManagementPermission() {
    return this.isGranted(Permissions.DocumentManagerFeaturePermissionName);
  }

  isGranted(permission: PermissionName): boolean {
    return this.permissions.some(p => p === permission);
  }

  get realLoginName(): string {
    if (this.impersonator && this.impersonator.loginName) {
      return this.impersonator.loginName;
    }
    return this.loginName;
  }

  get realAccountName(): string {
    if (this.impersonator && this.impersonator.accountName) {
      return this.impersonator.accountName;
    }
    return this.accountName;
  }

  get realProfileImg(): string {
    if (this.impersonator) {
      return this.impersonator.profileImg;
    }
    return this.profileImg;
  }
}

export class UserInviteDto {
  id: number;
  emailAddress: string;
  name: string;
  surname: string;
  userType: string;
  departmentIds: any [];
  categoryIds: any [];
  permissions: PermissionName[] = [];
  status: string = '';
  departments: DepartmentModel[];
  emailAddressOld: string = '';
  canPublishChanged: boolean = false;

  constructor() {
    this.id = 0;
    this.emailAddress = '';
    this.name = '';
    this.surname = '';
    this.departmentIds = [];
    this.categoryIds = [];
    this.userType = '';
    this.permissions = [];
    this.status = '';
    this.departments = [];
    this.canPublishChanged = false;
  }

}

export class UserConfigurableDepartmentApplication {
  // Note: User for Content User Only
  isApplicationUserConfigurable: boolean = false;
  isDepartmentUserConfigurable: boolean = false;

  fromJson(json: any): UserConfigurableDepartmentApplication {
    if (!json) return this;
    this.isApplicationUserConfigurable = json.isApplicationUserConfigurable;
    this.isDepartmentUserConfigurable = json.isDepartmentUserConfigurable;
    return this;
  }
}

export enum UserType {
  NormalUser = 0,
  SystemUser = 1,
  ImpersonateUser = 2,
  PartnerUser = 3
}
