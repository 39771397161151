export class ColumnHeaderContext {
  headerName: string;
  headerClass: string;

  constructor(headerName: string, headerClass: string) {
    this.headerName = headerName;
    this.headerClass = headerClass;
  }
}

export class ColumnBodyContext {
  fieldIndex: number;
  bodyClass: string;
  isEdited: boolean = true;
  isShowError: boolean = true;

  constructor(fieldIndex: number, bodyClass: string, isEdited: boolean = true, isShowError: boolean = true) {
    this.fieldIndex = fieldIndex;
    this.bodyClass = bodyClass;
    this.isEdited = isEdited;
    this.isShowError = isShowError;
  }
}

export enum ContextIndex {
  caption = 0,
  url,
  processName,
  additionalContext,
  steps,
  isAddedManually
}
