export class Roles {

  static readonly EpilogueAdminRoleName = 'EpilogueAdmin';

  static readonly EpilogueSupportUserRoleName = 'EpilogueSupportUser';

  static readonly EpilogueTranslatorRoleName = 'Translator';

  static readonly CustomerAdminRoleName = 'CustomerAdmin';

  static readonly CustomerPowerUserRoleName = 'CustomerPowerUser';

  static readonly CustomerContentUserRoleName = 'CustomerContentUser';

  static readonly HostUserRoleName = 'HostUser';

  static readonly PublicApiAdmin = 'PublicApiAdmin';
}

export class Permissions {

  static readonly DashboardFeaturePermissionName = 'DASHBOARD';

  static readonly WorkflowFeaturePermissionName = 'Workflow';

  static readonly AnalyticsFeaturePermissionName = 'Analytics';

  static readonly AnalyticsSisenseFeaturePermissionName = 'Analytics.Sisense';

  static readonly WorkflowProjectManagePermissionName = 'Workflow.Project.Admin';

  static readonly WorkflowProjectMemmberPermissionName = 'Workflow.Project.Member';

  static readonly ContentFeaturePermissionName = 'Content';

  static readonly DocumentManagerFeaturePermissionName = 'DocumentManager';

  static readonly DocumentManagerImportFeaturePermissionName = 'DocumentManager.Import';

  static readonly DocumentManagerExportFeaturePermissionName = 'DocumentManager.Export';

  static readonly DocumentManagerUploadFeaturePermissionName = 'DocumentManager.Upload';

  static readonly DocumentManagerDownloadFeaturePermissionName = 'DocumentManager.Download';

  static readonly DocumentManagerPublishChangesPermissionName = 'DocumentManager.PublishChanges';

  static readonly AdvisorFeaturePermissionName = 'Advisor';

  static readonly ApiAccessFeaturePermissionName = 'ApiAccess';

  static readonly TenantSettingsPermissionName = 'TenantSettings';

  static readonly CustomerAccountPermissionName = 'CustomerAccount';


  static readonly HostAdminPermissionName = 'Admin';

  static readonly HostSettingsPermissionName = 'Admin.Settings';

  static readonly HostManageCustomerPermissionName = 'Admin.Customer';

  static readonly ReviewDocumentPermissionName = 'Content.ReviewDocument';

  static readonly BookPermissionName = 'Book';
  static readonly BookManagerPermissionName = 'BookManager';
  static readonly BookManagerImportPermissionName = 'BookManager.Import';
  static readonly BookManagerExportPermissionName = 'BookManager.Export';
  static readonly BookManagerUploadPermissionName = 'BookManager.Upload';
  static readonly BookManagerDownloadPermissionName = 'BookManager.Download';
  static readonly BookManagerPublishChangesFeatureName = 'BookManager.PublishChanges';
  static readonly HostApplicationLanguagePermissionName = "Admin.ApplicationLanguage";
  static readonly HostStepLibaryPermissionName = "Admin.StepLibary";
  static readonly FollowMe = "FollowMe";

}

export type RoleName
  = 'EpilogueAdmin'
  | 'EpilogueSupportUser'
  | 'CustomerAdmin'
  | 'CustomerPowerUser'
  | 'CustomerContentUser';

export type PermissionName
  = 'Dashboard'
  | 'Workflow'
  | 'Workflow.Project.Admin'
  | 'Content'
  | 'DocumentManager'
  | 'DocumentManager.Import'
  | 'DocumentManager.Export'
  | 'DocumentManager.Upload'
  | 'DocumentManager.Download'
  | 'DocumentManager.PublishChanges'
  | 'Advisor'
  | 'ApiAccess'
  | 'TenantSettings'
  | 'Workflow.Project.Member'
  | 'Admin'
  | 'Admin.Settings'
  | 'Admin.Customer'
  | 'Content.ReviewDocument'
  | 'Book'
  | 'BookManager'
  | 'BookManager.Import'
  | 'BookManager.Export'
  | 'BookManager.Upload'
  | 'BookManager.Download'
  | 'BookManager.PublishChanges'
  | 'Admin.ApplicationLanguage'
  | 'Admin.StepLibary'
  | 'Analytics'
  | 'Analytics.Sisense'
  | 'CustomerAccount'
  | 'FollowMe';

export enum ContentRole {
  'AllRoles' = 0,
  'EpilogueAdmin',
  'EpilogueSupportUser',
  'CustomerAdmin',
  'CustomerPowerUser',
  'CustomerContentUser'
}

export const ContentRoleDisplayText: any[] = [
  { id: ContentRole.AllRoles, text: 'All Roles' },
  { id: ContentRole.EpilogueAdmin, text: 'Epilogue Admin' },
  { id: ContentRole.EpilogueSupportUser, text: 'Epilogue Support User' },
  { id: ContentRole.CustomerAdmin, text: 'Customer Admin' },
  { id: ContentRole.CustomerPowerUser, text: 'Power User' },
  { id: ContentRole.CustomerContentUser, text: 'Content User' },
];
