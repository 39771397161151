export class CategoryModel {
  id: number = 0;
  name: string = '';
  depth: number = 1;
  childrenInit: CategoryModel[] = [];
  children: CategoryModel[] = [];
  childrenSelected: CategoryModel[] = [];
  childCategorySelected: number = 1;
  levelSelected: number = 5;
  displayCategory: boolean = false;
  toggleCategory: boolean = false;
  isExistChildCategory: boolean = false;
  categoryName: string = '';

  constructor() {
    this.id = 0;
    this.name = "";
    this.depth = 1;
    this.childCategorySelected = 1;
    this.levelSelected = 5;
    this.displayCategory = false;
    this.toggleCategory = false;
    this.isExistChildCategory = false;
  }
}

export class CategoryUtils {
  public static getCategoryById(categories: CategoryModel[], searchId: number): CategoryModel | null {
    for (let category of categories) {
      if (category.id === searchId) {
        return category;
      } else {
        if (category.children.length > 0) {
          let childrenResult = this.getCategoryById(category.children, searchId);

          if (childrenResult) {
            return childrenResult;
          }
        }
      }
    }
    return null;
  }

  public static convertHierachyCategoriesIntoArrayIds(categories: CategoryModel[], result: number[]): number[] {
    if (categories === undefined) {
      return [0];
    }
    for (let category of categories) {
      result.push(category.id);
      if (category.children.length > 0) {
        this.convertHierachyCategoriesIntoArrayIds(category.children, result);
      }
    }
    return result;
  }
}

export class CategoryTreeViewModel {
  isExpanded: boolean = true;
  id!: number;
  name!: string;
  children: CategoryTreeViewModel[] = [];

  static sortByName(a: CategoryTreeViewModel, b: CategoryTreeViewModel) {
    if (!a.name){
      a.name = '';
    }

    if (!b.name){
      b.name = '';
    }
    return a.name.toUpperCase().localeCompare(b.name.toUpperCase());
  }
}
