import { Utility } from '../shared/helper/utilities';
import { DocumentElement, StepElement, GroupElement } from './document-element.model';
// import { ElementTypeKind } from './element-type.model';

export class Recording {
  id: string = '';
  overrideOption: OverrideOptions = OverrideOptions.None;
  elements: DocumentElement[] = [];
  originalStepElements: StepElement[] = [];

  fromJson(json: any): Recording {
    if (!json) {
      return this;
    }

    this.id = json.id || '';
    this.overrideOption = OverrideOptions.None || this.overrideOption;
    this.elements = json.elements ? json.elements.map(DocumentElement.fromDto) : [];
    this.originalStepElements = json.originalStepElements ? json.originalStepElements.map(DocumentElement.fromDto) : [];
    return this;
  }

  getStepIndex(step: StepElement): number {
    let doFind = (step: StepElement, elements: DocumentElement[]): number => {
      if (!step) {
        return -1;
      }

      let steps = Utility.flattenAray(elements.map(e => e instanceof GroupElement ? e.subElements : e))
        .filter((e: any) => e instanceof StepElement);
      return steps.findIndex((s: any) => s.id === step.id);
    };

    return doFind(step, this.elements);
  }

  getRefStepElementInStandaloneNote(documentElement: DocumentElement, elements: DocumentElement[] = []): StepElement | null {
    let doFind = (note: DocumentElement, elements: DocumentElement[]): StepElement | null => {
      let result: StepElement | null = null;

      // if (!note || note.kind !== ElementTypeKind.Note) {
      //   return result;
      // }

      let flattenElementsList =
        Utility.flattenAray(elements.map(e => e instanceof GroupElement ? e.subElements : e));
      let index = flattenElementsList.findIndex((e: any) => e.id === note.id) - 1;

      while (index > -1) {
        result = flattenElementsList[index];

        if (!(result instanceof StepElement)) {
          result = null;
          index--;
        } else {
          break;
        }
      }

      return result;
    };

    return elements.length ? doFind(documentElement, elements) : doFind(documentElement, this.elements);
  }
}

export enum OverrideOptions {
  None = 0,
  No = 1,
  Yes = 2
}

export enum RecordingCallbackAction {
  Default = 1,
  CloseAfterRecord = 2
}

export enum BranchRecordingStatus {
  None = 0,
  Recording = 1,
  Fail = 2
}
