export class Rect {
  constructor(public left: number = 0,
              public top: number = 0,
              public width: number = 0,
              public height: number = 0) {
  }

  fromString(r: string): Rect {
    if (!r) {
      return this;
    }

    let values = r.split(',');
    this.left = parseInt(values[0], 10) || 0;
    this.top = parseInt(values[1], 10) || 0;
    this.width = parseInt(values[2], 10) || 0;
    this.height = parseInt(values[3], 10) || 0;
    return this;
  }

  toString(): string {
    return `${this.left}, ${this.top}, ${this.width}, ${this.height}`;
  }

  fromJson(json: any): Rect {
    if (!json) {
      return this;
    }

    this.left = json.Left || 0;
    this.top = json.Top || 0;
    this.width = json.Width || 0;
    this.height = json.Height || 0;
    return this;
  }

  get empty() {
    return !this.left && !this.top && !this.width && !this.height;
  }

  setDefaultBound() {
    this.width = 60;
    this.height = 30;
    this.top = 0;
    this.left = 0;
  }

  clone() {
    let rect = new Rect();

    rect.left = this.left;
    rect.top = this.top;
    rect.width = this.width;
    rect.height = this.height;

    return rect;
  }

  multiply(weight: number) {
    this.left = Math.ceil(this.left*weight);
    this.top = Math.ceil(this.top*weight);
    this.width = Math.ceil(this.width*weight);
    this.height = Math.ceil(this.height*weight);

    return this;
  }

  divide(weight: number) {
    this.left = Math.ceil(this.left/weight);
    this.top = Math.ceil(this.top/weight);
    this.width = Math.ceil(this.width/weight);
    this.height = Math.ceil(this.height/weight);

    return this;
  }

  isEqual(area: Rect) {
    return ~~this.top === ~~area.top && ~~this.width === ~~area.width && ~~this.left === ~~area.left && ~~this.height === ~~area.height;
  }
}
