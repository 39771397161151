import { LockOutStatus } from './admin-customer.model';
import { Utility } from '../shared/helper/utilities';
import { advisorSearchFiltersSettings } from './advisor-search-filters-settings.model';

export class Tenant {
  id: number = 0;
  name: string = '';
  isFirstLogin!: boolean;
  tenancyName: string = '';
  accountName: string = '';
  logo: string = '';
  haveNotification: TypeNotification = TypeNotification.None;
  isAccessTargetApplication: boolean = true;
  isShowHelpLink: boolean = true;
  isInternal: boolean = false;
  dateFormat: string = '';
  isOpusAIFeatureEnable: boolean = false;

  static fromJson(json: any): Tenant {
    return new Tenant().fromJson(json);
  }

  fromJson(json: any): Tenant {
    this.id = json.id || 0;
    this.name = json.name || '';
    this.isFirstLogin = json.isFirstLogin || false;
    this.tenancyName = json.tenancyName || '';
    this.accountName = json.accountName || '';
    this.logo = json.logo || '';
    this.haveNotification = json.haveNotification;
    this.isAccessTargetApplication = json.isAccessTargetApplication;
    this.isShowHelpLink = json.isShowHelpLink;
    this.isInternal = json.isInternal;
    this.dateFormat = json.dateFormat;
    if (json.isOpusAIFeatureEnable === undefined) {
      json.isOpusAIFeatureEnable = false;
    }
    this.isOpusAIFeatureEnable = json.isOpusAIFeatureEnable;
    return this;
  }
}

export class TenantSso {
  id: number = 0;
  tenancyName: string = '';
  logo: string = '';
  ssoUrl: string = '';
  deactivatedUser: boolean = false;

  static fromJson(json: any): TenantSso {
    return new TenantSso().fromJson(json);
  }

  fromJson(json: any): TenantSso {
    this.id = json.id || 0;
    this.tenancyName = json.tenancyName || '';
    this.logo = json.logo ? Utility.getImageUrlNoneCache(json.logo) : '';
    this.ssoUrl = json.ssoUrl || '';
    if (!json.isOwnerAccount && (json.deactivatedUser || json.lockedOut !== LockOutStatus.None)) {
      this.deactivatedUser = true;
    }
    return this;
  }
}

export class TenantGeneralSetting {
  availableLanguageUserConfigurable: boolean = false;
  allowAutoUpdate: boolean = false;
  allowFollowMeAdivsor: boolean = false;
  enableContentRequest: boolean = true;
  advisorSearchFiltersSettings: advisorSearchFiltersSettings = new advisorSearchFiltersSettings();
  dateFormat: string = '';
  aiEnabledRoles: number = 1;
  scimEnabled:boolean = false;

  fromJson(json: any): TenantGeneralSetting {
    if (!json) return this;
    this.availableLanguageUserConfigurable = json.availableLanguageUserConfigurable;
    this.allowAutoUpdate = json.allowAutoUpdate;
    this.allowFollowMeAdivsor = json.allowFollowMeAdivsor;
    this.enableContentRequest = json.enableContentRequest;
    this.advisorSearchFiltersSettings = json.advisorSearchFiltersSettings;
    this.dateFormat = json.dateFormat;
    this.aiEnabledRoles = json.aiEnabledRoles;
    this.scimEnabled = json.scimEnabled;
    return this;
  }
}

export enum TypeNotification {
  None = 0,
  BillingType = 1
}
