import { CategoryContextModel } from './category-context.model';

export class CategoryItemModel {
  rowId: number = Date.now(); //using for remove in DOM
  id: number = 0;
  name: string = '';
  isExpanded: boolean = false;
  children: CategoryItemModel[] = [];
  addedChildren: CategoryItemModel[] = [];
  parentCategoryId: number = 0;
  depth: number = 0;
  level: number = 0;
  categoryContexts: CategoryContextModel[] = [];
  isDefault: boolean = false;
  isEditable: boolean = false;
  isAdded: boolean = false;
  public static addAllOptionId = -1;

  clone(): CategoryItemModel {
    let cloneItem: CategoryItemModel = new CategoryItemModel();

    cloneItem.id = this.id;
    cloneItem.name = this.name;
    cloneItem.parentCategoryId = this.parentCategoryId || 0;
    cloneItem.depth = this.depth;
    cloneItem.children = this.children.slice();
    cloneItem.level = this.level;
    cloneItem.categoryContexts = this.categoryContexts ? this.categoryContexts.map(contexts => new CategoryContextModel().fromJson(contexts)) : [];

    return cloneItem;
  }

  static cloneItem(item: CategoryItemModel) {
    let cloneItem: CategoryItemModel = new CategoryItemModel();

    cloneItem.id = item.id;
    cloneItem.name = item.name;
    cloneItem.parentCategoryId = item?.parentCategoryId || 0;
    cloneItem.depth = item.depth;
    cloneItem.children = item.children.slice();
    cloneItem.level = item.level;
    cloneItem.categoryContexts = item.categoryContexts ? item.categoryContexts.map(contexts => new CategoryContextModel().fromJson(contexts)) : [];

    return cloneItem;
  }

  static mapToTree(items: CategoryItemModel[]): CategoryItemModel[] {
    items.forEach(c => c.children = []);

    let remainingItems = items;
    let itemMap: { [id: number]: CategoryItemModel } = {};
    let rootItems: CategoryItemModel[] = [];

    while (remainingItems.length) {
      let skippedItems: CategoryItemModel[] = [];

      remainingItems.forEach(item => {
        if (item?.parentCategoryId) {
          if (itemMap[item?.parentCategoryId]) {
            itemMap[item?.parentCategoryId].children.push(item);
            itemMap[item.id] = item;
          } else {
            skippedItems.push(item);
          }
        } else {
          rootItems.push(item);
          itemMap[item.id] = item;
        }
      });

      if (skippedItems.length === remainingItems.length) {
        break;
      } else {
        remainingItems = skippedItems;
      }
    }

    return rootItems;
  }

  static listFromTree(categories: CategoryItemModel[], resultCategories: CategoryItemModel[]) {
    for (let i = 0; i < categories.length; i++) {
      let cloneUnChildCategory: CategoryItemModel = CategoryItemModel.cloneItem(categories[i]);
      cloneUnChildCategory.children = [];
      resultCategories.push(cloneUnChildCategory);

      if (categories[i].children.length > 0) {
        this.listFromTree(categories[i].children, resultCategories);
      }
    }
  }

  static indexDepthCategories(categoriesNone: CategoryItemModel[], categoriesHad: CategoryItemModel[]) {
    for (let i = 0; i < categoriesNone.length; i++) {
      let index = categoriesHad.findIndex(category => category.id === categoriesNone[i].id);

      if (index >= 0) {
        categoriesNone[i].depth = categoriesHad[index].depth ? categoriesHad[index].depth : 1;
      } else {
        categoriesNone[i].depth = 1;
      }

      if (categoriesNone[i].children.length > 0) {
        this.indexDepthCategories(categoriesNone[i].children, categoriesHad);
      }
    }
  }

  get hasChildrenItem(): boolean {
    return this.children.length !== 0;
  }

  get categoryFullName(): string {
    return [this.name].concat(this.children.map(c => c.categoryFullName))
      .filter(n => n).join(', ');
  }

  fromJson(json: any): CategoryItemModel {
    if (json === null)
      return this;
    this.id = json.id;
    this.name = json.name;
    this.parentCategoryId = json?.parentCategoryId || 0;
    this.depth = json.depth;
    this.children = json.children ? json.children.map((json: any) => new CategoryItemModel().fromJson(json)) : [];
    this.level = json.level || 0;
    this.categoryContexts = json.categoryContexts ? json.categoryContexts.map((contexts: any) => new CategoryContextModel().fromJson(contexts)) : [];
    this.isDefault = json.isDefault;
    return this;
  }

  toJson(): any {
    return {
      id: this.id,
      name: this.name,
      parentCategoryId: this.parentCategoryId || 0,
      depth: this.depth,
      children: this.children ? this.children.map((c: CategoryItemModel) => c.toJson()) : [],
      categoryContexts: this.categoryContexts ? this.categoryContexts.map((contexts: CategoryContextModel) => contexts.toJson()) : [],
      level: this.level || 0
    };
  }

  static categoriesToTree(categories: CategoryItemModel[], depth: number = 0): CategoryItemModel[] {
    if (!categories || categories.length === 0) return [];
    let categoryItems = categories.filter(c => !c?.parentCategoryId).map(c => {
      c.depth = 1;
      return c;
    });
    if (depth === 1) {
      return categoryItems;
    }
    return this.recursiveChildren(categoryItems, categories, 1, depth);
  }

  static recursiveChildren(categoryItems: CategoryItemModel[], categories: CategoryItemModel[], startDepth: number, endDepth: number): CategoryItemModel[] {
    categoryItems.forEach(item => {
      item.children = categories.filter(c => c?.parentCategoryId === item.id).map(c => {
        c.depth = startDepth + 1;
        return c;
      });
      if (item.children.length > 0 && (endDepth <= 0 || startDepth < endDepth)) {
        this.recursiveChildren(item.children, categories, startDepth + 1, endDepth);
      }
    });
    return categoryItems;
  }

  static sortByStringNameDesc(a: any, b: any) {
    let nameA = a.name.toUpperCase();
    let nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return 1;
    }
    if (nameA > nameB) {
      return -1;
    }
    return 0;
  }

  static sortByStringNameAsc(a: CategoryItemModel, b: CategoryItemModel) {
    let nameA = a.name.toUpperCase();
    let nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }
}
