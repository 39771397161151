import { BrowserLocale, LocaleModel } from '../models';
import { ElementGroup } from './element-group.model';

export enum ElementTypeKind {
  Generic = 1,
  Step = 2,
  Group = 3,
  Note = 4,
  Audio = 5,
  Video = 6,
  DocumentLink = 7,
  TrueFalseQuiz = 8,
  MultipleQuiz = 9,
  FillInTheBlankQuiz = 10,
  ConsolidateTableStep = 11,
  PowerPointSlides = 12,
  PowerPointSlideStep = 13,
  Section = 14,
  CoreDocument = 15,
  TableOfContent = 16,
  BookTitle = 17,
  Introduction = 18,
  Conclusion = 19,
  Fork = 20,
  Path = 21
}


export enum ElementTypeCategory {
  Group = 0,
  Step = 1,
  Header = 2,
  Footer = 3,
  Title = 4,
  Note = 5,
  Audio = 6,
  Basic = 7,
  Video = 8,
  Link = 9,
  Quiz = 10,
  PowerPoint = 11,
  Section = 12,
  CoreDoc = 13,
  Toc = 14,
  Fork = 15,
  Conclusion = 16
}
export class ElementType {
  public static IconClasses: string [] = ['mdi-notification-clear-all',
    'mdi-format-title', 'mdi-page-layout-footer',
    'mdi-page-layout-header', 'mdi-note-outline',
    'mdi-microphone-outline', 'mdi-notification-clear-all',
    'mdi-video', 'mdi-link-variant', 'mdi-checkbox-marked-outline'];
  category: number = ElementTypeCategory.Header;
  group: ElementGroup = new ElementGroup();
  groupId: number = 0;
  iconClass: string = '';
  id: number = 0;
  rootId: number;
  kind: number = ElementTypeKind.Generic;
  name: string = '';
  languageKey: string = '';
  specificToDocType: boolean = false;
  tenantId: number = 0;
  availableInDoctypes: number [] = [];
  defaultInDoctypes: number [] = [];
  otherNames: ElementTypeLocaleName [] = [];
  isDefault: boolean = false;
  isStatic: boolean = false;
  isDeActivate: boolean = false;
  originalDefaultElements: boolean = false;

  constructor() {
    this.iconClass = ElementType.IconClasses[0];
    this.category = ElementTypeCategory.Title;
  }

  localizeName(ISOCode: string) {
    let localeId = LocaleModel.defaultLocaleId;
    switch (ISOCode) {
      case 'en':
        localeId = BrowserLocale.English;
        break;
      case 'fr':
        localeId = BrowserLocale.French;
        break;
      case 'de':
        localeId = BrowserLocale.Germany;
        break;
    }

    let locales = this.otherNames.filter((locale: ElementTypeLocaleName) => locale.localeId === localeId);
    return locales.length > 0 ? locales[0].name : this.name;
  }

  fromJson(json: any) {
    if (!json) {
      return this;
    }

    this.category = +json.category;
    this.group = new ElementGroup().fromJson(json.group);
    let groupId = +json.groupId;
    this.groupId = isNaN(groupId) ? null : groupId;
    this.iconClass = json.iconClass;
    this.id = +json.id;
    this.kind = +json.kind;
    this.name = json.name;
    this.isDefault = json.isDefault;
    this.rootId = json.rootId;
    this.isStatic = json.isStatic;
    this.languageKey = json.languageKey;
    this.specificToDocType = json.specificToDocType;
    let tenantId = +json.tenantId;
    this.tenantId = isNaN(tenantId) ? null : tenantId;
    this.otherNames = json.otherNames.map((json: any) => new ElementTypeLocaleName().fromJson(json));
    this.isDeActivate = json.isDeActivate;
    this.originalDefaultElements = this.isDefault && !json.availableInDoctypes.some(item => {
      if (item) { return item.isCustomDefault; }
      return false;
    });
    if (json.availableInDoctypes && json.availableInDoctypes.length > 0) {
      this.availableInDoctypes = json.availableInDoctypes.map((d: any) => {
        if (d)
          return d.documentTypeId;
        return 0;
      });
      let listDefaultCustom = json.availableInDoctypes.filter(item => {
        if (item) { return item.isCustomDefault; }
        return false;
      });
      if (listDefaultCustom && listDefaultCustom.length > 0) {
        this.defaultInDoctypes = listDefaultCustom.map((d: any) => d.documentTypeId);
      }
    }
    return this;
  }

  toJson(): any {
    return {
      category: this.category,
      group: this.group ? this.group.toJson() : null,
      iconClass: this.iconClass,
      id: this.id,
      kind: this.kind,
      name: this.name,
      isDefault: this.isDefault,
      rootId: this.rootId,
      isStatic: this.isStatic,
      languageKey: this.languageKey,
      specificToDocType: this.specificToDocType,
      tenantId: this.tenantId,
      groupId: this.groupId === 0 ? null : this.groupId,
      isDeActivate: this.isDeActivate,
      otherNames: this.otherNames.map(name => name.toJson()),
      availableInDoctypes: this.availableInDoctypes.map(d => {
        let isDefaultCustom = true;
        if (this.id && this.isDefault && !this.defaultInDoctypes.length){
          isDefaultCustom = null;
        }else {
          isDefaultCustom = this.defaultInDoctypes.some(dc => {
            return dc === d;
          });
        }
        return {elementTypeId: this.id, documentTypeId: d, isCustomDefault: isDefaultCustom};
      })
    };
  }

  clone() {
    return new ElementType().fromJson(this.toJson());
  }
}

export class ElementTypeLocaleName {
  name: string = '';
  localeId: number = 0;

  public static fromDictionary(dict: any) {
    let names: ElementTypeLocaleName[] = [];

    if (!dict) {
      return names;
    }

    for (let locale in dict) {
      names.push(new ElementTypeLocaleName().fromJson({
        name: dict[locale],
        localeId: +locale
      }));
    }

    return names;
  }

  fromJson(json: any) {
    if (!json) {
      return this;
    }

    this.name = json.name;
    this.localeId = +json.localeId;

    return this;
  }

  toJson() {
    return {
      name: this.name,
      localeId: this.localeId
    }
  }

  clone() {
    return new ElementType().fromJson(this.toJson());
  }
}


export class ElementTypeDetailModel {
//   info: ElementType = new ElementType();
//   template: BuildingBlockListItem = new BuildingBlockListItem();

//   fromJson(json: any) {
//     if (!json) {
//       return this;
//     }

//     this.info = new ElementType().fromJson(json.info);
//     this.template = new BuildingBlockListItem().fromJson(json.template);

//     return this;
//   }

//   toJson() {
//     return {
//       info: this.info.toJson(),
//       template: this.template.toJson(),
//     };
//   }

//   clone() {
//     return new ElementTypeDetailModel().fromJson(this.toJson());
//   }
// }

// export class ElementTypeCategoryModel {
//   private static categoryOptions: ElementTypeCategory[] = [ElementTypeCategory.Header, ElementTypeCategory.Footer, ElementTypeCategory.Title, ElementTypeCategory.Note, ElementTypeCategory.Basic];
//   private static bookCategoryOptions: ElementTypeCategory[] = [ElementTypeCategory.Title,
//     ElementTypeCategory.Section,
//     ElementTypeCategory.Header,
//     ElementTypeCategory.Conclusion];
//   name: string = '';
//   indicator: ElementTypeCategory = ElementTypeCategory.Step;

//   public static getAllGenericElementCategories(): ElementTypeCategoryModel [] {
//     return this.categoryOptions
//       .map(key => new ElementTypeCategoryModel().fromJson({indicator: +key, name: ElementTypeCategory[+key]}));
//   }

//   public static getAllBookElementCategories(): ElementTypeCategoryModel [] {
//     return this.bookCategoryOptions
//       .map(key => {
//         let name = ElementTypeCategory[+key];
//         if (key === ElementTypeCategory.Header) {
//           name = ElementTypeKind[ElementTypeKind.Introduction];
//         }
//         return new ElementTypeCategoryModel().fromJson({indicator: +key, name: name});
//       });
//   }

//   fromJson(json: any) {
//     if (!json) {
//       return this;
//     }

//     this.name = json.name;
//     this.indicator = json.indicator;
//     return this;
//   }
}


export enum ElementTypeId {
  StepElement = 1,
  TitleElement = 2,
  FooterElement = 3,
  NoteElement = 7,
  AudioElement = 8,
  VideoElement = 13
}