import { DocumentMetadataDocType } from './document.model';
import { RoleModel } from './v2/role.model';

export enum SimulationType {
  Demo = 1,
  Interactive = 2,
  Test = 3,
  FollowMe = 4
}

export enum DocumentDocTypeId {
  Standard = 1,
  Generic = 2,
  Enduser = 3,
  DemoSimulation = 4,
  InteractiveSimulation = 5,
  TestSimulation = 6,
  Accessibility = 7,
  FollowMe = 8,
  Eassistant = 9,
}

export enum DoctypeName {
  eAssistant = 'eAssistant'
}

export enum StatusDocument {
  UnPublished = 0,
  Published = 1
}

export const StatusDocumentText = [
  {id: StatusDocument.Published, text: 'Published'},
  {id: StatusDocument.UnPublished, text: 'Unpublished'}
];

export class DocumentTypeModel {
  id: number = 0;
  isSimulation: boolean = false;
  name: string = '';
  simulationType: number = 0;
  styleClass: string = '';
  suitableElementTypes: number[] = [];
  tenantId: number = 0;
  documentTypeSetting: DocumentTypeSettingModel = new DocumentTypeSettingModel();
  public static totalDoctypes: number = 8;
  public static totalStaticDoctypes: number = 5;
  public static hiddenDocumentTypeIds: number[] = [9];

  public static isStaticDocType(doctype: DocumentTypeModel) {
    switch (doctype.id) {
      case DocumentDocTypeId.Standard:
      case DocumentDocTypeId.Generic:
      case DocumentDocTypeId.Enduser:
      case DocumentDocTypeId.Accessibility:
        return true;
      default: {
        return false;
      }
    }
  }

 get getSvgIcon() {
    switch (this.id) {
      case DocumentDocTypeId.FollowMe:
      return '../../../../assets/svg/follow_me_updated.svg';
      case DocumentDocTypeId.Standard:
        return '../../../../assets/svg/standard_updated.svg';
      case DocumentDocTypeId.Generic:
        return '../../../../assets/svg/generic_updated.svg';
      case DocumentDocTypeId.Accessibility:
        return '../../../../assets/svg/accessibility_updated.svg';
      case DocumentDocTypeId.Enduser:
        return '../../../../assets/svg/enduser_updated.svg';
      case DocumentDocTypeId.InteractiveSimulation:
        return '../../../../assets/svg/interactive_simulation_updated.svg';
      case DocumentDocTypeId.DemoSimulation:
        return '../../../../assets/svg/demo_simulation_updated.svg'
      case DocumentDocTypeId.TestSimulation:
        return '../../../../assets/svg/test_simulation_updated.svg';
    }
    return '';
  }

  get getIcon() {
    switch (this.id) {
      case DocumentDocTypeId.Standard:
        // text-box-multiple
        return 'mdi-text-box-multiple';
      case DocumentDocTypeId.Generic:
        // text-box-multiple-outline
        return 'mdi-text-box-multiple-outline';
      case DocumentDocTypeId.Enduser:
        // text-box
        return 'mdi-text-box';
      case DocumentDocTypeId.Accessibility:
        return 'M20.5 6c-2.61.7-5.67 1-8.5 1s-5.89-.3-8.5-1L3 8c1.86.5 4 .83 6 1v13h2v-6h2v6h2V9c2-.17 4.14-.5 6-1l-.5-2zM12 6c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z';
      case DocumentDocTypeId.Eassistant:
        // timeline-help
        return 'mdi-timeline-help';
      case DocumentDocTypeId.DemoSimulation:
        // monitor-eye
        return 'mdi-monitor-eye';
      case DocumentDocTypeId.InteractiveSimulation:
        // gesture-double-tap
        return 'mdi-gesture-double-tap';
      case DocumentDocTypeId.TestSimulation:
        // certificate
        return 'mdi-certificate';
      default: {
        return '';
      }
    }
  }

  get isEAssistantDoctype() {
    return this.styleClass.toUpperCase() === DoctypeName.eAssistant.toUpperCase();
  }

  fromJson(obj: any) {
    if (!obj) {
      return this;
    }

    this.id = obj.id;
    this.isSimulation = obj.isSimulation;
    this.name = obj.name;
    this.styleClass = obj.styleClass;
    this.simulationType = +obj.simulationType;
    this.tenantId = +obj.tenantId;
    this.documentTypeSetting = new DocumentTypeSettingModel().fromJson(obj.documentTypeSetting);

    if (obj.suitableElementTypes) {
      this.suitableElementTypes = obj.suitableElementTypes.map((st: any) => { 
        
        if(typeof st === 'object')
        {
          return st.elementTypeId;
        }
        
        return st;
      });
    }

    return this;
  }

  toJson(): any {
   return {
     id : this.id,
     isSimulation : this.isSimulation,
     name : this.name,
     simulationType: +this.simulationType,
     styleClass : this.styleClass,
     suitableElementTypes : this.suitableElementTypes,
     tenantId : +this.tenantId,
     documentTypeSetting: this.documentTypeSetting.toJson()
   };
  }
}

export class DocumentDocTypeModel {
  branchId: number = 0;
  documentTypeId: number = 0;
  documentType: DocumentDocTypeInfoModel | null = null;

  fromJson(json: any) {
    if (!json) {
      return this;
    }

    this.branchId = +json.branchId;
    this.documentTypeId = +json.documentTypeId;
    this.documentType = json.documentType ? new DocumentDocTypeInfoModel().fromJson(json.documentType): null;

    return this;
  }

  fromDocumentMetadataDocType(branchId: number, type: DocumentMetadataDocType) {
    this.branchId = branchId;
    this.documentTypeId = type.id;
    this.documentType = null;

    return this;
  }

  toJson(): any {
    return {
      branchId: this.branchId,
      documentTypeId: this.documentTypeId,
      documentType: this.documentType ? this.documentType.toJson() : null
    }
  }
}

export class DocumentDocTypeInfoModel {
  id: number = 0;
  name: string = '';

  fromJson(json: any): DocumentDocTypeInfoModel {
    if (!json) {
      return this;
    }

    this.id = +json.branchId;
    this.name = json.name;

    return this;
  }

  toJson(): any {
    return {
      id: this.id,
      name: this.name
    }
  }
}

export class DocumentTypeSettingModel {
  id: number = 0;
  isActive: boolean = true;
  useFieldTable: boolean = true;
  isUseOnContent: boolean = false;
  isImageBeforeText: boolean = true;
  fromJson(obj: any) {
    if (!obj) {
      return this;
    }
    this.id = obj.id;
    this.isActive = obj.isActive || false;
    this.useFieldTable = obj.useFieldTable || false;
    this.isUseOnContent = obj.isUseOnContent || false;
    this.isImageBeforeText = obj.isImageBeforeText || false;
    return this;
  }

  toJson(): any {
    return {
      id: this.id,
      isActive: this.isActive,
      useFieldTable: this.useFieldTable,
      isUseOnContent : this.isUseOnContent,
      isImageBeforeText: this.isImageBeforeText
    };
  }
}

export class DocumentRoleModel {
  branchId: number = 0;
  roleId: number = 0;

  fromJson(json: any) {
    if (!json) {
      return this;
    }
    this.branchId = +json.branchId;
    this.roleId = +json.roleId;
    return this;
  }

  toJson(): any {
    return {
      branchId: this.branchId,
      roleId: this.roleId
    };
  }
}
