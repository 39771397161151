export class GeneralErrModel {
  Type: string; // Error or Warning or Other
  Title: string;
  ClientTime: string;
  Content: string;
  Optional: string;

  constructor() {
    this.Type = "";
    this.Title = "";
    this.ClientTime = "";
    this.Content = "";
    this.Optional = "";
  }
}
