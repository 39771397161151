export class DepartmentModel {
  id: number = 0;
  displayName: string = '';
  name: string = '';
  code: string = '';
  includeInDefault: boolean = false;
  tenantId: any = null;
  parentId: any = null;
  users: Array<DepartmentUserModel> = [];

  static sortByDisplayName (a: DepartmentModel, b: DepartmentModel) {
    if (!a.displayName){
      a.displayName = '';
    }

    if (!b.displayName){
      b.displayName = '';
    }

    return a.displayName.toUpperCase().localeCompare(b.displayName.toUpperCase());
  }

  fromJson(json: any): DepartmentModel {
    if (!json) {
      return this;
    }

    this.id = json.id;
    this.code = json.code;
    this.displayName = json.displayName;
    this.name = json.displayName;
    this.tenantId = json.tenantId || null;
    this.parentId = json.parentId || null;
    this.includeInDefault = json.includeInDefault;
    if (json.users) {
      this.users = json.users.map((json: any) => new DepartmentUserModel().fromJson(json)) || [];
    } else {
      this.users = [];
    }

    return this;
  }

  toJson(): any {
    let json: any = {};
    json.id = this.id;
    json.code = this.code;
    json.displayName = this.displayName;
    json.tenantId = this.tenantId || null;
    json.parentId = this.parentId || null;
    json.includeInDefault = this.includeInDefault;
    json.users = this.users.map((user: DepartmentUserModel) => user.toJson()) || [];
    return json;
  }



}

export class DepartmentUserModel {
  id: number = 0;
  name: string = '';
  surname: string = '';
  emailAddress: string = '';

  fromJson(json: any): DepartmentUserModel {
    this.id = json.id;
    this.emailAddress = json.emailAddress;
    this.name = json.name;
    this.surname = json.surName;
    return this;
  }

  toJson(): any {
    return {
      id: this.id,
      emailAddress: this.emailAddress,
      name: this.name,
      surname: this.surname
    }
  }

  get fullName(): string {
    return this.name + ' ' + this.surname;
  }
}
