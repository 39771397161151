export class CountryModel {
  id: number = 0;
  code: string = '';
  countryName: string = '';
  isActive: boolean = false;
  currency!: string;
  addressFormat!: number;
  phoneCode: string = '';

  canEdit: boolean = false;

  fromJson(json: any) {
    if (!json) {
      return this;
    }

    this.id = json.id;
    this.code = json.code;
    this.countryName = json.countryName;
    this.isActive = json.isActive;
    this.currency = json.currency;
    this.addressFormat = json.addressFormat;
    this.phoneCode = json.phoneCode;
    this.canEdit = json.canEdit;

    return this;
  }

  toJson() {
    return {
      id: this.id,
      code: this.code,
      countryName: this.countryName,
      isActive: this.isActive,
      canEdit: this.canEdit,
    };
  }
}

export class CountryEntry {
  countryName: string = '';
  currency!: string;
  addressFormat!: number;
  phoneCode: string = '';
}

export class CountryResultModel {
  countries: CountryModel[] = [];
  totalItems: number = 0;
}

export class CountryFilterModel {
  keyWord: string = '';
  currentPage: number = 1;
  itemsPerPage: number = 20;
  totalItems: number = 0;
  orderASC: boolean = true;
  orderByColumn: number = 1;

  clear() {
    this.keyWord = '';
    this.orderASC = true;
    this.totalItems = 0;
    this.itemsPerPage = 20;
    this.currentPage = 1;
  }
}

export class CurrencyModel {
  id!: number;
  name!: string;
  code!: string;
  isActive!: boolean;
  isAmericanExpressIgnore!: boolean;

  fromJson(json: any): CurrencyModel {
    if (!json) {
      return this;
    }

    this.id = json.id;
    this.name = json.name;
    this.code = json.code;
    this.isActive = json.isActive;
    this.isAmericanExpressIgnore = json.isAmericanExpressIgnore;

    return this;
  }
}


