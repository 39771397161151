export class CacheEntry {
  token: string = '';
  expireInSeconds: number = 0;
}

export class SignedUrlType {
  public static recordings = 'Recordings';
  public static system = 'System';
  public static uploads = 'Uploads';
}

export class SignedUrlEntry {
  value: string = '';
  key: string = '';
  cloudfront: string = '';
  bucketName: string = '';
  s3Link: string = '';

  fromJson(json: any) {
    if (!json) {
      return this;
    }

    this.key = json.key;
    this.value = json.value[0];
    this.cloudfront = json.value[1];
    this.bucketName = json.value[2];
    this.s3Link = json.value[3];
    return this;
  }
}

interface CachedTokens {
  [key: string]: CacheEntry;
}

interface SignedUrls {
  [key: string]: SignedUrlEntry;
}

export class AuthCacheService {
  private static cache: CachedTokens = {};
  private static signedUrls: SignedUrls = {};
  static save(entry: CacheEntry) {
    const key = window.location.hostname;
    this.cache[key] = entry;
  }

  static saveSignedUrls(entries: SignedUrlEntry[]) {
    this.signedUrls = {};
    const that = this;
    entries.forEach(entry => {
      that.signedUrls[entry.key] = entry;
    });
  }

  static getSignedUrl(key: string): SignedUrlEntry {
    let url = this.signedUrls[key];
    if (!url) {
      return new SignedUrlEntry();
    }
    return url;
  }

  static get(): CacheEntry{
    let auth = this.cache[window.location.hostname];
    if (!auth){
      return new CacheEntry();
    }
    return auth;
  }

  static remove(){
    delete this.cache[window.location.hostname];
  }
}
