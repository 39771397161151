import { IPagination } from '../shared/interfaces';
export class AdminCustomerModel {
  id: number = 0;
  executionTime: string = '';
  userName: string = '';
  firstName: string = '';
  lastName: string = '';
  emailAddress: string = '';
  phoneNumber: string = '';
  roleName: string = '';
  isActive: boolean = false;
  status: string = '';
  isEmailConfirmed: boolean = false;
  tenantId: number = 0;
  tenantName: string = '';
  lockedOut: number = LockOutStatus.None;
  planName: string = '';
  suspendedType: number = 0;
  lockoutEndDateUtc: Date;
  creationTime: Date;
  lastLoginTime: Date;
  isInvitedByAdmin: boolean = false;
  customerStatus = '';
  isInternal: boolean = false;
  lastLoginUserTime: string = '';
  lastLoginUserFullName: string = '';
  activeUsersCount: number = 0;
  invitedUsersCount: number = 0;
  inActiveUsersCount: number = 0;
  licenseUsed: number = 0;
  numberUsers: number = 0;
  ssoEnabled: boolean = false;
  scimEnabled: boolean = false;
  get fullName(): string {
    return this.firstName + ' ' + this.lastName;
  }

  fromJson(json: any): AdminCustomerModel {
    this.id = json.id;
    this.executionTime = json.creationTime;
    this.userName = json.userName;
    this.firstName = json.name;
    this.lastName = json.surname;
    this.emailAddress = json.emailAddress;
    this.phoneNumber = json.phoneNumber;
    this.roleName = json.roleName;
    this.isActive = json.isActive;
    this.status = json.status;
    this.isEmailConfirmed = json.isEmailConfirmed;
    this.lockedOut = json.lockedOut;
    this.tenantId = isNaN(+json.tenantId) ? null : +json.tenantId;
    this.tenantName = json.tenantName;
    this.planName = json.planName;
    this.suspendedType = +json.suspendedType;
    this.lockoutEndDateUtc = json.lockoutEndDateUtc;
    this.isInvitedByAdmin = json.isInvitedByAdmin;
    this.creationTime = json.creationTime;
    this.lastLoginTime = json.lastLoginTime;
    this.customerStatus = json.customerStatus;
    this.isInternal = json.isInternal;
    this.lastLoginUserTime = json.lastLoginUserTime;
    this.lastLoginUserFullName = json.lastLoginUserFullName;
    this.activeUsersCount = json.activeUsersCount;
    this.invitedUsersCount = json.invitedUsersCount;
    this.inActiveUsersCount = json.inActiveUsersCount;
    this.licenseUsed = json.licenseUsed;
    this.numberUsers = json.numberUsers;
    this.ssoEnabled = json.ssoEnabled;
    this.scimEnabled = json.scimEnabled;


    return this;
  }
}

export class AdminCustomerFilterModel implements IPagination {
  keyword: string = '';
  orderASC: boolean = true;
  orderByColumn: string = AdminCustomerOrderBy[1];
  // paging
  currentPage: number = 1;
  itemsPerPage: number = 20;
  totalPages: number = 0;
  totalItemCount: number = 0;
  browserLang: string = '';

  clear() {
    this.browserLang = '';
    this.keyword = '';
    this.currentPage = 1;
    this.totalPages = 1;
    this.totalItemCount = 0;
    this.orderASC = false;
  }

  fromJson(json: any): AdminCustomerFilterModel {
    this.totalPages = json.totalPages;
    this.totalItemCount = json.totalItemCount;
    return this;
  }
}

export class AdminFindUserFilterModel implements IPagination {
  keyword: string = '';
  accountName: string = '';
  creationFromDate: string;
  creationToDate: string;
  lastLoginFromDate: string;
  lastLoginToDate: string;
  orderASC: boolean = false;
  orderByColumn: string = AdminFindUserOrderBy[7];
  currentPage: number = 1;
  itemsPerPage: number = 20;
  totalPages: number = 0;
  totalItemCount: number = 0;
}

export enum AdminFindUserOrderBy {
  Name = 1,
  Surname = 2,
  EmailAddress = 3,
  RoleName = 4,
  TenantName = 5,
  CreationTime = 6,
  LastLoginTime = 7,
}

export enum AdminCustomerOrderBy {
  UserName = 1,
  FullName = 2,
  LastLoginUserTime = 3,
  PhoneNumber = 4,
  CustomerStatus = 5,
  PlanName = 6,
  TenantName = 7,
  LastLoginUserFullName = 8,
}
export enum LockOutStatus {
  None = 0,
  Automatic = 1,
  Manual = 2,
  Cancel = 3,
  Manual_Suspend_InGrace = 4,
  PendingChangeBillingType = 5
}
export enum SuspendedType {
  None = 0,
  SuspendedInPeriod = 1,
  SuspendedNotInPeriod = 2,
  SuspendedBillingType = 3 //type will out period when collect info user, see BE for more information
}
export enum CountryCode {
  USA = 'US',
  GERMANY = 'DE'
}

export enum BrowerLanguageCode {
  ENGLISH = 'en',
}
