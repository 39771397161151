import { RoleMap } from './role.model';
import { UserInfoModel } from './user.model';


export class TeamModel {
  id: number = 0;
  tenantId: number = 0;
  name: string = '';
  members: Array<TeamMember> = [];
  isOpening: boolean = false;

  fromJson(json: any): TeamModel {
    if (!json) {
      return this;
    }

    this.id = json.id;
    this.tenantId = json.tenantId;
    this.name = json.name;
    this.members = json.members.map((json: any) => new TeamMember().fromJson(json));
    return this;
  }

  get hasProjectAdmin(): boolean {
    return this.members.some(m => m.role === TeamRoleEnum.ProjectAdmin);
  }

  isTeamNameExisted(teamName: string): boolean {
    if(this.name === teamName) return true;

    return false;
  }
}

export class TeamMember
{
  teamId: number = 0;
  userId: number = 0;
  role!: TeamRoleEnum;
  permissions!: PermissionMap;
  user!: UserInfoModel;

  get roleName(): string {
    return TeamRoleMap[this.role];
  }

  get permissionText(): string {
    if (this.role !== TeamRoleEnum.TeamLead) {
      return '';
    }

    let permissionText = [PermissionMap.Record, PermissionMap.Edit, PermissionMap.QA, PermissionMap.Master]
    .filter(p => this.permissions & p)
    .map(p => PermissionMap[p])
    .join(', ');

    return permissionText.replace('Master', 'Final Review / Publish Task')
  }

  fromJson(json: any): TeamMember {
    this.teamId = json.teamId;
    this.userId = json.userId;
    this.role = json.role;
    this.permissions = json.permissions;

    this.user = new UserInfoModel().fromJson(json.user);

    return this;
  }

  get teamRoleEnumOrder(): number {
    return TeamRoleOrder.findIndex( (val) => val === this.role);
  }

}

export class UserModel {
  id: number = 0;
  userId: number = 0;
  tenantId: number = 0;
  name: string = '';
  surname: string = '';
  emailAddress: string = '';
  roleId: number = 0;
  roleName: string = '';
  departmentId: number = 0;
  applicationId: number = 0;
  functionAreaId: number = 0;
  levelId: number = 0;
  permissionList: Array<PermissionModel> = [];
  _permissionNames: string = '';
  typeName: string = '';
  isConcurrentUser: boolean = false;

  fromJson(json: any): UserModel {
    this.id = json.userId || json.id;
    this.userId = json.userId || json.id;
    this.name = json.name;
    this.surname = json.surname;
    this.emailAddress = json.emailAddress;
    this.roleId = json.roleId;
    this.roleName = json.roleName = RoleMap[json.roleId] || json.roleName;
    if(json.permissionList) {
      this.permissionList = json.permissionList.map((json: any) => new PermissionModel().fromJson(json));
    }
    this.typeName = json.typeName;
    this.isConcurrentUser = json.isConcurrentUser;
    return this;
  }

  get fullName() {
    return this.name + ' ' + this.surname;
  }

  get permissionNames() {
    if(this.roleId && this.roleId == TeamRoleEnum.TeamLead) {
      let permissions: string[] = [];
      this.permissionList.forEach(permission => {
        permissions.push(permission.permissionName);
      });
      this._permissionNames = permissions.join(', ');
    }
    return this._permissionNames;
  }

  get isProjectAdmin(): boolean {
    if(this.roleId == TeamRoleEnum.ProjectAdmin) return true;

    return false;
  }


}

export class PermissionModel {
  permissionId: number = 0;
  permissionName: string = '';

  fromJson(json: any): PermissionModel {
    this.permissionId = json.permissionId;
    this.permissionName = PermissionMap[json.permissionId];
    return this;
  }
}

export enum PermissionMap {
  'Record' = 2,
  'Edit' = 1,
  'QA' = 4,
  'Master' = 8,
  'All' = 15
}

export const TeamPermissionText: any[] = [
  { id: 2, text: 'Record' },
  { id: 1, text: 'Edit' },
  { id: 4, text: 'QA' },
  { id: 8, text: 'Final Review / Publish Task' }
];

export enum TeamRoleEnum {
  ProjectAdmin = 1,
  TeamLead = 2,
  Editor = 3,
  Recorder = 4,
  QA = 5
}

export const TeamRoleOrder: TeamRoleEnum[] = [TeamRoleEnum.ProjectAdmin, TeamRoleEnum.TeamLead, TeamRoleEnum.Recorder, TeamRoleEnum.Editor, TeamRoleEnum.QA];

export enum TeamRoleMap {
  'Project Admin' = 1,
  'Team Lead' = 2,
  'Editor' = 3,
  'Recorder' = 4,
  'QA' = 5
}


export enum TeamPermissionEnum {
  Record = 2,
  Edit = 1,
  QA = 4,
  Master = 8,
  All = 15
}

export const TeamRoleText: any[] = [
  { id: 1, text: 'Project Admin', key: 'TEAM_PROJECT_ADMIN' },
  { id: 2, text: 'Team Lead', key: 'TEAM_TEAM_LEAD' },
  { id: 4, text: 'Recorder', key: 'TEAM_RECODER' },
  { id: 3, text: 'Editor', key: 'TEAM_EDITOR' },
  { id: 5, text: 'QA', key: 'QA' }
];

export class TeamRole {
  static getTeamRoleText(roleList: any[], multipleLang: any) {
    return roleList.map(role => {
      role.text = multipleLang && multipleLang[role.key] ? multipleLang[role.key] : role.text;
      return role;
    });
  }
}
