import { AbstractControl } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { ProjectStatus } from '../../models';
import { ColumnModel } from '../../models/column.model';
import { Utility } from '.';
import { ViewFilterModel, ViewModel, ViewSortColumnModel } from '../../models/view.model';

export class Constants {
  public static IsPartner = false;
  public static IsHost = false;
  public static SystemName = 'opus';
  public static DomainURL = environment.API;
  public static DomainURL_V2 = environment.API_V2;
  public static RootDomain = environment.OPUS_DOMAIN;
  public static IdentityServerDomain = environment.IDENTITY_SERVER_DOMAIN;
  public static SocketIO = environment.SOCKETIO_URL;
  public static PPProcessor = "PdfProcessing";
  public static PPProcessorURL = "http://localhost:21053/api";
  public static readonly SisenseDomain = environment.SISENSE_DOMAIN;
  public static readonly Epilogue = 'Epilogue';
  public static readonly adminTenant = 'admin';
  public static readonly TokenAuth = 'tokenauth';
  public static readonly RenewToken = 'tokenauth/renew';
  public static readonly AuthenticateUser = 'tokenauth/authenticate-user';
  public static readonly Authenticate = 'tokenauth/authenticate';
  public static readonly ImpersonateAuthenticate = 'tokenauth/impersonate-authenticate';
  public static readonly Login = 'account/login';
  public static readonly CustomerInsight = 'Insight';
  public static readonly Auditlog = 'AuditLog';
  public static readonly GeneralErrLog = 'generallog/SaveLogData';
  public static readonly adminCustomerList = 'User';
  public static readonly Register = 'UserAccount';
  public static readonly BccEmailNotification = 'bcc_email_notification';
  public static readonly team = 'Team';
  public static readonly Project = 'Project';
  public static readonly category = 'Category';
  public static readonly Categories = 'categories';
  public static readonly Account = 'Account';
  public static readonly Authoring = 'Authoring';
  public static readonly ContentRequest = 'ContentRequest';
  public static readonly ApplicationV2 = 'appsetting';
  public static readonly Download = 'Download';
  public static readonly MenuSystem = 'menu/getmenus';
  public static readonly Upload = 'upload';
  public static readonly RetryUpload = 'retryUpload';
  public static readonly ImportCustomElements = 'ImportCustomElement';
  public static readonly Category = 'Category';
  public static readonly Departments = 'Department';
  public static readonly DepartmentsV2 = 'departments';
  public static readonly Task = 'Task';
  public static readonly Tasks = 'Tasks';
  public static readonly Billing = 'Billing';
  public static readonly Language = 'Language';
  public static readonly RootPageTitle = 'Epilogue IQ';
  public static readonly Workflow = 'Workflow';
  public static readonly DocumentManager = 'DocumentManager';
  public static readonly Advisor = 'Advisor';
  public static readonly Content = 'Content';
  public static readonly Books = 'Books';
  public static readonly Edition = 'Edition';
  public static readonly Setting = 'Setting';
  public static readonly Settings = 'settings';
  public static readonly SingleSignOn = 'sso';
  public static readonly Common = 'Common';
  public static readonly CommonV2 = 'common';
  public static readonly TenantV2 = 'tenants';
  public static readonly CustomAttribute = 'Attribute';
  public static readonly Notification = 'Notification';
  public static readonly TermURL = `${environment.AWS_S3_BUCKET_LINK}/opuseps.assets/Terms%2Bof%2BUse%2B-%2BEpilogue%2B-%2BFINAL.pdf`;
  public static readonly GermanyCountryCode = 'DE';
  public static readonly DefaultCountryCode = 'US';
  public static readonly DefaultCurrencyCode = 'USD';
  public static readonly Dashboard = 'dashboard';
  public static readonly MenuTasks = 'tasks';
  public static readonly ContentBar = `${Constants.Dashboard}/ContentBars`;
  public static readonly ControlType = 'control_types';
  public static readonly AddControlTypes = 'control_types/add_control_types';
  public static readonly Action = 'Actions';
  public static readonly AddActions = 'Actions/add_actions';
  public static readonly projects = 'projects';
  public static readonly active = 'active';
  public static readonly members = 'members';
  public static readonly users = 'users';
  public static readonly EULAs = 'eulas';
  public static readonly searchCredential = 'search_credential';
  public static readonly Search = 'Search';
  public static readonly TaskV2 = 'tasks';
  public static readonly Opus = 'Opus';
  public static readonly ContextUrl = 'opuseps.com';
  public static readonly DomainURL_Context = environment.OPUS_DOMAIN;
  public static readonly countryV2 = 'countries';
  public static readonly elementTypeV2 = 'element_types';
  public static readonly applicationSettingV2 = 'application_settings';
  public static readonly captureContext = 'capture-context';
  public static readonly destroyCaptureContext = 'destroy-capture-context';
  public static readonly informLostFileMessage = 'Warning: There are still files being Imported to your account. If you take this action, the files that have not fully uploaded will be lost.';
  public static readonly titleLostFileMessage = 'Files Uploading';
  public static readonly ok = 'Ok';
  public static readonly cancel = 'Cancel';
  public static readonly myTasksUrl = '/app/tenant/task/my-tasks';
  public static readonly SisenseUsers = 'sisense_users';
  public static readonly Dashboards = 'dashboards';
  public static readonly Sisense = 'sisense';
  public static readonly AddSisenseCookies = 'add_cookies';
  public static readonly LogoutSisenseUser = 'logout';
  public static readonly DocumentHTMLOutput = 'document_html_output';
  public static readonly cancelUploading = 'cancel_uploading';
  public static readonly OPSB = 'OPSB';
  public static readonly DEFAULT_PREFIX_THEME_NAME = 'Opus Theme';
  public static readonly MaxAllowedFileLength = 209715200;
  public static readonly ExtensionsNotCheckFileLength = ['RSTX', 'OPSX', 'ODARC', 'HTMP', 'OPSB', 'URL'];
  public static readonly controlImageHighlight = 'controlImageHighlight';
  public static readonly customBorderSize = ['1', '2', '4', '6', '8', '10', '12'];
  public static readonly stepControlNumber = 'stepControlNumber';
  public static readonly themeName = 'themeName';
  public static readonly customFontSize = ['8', '9', '10', '11', '12', '14', '16', '18', '20', '24', '28', '30', '32', '36', '48', '60', '72', '96'];
  public static readonly eAssistant = 'EASSISTANT';
  public static readonly isMasterImpersonate = 'imi';
  public static readonly https = 'https://';
  public static readonly timeGetNewData = 60000;
  public static readonly targetApplications = 'target_applications';
  public static readonly tenantHostName = 'epiloguegroup';
  public static readonly SPECIAL_BROWSER = 'SPECIALBROWSER';
  public static readonly ASTERISK = '*';
  public static readonly BROWSER_PROCESSES = ['MicrosoftEdge.exe', 'iexplore.exe', 'chrome.exe', 'firefox.exe'];
  public static readonly COMMON_CAPTIONS = [
    '- Mozilla Firefox',
    '- Internet Explorer',
    '- Microsoft Internet Explorer',
    '- Windows Internet Explorer',
    '- Microsoft Edge',
    '- Google Chrome'
  ];
  public static readonly dateFormatForText: string = 'MM/DD/YYYY HH:mm:ss';
  public static readonly ignoreFilterParams: string[] = ['caption', 'processName', 'url', 'application', 'department', 'threshold', 'language', 'role'];
  public static readonly EMBEDDED_ADVISOR_DOMAIN = environment.EMBEDDED_ADVISOR_DOMAIN;
  public static readonly MaxNumberSteps = 300;
  public static readonly maxContentZoom = 1.5;
  public static readonly minContentZoom = 0.2;
  public static readonly rangeZoom = 0.1;
  public static readonly addAll = 'Add All';
  public static readonly CurrentCultureLanguage = 'current_culture_language';
  public static readonly ColumnChoice = 'column-choice';
  public static readonly DocumentEditorCommon = 'Editor';
  public static readonly Cache_8_Hours = 1000 * 60 * 60 * 8;
  public static readonly CaptureWebContext = 'Capture-Web-Context';
  public static readonly CapturedWebContext = 'Captured-Web-Context';
  public static readonly IsFMRecorderInstalled = 'is-recorder-installed';
  public static readonly FMRecorderInstalled = 'recorder-installed';
  public static readonly DefaultTitle = 'title';
  public static readonly EmbeddedAdvisorSetting = "tenants/ea-setting";
  public static readonly GetDocumentBranchDocTypes = "Authoring/GetDocumentBranchDocTypes";
  public static readonly DocumentManagerUrl = '/app/tenant/content/document-manager';
  public static readonly CreateDocumentUrl = '/app/tenant/content/media-manager/create';
  public static readonly DashboardItemsPerPageLimit = 10;
}

export class ControllerConstants {
  public static readonly landingPageUrl = 'landing';
  public static readonly loginPageUrl = 'login';
}

export class LinkActionConstants {
  public static readonly landingPageUrl = '/landing/';
  public static readonly loginPageUrl = 'login';
  public static readonly dashboardPageUrl = '/app/host/dashboard';
  public static readonly tenantDashboardPageUrl = '/app/tenant/dashboard';
  public static readonly gettingStartedCustomerAdminUrl = '/app/getting-started/admin/getting-started-departments';
  public static readonly gettingStartedContentUserUrl = '/app/getting-started/user/user-getting-started-add-department';
  public static readonly gettingStartedUserUrl = '/app/getting-started/user';
  public static readonly billingInfoUrl = '/app/tenant/setting/billing-customer-settings';
  public static readonly adminCustomerListUrl = '/app/host/customer';
  public static readonly gettingStartedSetPlanUrl = '/app/getting-started/admin/getting-started-select-plan';
  public static readonly applicationLanguageUrl = '/app/host/setting/language';
  public static readonly customMetadataPageUrl = '/app/tenant/setting/attribute';
  public static readonly downloadPageUrl = 'app/tenant/setting/download';
  public static readonly mediaManagerPageUrl = '/app/tenant/content/media-manager';
  public static readonly bookManagerPageUrl = '/app/tenant/content/book-manager';
  public static readonly recycleBinPageUrl = '/app/tenant/content/deleted-media-manager';
  public static readonly mediaManagerHostPageUrl = '/app/host/content/media-manager';
  public static readonly recycleBinHostPageUrl = '/app/host/content/deleted-media-manager';
  public static readonly dashboardMasterAdmin = environment.OPUS_URL + '/app/host/dashboard';
  public static readonly remoteLogoutURLDefault = environment.OPUS_URL + `/login`;
  public static readonly editorPageUrl = '/app/editor/tasks';
  public static readonly contentPageUrl = '/app/content/tasks';
  public static readonly editorLandingPageUrl = '/landing/recorder';
  public static readonly myTaskHostPageUrl = '/app/host/task/my-tasks';
  public static readonly contentListPageUrl = '/app/tenant/content/content-list';

}

export class LinkChromeStore {
  public static readonly webRecorderStore = 'https://chrome.google.com/webstore/detail/opus-web-recorder-dev/aodklmoepgndmhikgofkniolofgkngai';
  public static readonly advisorAgentStore = 'https://chrome.google.com/webstore/detail/opus-web-listener-dev/fhdccmoifkfoenfneokcijblgjglfckp';
}

export class PublicApiConstants {
  public static readonly authTokenUrl = 'tokenauth/token';
  public static readonly documents = 'v1/documents?user_name={username}&key_word={keyword}';
  public static readonly swaggerPublicApiURL = 'swagger/v1/ui/index.html';
  public static readonly auth2TokenUrl = 'tokenauth/v2/token';
  public static readonly auth2AuthorizeUrl = 'tokenauth/v2/authorize';
}

export class NoteElementConstants {
  public static DefaultWidth = 500;
  public static DefaultHeight = 150;
  public static DefaultTop = 100;
  public static DefaultLeft = 150;
  public static DefaultFontSize = 14;
  public static DefaultHandleSize = 18;
  public static readonly DefaultHeaderFooterSizeSimulationView = 154;
  public static ImageWidthLimitForAspectRatio = 1900;

  public static BaseFontSizeForAspectRatio_2x = 6.5;
  public static BaseWidthForAspectRatio_2x = 400;
  public static BaseHeightForAspectRatio_2x = 120;
  public static HandleFontSize_2x = 30;


  public static ImageWidthLimitForAspectRatio_3x = 3000;
  public static BaseFontSizeForAspectRatio_3x = 10;
  public static BaseWidthForAspectRatio_3x = 550;
  public static BaseHeightForAspectRatio_3x = 165;
  public static HandleFontSize_3x = 36;

}


export class CookieKey {
  public static readonly FullName = 'user_login';
  public static readonly AccountName = 'user_account_name';
  public static readonly Logo = 'logo';
  public static readonly Profile = 'profile';
  public static readonly LastLoginCompanyName = 'company_name';
  public static readonly ShowEnqueueTaskDialogKey = 'show_enqueue_task_dialog_key';
  public static readonly ChannelID = 'channel_id';
  public static readonly lastAction = 'lastAction';
  public static readonly PushNotification = 'push_notification';
  public static readonly isRequiredMetadata = 'is_Required_Metadata';
  public static readonly defaultLogo = 'default_logo';
  public static readonly slidingTokenExpiration = 'sliding_token_expiration';
  public static readonly TenantId = 'tenant_id';
  public static readonly tenants = 'list_tenant';
  public static readonly opusWidget = 'opus_widget';
  public static readonly signedUrlKey = 'signed_url_key';
  public static readonly currentUserProfilekey = 'current_user_profile';
  public static readonly menusKey = 'menus_cache';
  public static readonly applicationLanguagesKey = 'application_languages_key';
  public static readonly appSettingsKey = 'app_settings_key';
  public static readonly currentApplicationLanguageKey = 'current_application_language_key';
  public static readonly elementGroupsKey = 'element_groups_key';

  public static readonly CustomAttributeSection_Book = 'custom_attribute_section_book';
  public static readonly CustomAttributeSection_WorkFlow = 'custom_attribute_section_workFlow';

  public static readonly Token = "token";

}

export class RequestType {
  public static readonly Message = "Message";
  public static readonly Conversation = "Conversation";
  public static readonly Flag = "Flag";
  public static readonly Feedback = "Feedback";
}

export class Inactivity {
  public static readonly checkInterval = 120000; // MS
  public static readonly minutesUntilAutoLogout = 120000; // MS
  public static readonly idleTimeout = 86400; // S
  public static readonly timeout = 5; // S
  public static readonly ping = 60; // S

}

export class ReturnValue {
  public static readonly Success = 'Success';
}

export class DefaultContentMessage {
  public static readonly DefaultTitleGeneralLog = 'Epilogue message';
  public static readonly DefaultContentGeneralLog = 'The application has encountered an error. Please try again';
  public static readonly DefaultContentAccessDeny = 'You have been denied permission to access this page';
  public static readonly MsgCreatingNewDomain = 'New domain is being created. Please wait for a moment';
  public static readonly MsgSaveSuccess = 'Your data has been successfully saved';
  public static readonly MsgConfirmAccountType = 'The account info being confirm, please wait';
}

export class Secret {
  public static readonly FroalaKey = 'FEK';
}


export class FileIntent {
  public static readonly Profile = 'Profile';
  public static readonly Logo = 'Logo';
  public static readonly Other = 'Other';
  public static readonly RecorderLogo = 'RecorderLogo';
  public static readonly RecorderLogoOffline = 'RecorderLogoOffline';
  public static readonly RecorderIcon = 'RecorderIcon';
  public static readonly RecorderIconOffline = 'RecorderIconOffline';
  public static readonly AdvisorIcon = 'AdvisorIcon';
  public static readonly AdvisorIconOffline = 'AdvisorIconOffline';
  public static readonly AdvisorIconOutline = 'AdvisorIconOutline';
  public static readonly AdvisorLogo = 'AdvisorLogo';
  public static readonly AdvisorLogoOffline = 'AdvisorLogoOffline';
  public static readonly AdvisorLogoOutline = 'AdvisorLogoOutline';
}

export class ThirdParty {
  // public static readonly Intercom = 'hide_default_launcher';
  public static readonly FullStory = 'FullStory';
}

export class Regexs {
  // public static Email = '^[a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$';
  // public static Email = '^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$';
  // public static Email = '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$'; // allow +
  public static Email = '^[a-zA-ZÀ-ú0-9_+-]+(\\.[a-zA-ZÀ-ú0-9_+-]+)*@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*(\\.[a-zA-Z]{2,6})$'; // allow +
  public static Tenant = '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*$';
  public static OnlyNumber = '^[0-9]*$';
  public static onlyLetter = '^[A-Za-z-_ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\\s!@#$%^&*()_+=-`~\\\\\\]\\[{}|\';:/.,?><]*$';
  public static DoubleNumber = '^[-+]?[0-9]*\.?[0-9]+$';
  //  public static CardNumber = '^(?:4[0-9]{12}(?:[0-9]{3})?|(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|6(?:011|5[0-9]{2})[0-9]{12}|(?:2131|1800|35\d{3})\d{11})$';
  public static CardNumber = '^[0-9]*$';
  public static PhoneNumber = '^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$';
  public static PhoneFormat: any = [/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public static PhoneFormatNonCode: any = [/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public static DomainFormat = '^[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?$';

  public static PhoneNumberNonUS = '^[+]?[0-9- ]*$';

  // Password matching expression. Password must be at least 8 characters, and must include at least one upper case letter, one number, one
  // special letter,
  public static PasswordStrength = '^(?=.*[0-9]+.*)(?=.*[A-Z])(?=.*[!@#$%]).{8,}$';
  // public static Percentage = '^(\\d{1,2}([.]\\d+)?|100)%?$';
  public static Percentage = '^([0-9]{1,2}|100)%?$';
  // Password validator Requires 6-15 characters including at least 1 upper or lower alpha, and 1 digit. It should disallow just about everything
  // else, inluding extended characters.
  // (?!^[0-9]*$)(?!^[a-zA-Z]*$)^([a-zA-Z0-9]{6,15})$

  // Password matching expression. Password must be at least 8 characters, and must include at least one upper case letter, one lower case letter,
  // one number
  // ^(?=.*[a-z])(?=.*[0-9]+.*)(?=.*[A-Z]).{7,}$
  // ^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9]).*$
  public static AlphaNumeric = '^[a-zA-Z0-9 ]+$';
  public static readonly alphaNumericDashes: string = '^[a-zA-Z0-9 -]+$';

  public static Url = '\\(?(?:(http|https|ftp):\\/\\/)?(?:((?:[^\\W\\s]|\\.|-|[:]{1})+)@{1})?((?:www.)?(?:[^\\W\\s]|\\.|-)+[\\.][^\\W\\s]{2,4}|localhost(?=\\/)|\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3})(?::(\\d*))?([\\/]?[^\\s\\?]*[\\/]{1})*(?:\\/?([^\\s\\n\\?\\[\\]\\{\\}\\#]*(?:(?=\\.)){1}|[^\\s\\n\\?\\[\\]\\{\\}\\.\\#]*)?([\\.]{1}[^\\s\\?\\#]*)?)?(?:\\?{1}([^\\s\\n\\#\\[\\]]*))?([\\#][^\\s\\n]*)?\\)?';
  public static DocumentUrl = '(https?://(?:www.|(?!www))[a-zA-Z0-9_.+/-][a-zA-Z0-9_.+/-]+[a-zA-Z0-9_.+/-].[^]{2,}|www.[a-zA-Z0-9_.+/-][a-zA-Z0-9-_.+/-]+[a-zA-Z0-9_.+/-].[^]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9_.+/-].[^]{2,}|www.[a-zA-Z0-9_.+/-].[^]{2,})';
}
export class CustomValidator {
  public static invalidPrice(control: AbstractControl) {
    const input = control.value;
    if (!input) {
      return null;
    }
    if (input < 0) {
      return { 'zero': true };
    }
    let value = parseFloat(input.toString().replace(new RegExp('\\$', 'g'), '').replace(new RegExp(',', 'g'), ''));
    if (+value >= 0) {
      return null;
    } else {
      return { 'zero': true };
    }
  }
}

export function billingNumberFormatPipe(conformedValue: string) {
  let newValue = conformedValue.slice(1).replace(/_/g, '0');
  if (!newValue || newValue === '.') {
    newValue = '0';
  }
  newValue = '$' + parseFloat(newValue).toFixed(2);
  let indexesOfPipedChars = [];
  if (newValue) {
    if (conformedValue.length === newValue.length) {
      return conformedValue;
    }
    else {
      let addedChars = newValue.length - conformedValue.length;
      if (addedChars > 0) {
        for (let i = addedChars; i > 0; i--) {
          indexesOfPipedChars.push(newValue.length - i);
        }
        return {
          value: newValue,
          indexesOfPipedChars
        };
      } else {
        return conformedValue;
      }
    }
  } else {
    return conformedValue;
  }
}

/*
export class PriceMask {
  public static priceMask = createNumberMask({
    prefix: '$',
    suffix: '',
    allowDecimal: true,
    includeThousandsSeparator: true,
    decimalSymbol: '.',
    decimalLimit: 2,
    allowLeadingZeroes: true
  });
}
export class PercentMask {
  public static percentMask = createNumberMask({
    prefix: '',
    suffix: '%',
    allowDecimal: false,
    includeThousandsSeparator: true,
    decimalSymbol: '.',
    decimalLimit: 2,
    allowLeadingZeroes: true,
  });
}

export class DayMask {
  public static dayMask = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: false,
    includeThousandsSeparator: true,
    decimalSymbol: '.',
    decimalLimit: 0,
    allowLeadingZeroes: true,
  });

}
*/

export class InvoiceBilling {
  public static readonly ChargeAutomatically = 'charge_automatically';
  public static readonly SendInvoice = 'send_invoice';
}

export class ContactSupport {
  public static readonly PhoneNumber = '913-555-1234';
  public static readonly EmailSupport = 'askus@epiloguesystems.com';
}

export class SystemConfiguration {
  public static readonly AutoSaveRecordingTimer = 5000;
}

export function getMedian(array: number[]) {
  if (!array) return 0;
  let data = array.filter(e => e !== 0);
  let count = data.length;
  if (count === 0) return 0;
  let middle: number = Math.floor(count / 2);
  data.sort(function (a, b) {
    return a - b;
  });
  if (count % 2 === 0)
    return (data[middle] + data[middle - 1]) / 2;
  else
    return data[middle];
}

export class ErrorCode {
  public static readonly CANNOT_CHANGE_STAGE = 2004;
}

export class LoginResultType {
  public static readonly Success = 1;
  public static readonly InvalidUserNameOrEmailAddress = 2;
  public static readonly InvalidPassword = 3;
  public static readonly UserIsNotActive = 4;
  public static readonly InvalidTenancyName = 5;
  public static readonly TenantIsNotActive = 6;
  public static readonly UserEmailIsNotConfirmed = 7;
  public static readonly UnknownExternalLogin = 8;
  public static readonly LockedOut = 9;
  public static readonly UserPhoneNumberIsNotConfirmed = 9;
}

export class NotificationMessageConstants {
  public static readonly CONTENT_PUBLISHED = `Content <b>%%documentTitle%%</b> was published to your Library.`;
  public static readonly CONTENT_PUBLISHED_MESSAGE = `Content was published to your Library. It will be available for AI Chat in a couple of minutes.`;
  public static readonly CONTENT_PUBLISHED_WITHOUT_AI = `Content <b>%%documentTitle%%</b> was published to your Library.`;
  public static readonly CONTENT_PUBLISHED_WITHOUT_AI_MESSAGE = `Content was published to your Library.`;
  public static readonly CONTENT_ENABLED_TO_AI = `Content <b>%%documentTitle%%</b> has been successfully enabled for AI Chat`;
  public static readonly CONTENT_ENABLED_TO_AI_MESSAGE = `Content has been successfully enabled for AI Chat`;
  public static readonly COMMENT_APPROVED = `Your <span style="color:#3ca7f1">Comment</span> was approved by an Admin`;
  public static readonly PROJECT_DUE = `A <span style="color:#3ca7f1">Project is due</span> on `;
  public static readonly TASK_ASSIGNED = `A <span style="color:#3ca7f1">New Task</span> has been assigned to you`;
  public static readonly TASK_ASSIGNED_MESSAGE = `A New Task has been assigned to you`;
  public static readonly TASK_ASSIGNED_WITH_COMMENT = `A New Task has been assigned to you <b>with a Comment</b>`;
  public static readonly TASK_ASSIGNED_WITH_COMMENT_MESSAGE = `A New Task has been assigned to you with a Comment`;
  public static readonly ALERT_MESSAGE = `System Alert`;
  public static readonly REVIEW_LRS = `It's time to review your personal data to ensure it's accuracy.`;
  public static readonly TASK_UNASSIGNED = `An <span style="color:#3ca7f1">Unassigned Task</span> is available`;
  public static readonly CONTENT_DOWNLOADED = `Pdf download link is available`;
  public static readonly VIDEO_DOWNLOADED = `Video download link is available`;
  public static readonly CONTENT_REQUEST_SUBMITTED = ` has submitted a new content request:<br/>`;
  public static readonly NO_CONTENT_MESSAGE = "Can't download the video, Document contains no steps";
}

export class UserStatus {
  public static readonly Active = 'Active';
  public static readonly Inactive = 'Inactive';
  public static readonly Invited = 'Invited';
}

export class UserType {
  public static readonly Admin = 'Admin';
  public static readonly SupportUser = 'SupportUser';
  public static readonly Translator = 'Translator';

}

export class LanguageUI {
  public static readonly DEPARTMENT = 'DEPARTMENT';
  public static readonly DEPARTMENTS_TIPS = 'DEPARTMENTS_TIPS';
  public static readonly DEPARTMENTS_TIPS_CU = 'DEPARTMENTS_TIPS_CU';
  public static readonly DEPARTMENTS_TIPS_CA = 'DEPARTMENTS_TIPS_CA';
  public static readonly CATEGORY = 'CATEGORY';
  public static readonly SMALL_DESCRIPTION = 'SMALL_DESCRIPTION';
  public static readonly SMALL_DESCRIPTION_CU = 'SMALL_DESCRIPTION_CU';
  public static readonly SMALL_DESCRIPTION_CA = 'SMALL_DESCRIPTION_CA';
  public static readonly SMALL_DESCRIPTION_SU = 'SMALL_DESCRIPTION_SU';
  public static readonly SMALL_DESCRIPTION_OA = 'SMALL_DESCRIPTION_OA';
  public static readonly GETTING_STARTED_TIPS = 'GETTING_STARTED_TIPS';
  public static readonly GETTING_STARTED = 'GETTING_STARTED';
  public static readonly USER_GETTING_STARTED = 'USER_GETTING_STARTED';
  public static readonly GETTING_STARTED_INFO_CONTENT = 'GETTING_STARTED_INFO_CONTENT';
}

export class ApplicationLanguage {
  public static readonly SettingPrefixLanguage = 'Application_Language';
}

export class LanguageCode {
  public static readonly defaultLanguage = 'EN';
  public static readonly settingPrefixLanguage = 'Application_Language';
}

export class StatementForwarding {
  // auth type
  public static readonly NO_AUTH = 'no auth';
  public static readonly BASIC_AUTH = 'basic auth';
  public static readonly TOKEN = 'token';

  // protocol
  public static readonly HTTP = 'http';
  public static readonly HTTPS = 'https';
}

export enum OutputDocumentType {
  PDF = 1,
  SCORM2004 = 2,
  SCORM12 = 3,
  DocumentHTMLOutput = 4,
  DownloadCSV = 5,
  DownloadVideo = 6

}

export enum EntityType {
  User = 0,
  Content = 1,
  Project = 2,
  Task = 3
}

export enum CellTypes {
  heading = 0,
  data = 1,
}

export enum OutputBookType {
  PDF = 1,
  HTMLOutput = 2,
  SCORM12 = 3,
  SCORM2004 = 4
}

export enum OpusTenantUserRelationType {
  NotFound = 0,
  Invited = 1,
  Actived = 2,
  InActived = 3,
  InCompleted = 4
}

export enum EventType {
  Hover = 0,
  Focus = 1,
  MouseEnter = 2,
  MouseLeave = 3
}

export class SignedUrlPolicyConstants {
  public static readonly SignedUrlEndTimeKey = 'AWS:EpochTime';
  public static readonly SignedUrlPolicyMatchString = 'Policy=';
  public static readonly NumType = 'number';
}

export class ContentRequestStatus {
  public static readonly Pending = 'Pending';
  public static readonly Accepted = 'Accepted';
  public static readonly Rejected = 'Rejected';
}

export class LocalStorageConstants {
    public static readonly contentViews = "content-views";
    public static readonly isFirstLogin = "is-first-login";
    public static readonly currentUserProfile = 'current_user_profile';
    public static readonly eTag = 'e-tag';
    public static readonly projectViews = "project-views";
    public static readonly TaskViews = "task-views"
}

export enum TourPages {
  WelcomeToOpus = 0,
  InstallRecorderExtension = 1,
  PinOpusRecorder = 2,
  IntroductionVideo = 3,
}

export enum SortOrder {
  none = 0,
  asc = 1,
  desc = 2,
}

export enum ApiResponse {
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  NO_CONTENT = 204,
  MOVED_PERMANENTLY = 301,
  FOUND = 302,
  NOT_MODIFIED = 304,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  CONFLICT = 409,
  INTERNAL_SERVER_ERROR = 500,
  SERVICE_UNAVAILABLE = 503
};

export class BadgeType {
  public static readonly Pill = 'pill';
}

export class BadgeText {
  public static readonly Published = 'Published';
  public static readonly Unpublished = 'Unpublished';
  public static readonly New = 'New';
  public static readonly Revision = 'Revision';
  public static readonly Draft = 'Draft';
  public static readonly Imported = 'Imported';
}

export class BadgeTheme {
  public static readonly Success = 'success';
  public static readonly Warning = 'warning';
  public static readonly Info = 'info';
}

export class TableActionsType {
  public static readonly Edit = 'edit';
  public static readonly Add = 'add';
  public static readonly Sort = 'sort';
  public static readonly More = 'more';
  public static readonly Search = 'search';
  public static readonly Menu = 'menu-item';
  public static readonly Clone = 'clone-action';
  public static readonly PublishChanges = 'publish-changes-action';
  public static readonly DeletePermanently = 'delete-permanently-action';
  public static readonly Impersonate = 'impersonate';
}

export class TableActionsText {
  public static readonly Clone = 'Clone';
  public static readonly PublishChanges = 'Publish Changes';
  public static readonly DeletePermanently = 'Delete Permanently';
}

export class SvgIcons {
    
    public static readonly DashboardIcon = 'dashboard-icon';
    public static readonly AnalyticsIcon = 'analytics-icon';
    public static readonly CustomersIcon = 'customers-icon';
    public static readonly PartnersIcon = 'partners-icon';
    public static readonly UsersIcon = 'users-icon';
    public static readonly ReportsIcon = 'reports-icon';
    public static readonly MoreIcon = 'more-icon';
    public static readonly SettingsIcon = 'settings-icon';
    public static readonly SupportIcon = 'support-icon';
    public static readonly PublicLibraryIcon = 'public-library-icon';
    public static readonly ContentManagerIcon = 'content-manager-icon';
    public static readonly ImportContentIcon = 'import-content-icon';
    public static readonly AiChatIcon = 'ai-chat-icon';
    public static readonly AiChatHistoryIcon = 'ai-chat-history-icon';
    public static readonly ContentSettingIcon = 'content-setting-icon';
    public static readonly TrashIcon = 'trash-icon';
    public static readonly ProjectsSubIcon = 'projects-sub-icon';
    public static readonly TasksIcon = 'tasks-icon';
    public static readonly TeamsIcon = 'teams-icon';
    public static readonly NotificationIcon = 'notification-icon';
    public static readonly ContentRequestsIcon = 'content-requests-icon';
    public static readonly ProjectsIcon = 'projects-icon';
    public static readonly CommentQueueIcon = 'comment-queue-icon';
    public static readonly OPFM = 'opfm';
    public static readonly TeamMembersIcon = 'team-member-icon';

    public static readonly List = 'list';
    public static readonly Card = 'card';
    public static readonly Search = 'search';
    public static readonly Export = 'export';
    public static readonly Filter = 'filter';
    public static readonly Plus = 'plus';
    public static readonly Down = 'down';
    public static readonly Up = 'up';
    public static readonly Cross = 'cross';
    public static readonly Sort = 'sort';
    public static readonly Column = 'column';
    public static readonly DotGrid = 'dot-grid';
    public static readonly UpArrow = 'up-arrow';
    public static readonly DownArrow = 'down-arrow';
    public static readonly LeftArrow = 'left-arrow';
    public static readonly Trashcan = 'trashcan';
    public static readonly Edit = 'edit';
    public static readonly View = 'view';
    public static readonly Delete = 'delete';
    public static readonly Team = 'team';
    public static readonly Publish = 'publish';
    public static readonly Archive = 'archive';
    public static readonly SortAsc = 'sort-asc';
    public static readonly SortDesc = 'sort-desc';
    public static readonly DotsHorizontal = 'dots-horizontal';
    public static readonly DotsVertical = 'dots-vertical';
    public static readonly ProjectName = 'project-name-icon';
    public static readonly Title = 'title-icon';
    public static readonly Stats = 'stats-icon';
    public static readonly State = 'state-icon';
    public static readonly Status = 'status-icon';
    public static readonly User = 'user-icon';
    public static readonly ProjectAdmin = 'project-admin-icon';
    public static readonly Owner = 'owner-icon';
    public static readonly Clock = 'clock-icon';
    public static readonly Progress = 'progress-icon';
    public static readonly Version = 'version-icon';
    public static readonly OpenIssues = 'open-issues-icon';
    public static readonly Date = 'date-icon';
    public static readonly DueDate = 'due-date-icon';
    public static readonly CreatedDate = 'created-date-icon';
    public static readonly UpdatedDate = 'updated-date-icon';
    public static readonly LastUpdated = 'last-updated-icon';
    public static readonly Departments = 'departments-icon';
    public static readonly Applications = 'applications-icon';
    public static readonly FunctionalAreas = 'functional-areas-icon';
    public static readonly SubAreas = 'sub-areas-icon';
    public static readonly ArrowRight = 'arrow-right';
    public static readonly ArrowLeft = 'arrow-left';
    public static readonly ArrowDown = 'arrow-down';
    public static readonly ArrowUp = 'arrow-up';
    public static readonly Pin = 'pin';
    public static readonly Duplicate = 'duplicate';
    public static readonly MoveLeft = 'move-left';
    public static readonly MoveRight = 'move-right';

    public static readonly Type = 'type-icon';
    public static readonly StandardIcon = "standard-icon";
    public static readonly DemoSimulationIcon = "demo-simulation-icon";
    public static readonly InteractiveSimulationIcon = "interactive-simulation-icon";
    public static readonly TestSimulationIcon = "test-simulation-icon";
    public static readonly FollowMeIcon = "follow-me-icon";
    public static readonly GenericIcon = "generic-icon";
    public static readonly EndUserIcon = "enduser-icon";
    public static readonly _508Icon = "508-icon";
    public static readonly EAssistantIcon = "eassistant-icon";

    public static readonly PublicAPI = 'public-api';
    public static readonly HTMLIcon = 'html-icon';
    public static readonly MicrosoftIcon = 'microsoft-icon';
    public static readonly ChromeIcon = 'chrome-icon';
    public static readonly ToggleIcon = 'toggle-icon';
    public static readonly BellIcon = 'bell-icon';
    public static readonly EpilogueLogo = 'epilogue-iq-icon';
    public static readonly BulbLogo = 'bulb-logo';

    public static readonly OpsbIcon = 'opsb-icon';
    public static readonly OpsxIcon = 'opsx-icon';
    public static readonly OpfmIcon = 'opfm-icon';
    public static readonly PdfIcon = 'pdf-icon';
    public static readonly DocIcon = 'doc-icon';
    public static readonly XlsxIcon = 'xlsx-icon';
    public static readonly PptIcon = 'ppt-icon';
    public static readonly HtmlFileIcon = 'html-file-icon';
    public static readonly EmptyFileIcon = 'empty-file-icon';

    public static readonly LogOut = 'logout-icon';
    public static readonly EmailIcon = 'email-icon';
    public static readonly ProfileIcon = 'profile-icon';
    public static readonly SupportMenuIcon = 'profilesupport-icon';
    public static readonly SystemSettingsIcon = 'systemsettings-icon';
    public static readonly HelpIcon = 'help-icon';
    public static readonly BillingIcon = 'billing-icon';
    public static readonly NotFoundIcon = 'notfound-icon';
    public static readonly PoweredByIcon = 'poweredby-icon';
    public static readonly Refresh = 'refresh';
    public static readonly NewsIcon = 'news-icon';
    public static readonly Impersonate = 'impersonate';
    public static readonly Add = 'add';
    public static readonly Timepicker = 'timepicker';

    public static readonly EditContent = 'edit-content';
    public static readonly AIChat = 'ai-chat';
    public static readonly Record = 'record';
    public static readonly Bookmark = 'bookmark';
    public static readonly Info = 'info';
    public static readonly EditAttributes = 'edit-attributes';
    public static readonly AdvisorContext = 'advisor-context';
    public static readonly Convert = 'convert';
    public static readonly PublishChanges = 'publish-changes';
    public static readonly UnpublishChanges = 'unpublish-changes';
    public static readonly Discard = 'discard';
    public static readonly UrlIcon = 'url-icon';

    public static readonly TableSubject = 'table-subject-icon';
    public static readonly TableCalendar = 'table-calendar-icon';
    public static readonly TableUser = 'table-user-icon';
    public static readonly TableCreated = 'table-created-icon';
    public static readonly LoadingBtn = 'loading-btn-icon';
    public static readonly Star = 'star-icon';
    public static readonly Check = 'check-icon';
    public static readonly Play = 'play-icon';
    public static readonly Recorder = 'recorder-icon';
    public static readonly Priority = 'priority-icon';

    public static readonly VideoRecorder = 'video-recorder';
    public static readonly ChangeProject = 'change-project';
    public static readonly Clone = 'clone';
    public static readonly Chain = 'chain';
    public static readonly Select = 'select';

    public static readonly HomeLine = 'home-line';

}

export class Delays {
  public static menu = 50;
}

export class GridId {
  public static readonly Project: 0;
}

export class TaskPriorityConstants {
  public static readonly High = 'high';
  public static readonly Normal = 'normal';
  public static readonly Low = 'low';
}

export class TaskTypeConstants {
  public static readonly New = 'new';
  public static readonly Pdf = 'pdf';
  public static readonly Opfm = 'opfm';
  public static readonly Opsx = 'opsx';
  public static readonly Opsb = 'opsb';
  public static readonly Ppt = 'ppt';
  public static readonly Doc = 'doc';
  public static readonly Docx = 'docx';
  public static readonly Xlsx = 'Xlsx';
  public static readonly Html = 'html';
  public static readonly Url = 'url';
}

export class TaskStatusTexts {
  public static readonly New = 'New';
  public static readonly Published = 'Published';
  public static readonly ToEdit = 'In Edit';
  public static readonly ToQA = 'In QA';
  public static readonly ToReview = 'In Review';
}

export class RecorderType {
  public static readonly Web = 'Web';
  public static readonly Desktop = 'Desktop'
}

export enum ContentTypes {
  New = 0,
  OPSB = 1,
  OPFM = 2,
  OPSX = 3,
  Word = 4,
  PDF = 5,
  PPT = 6,
  HTML = 7,
  XLSX = 8,
  URL = 9,
}

export declare enum ResponseContentType {
    Text = 0,
    Json = 1,
    ArrayBuffer = 2,
    Blob = 3,
}