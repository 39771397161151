import { CategoryItemModel } from './category-item.model';
import { ProjectStatus, ProjectMemberModel, ProjectDepartmentModel, ProjectCategoriesModel } from './project.model';
import { DocumentDocTypeModel, DocumentRoleModel } from './document-type.model';
import { UserInfoModel } from './user.model';
import { DocumentHistoryEventModel } from './document-history-event.model';
import { TaskPriority, TaskState } from './task.model';
import { DatePipe } from '@angular/common';
import { LocaleModel } from './locale.model';
import { ActionPerformAt, DocumentLogModel } from './document-log.model';
import { ElementIssueModel } from './element-issue.model';
import { ContentRole } from './authorization.model';
// const compareVersion = require('compare-versions');

export class DocumentModel {
  id: number = 0;
  tenantId: number = 0;
  name: string = '';
  isPublished: boolean = false;
  isPublishedToContent: boolean = false;
  documentIndexAIStatus: DocumentIndexAIStatusModel | null = null;
  checked: boolean = false;
  isImported: boolean = false;
  state: DocumentState | 0 = 0;
  version: number = 0;
  removalTime!: Date | null;
  updatedTime!: Date;
  creatorUserId: number = 0;
  creationTime!: Date | null
  lastModifierUserId: number = 0;
  lastModificationTime: Date | null = null;
  latestPublishedBranch: DocumentBranchModel | null = null;
  branches: DocumentBranchModel[] = [];
  historyEvents: DocumentHistoryEventModel[] = [];
  currentBranch: DocumentBranchModel | null = null;
  documentLogs: DocumentLogModel[] = [];
  rate: number = 0;
  rateCount: number = 0;
  viewTotal: number = 0;
  stateName: string = '';
  updatedTimeFormat: string = '';
  viewDocumentUrl: string = '';
  rootDocumentId: number = 0;
  isDocumentFollowMe: boolean = false;
  views: number = 0;
  commentsApproved: number = 0;
  ownerName: string = '';
  ownerEmail: string = '';
  url: string = '';
  type: number = 0;

  doctypes: any[] = []; // For private use only
  isEmptyDoctypes: boolean = false; // For private use only
  documentAdminBranch: DocumentBranchModel | null = null;
  onClick!: () => void;

  get workingAdminBranch(): DocumentBranchModel | undefined {
    return this.branches.find(b => b.isAdminBranch && b.publishedToVersion === null);
  }

  get productionBranch(): DocumentBranchModel | null {
    return this.currentBranch?.publishedToVersion == null || this.currentBranch?.publishedToVersion != null ? this.currentBranch : null;
  }

  get isDocumentWebLink(): boolean {
    return this.currentBranch?.lastContentRevision?.type === DocumentRevisionType.WebLink;
  }
  get isDocumentPPT(): boolean {
    return this.currentBranch?.extentionExternalFileContent === '.pptx';
  }
  get isDocumentWord(): boolean {
    return this.currentBranch?.extentionExternalFileContent === '.docx';
  }
  get isDocumentPDF(): boolean {
    return this.currentBranch?.extentionExternalFileContent === '.pdf';
  }
  get isDocumentHTMP(): boolean {
    return this.currentBranch?.extentionExternalFileContent === '.htmp';
  }
  get isDocumentHTML(): boolean {
    return this.currentBranch?.extentionExternalFileContent === '.html';
  }
  get isWebsiteStatic(): boolean {
    return (this.lastContentRevision?.type === DocumentRevisionType.Webpage);
  }

  get isAbleToDownload(): boolean {
    return this.lastContentRevision?.type === DocumentRevisionType.DocumentFile;
  }

  get isDocDeleted(): boolean {
    return this.removalTime !== null;
  }

  get isDocUnpublished(): boolean {
    return this.state !== DocumentState.Published;
  }

  get isUnpublishedState(): boolean {
    return this.state == DocumentState.Unpublished;
  }


  toJson(): any {
    return {
      id: this.id || 0,
      tenantId: this.tenantId || 0,
      name: this.name,
      isPublished: this.isPublished || false,
      isPublishedToContent: this.isPublishedToContent || false,
      documentIndexAIStatus: this.documentIndexAIStatus ? this.documentIndexAIStatus.toJson():null,
      isImported: this.isImported || false,
      state: this.state || 0,
      version: this.version,
      removalTime: this.removalTime,
      creatorUserId: this.creatorUserId,
      lastModifierUserId: this.lastModifierUserId,
      creationTime: this.creationTime,
      lastModificationTime: this.lastModificationTime,
      latestPublishedBranch: this.latestPublishedBranch ? this.latestPublishedBranch.toJson() : null,
      branches: this.branches ? this.branches.map((b: DocumentBranchModel) => b.toJson()) : [],
      currentBranch: this.currentBranch ? this.currentBranch.toJson() : null,
      rootDocumentId: this.rootDocumentId,
      isDocumentFollowMe: this.isDocumentFollowMe,
      documentAdminBranch: this.documentAdminBranch ? this.documentAdminBranch.toJson() : null,
    };
  }

  fromJson(json: any): DocumentModel {
    if (!json) {
      return this;
    }

    this.id = json.id || 0;
    this.tenantId = json.tenantId || 0;
    this.name = json.name;
    this.isPublished = json.isPublished;
    this.isPublishedToContent = json.isPublishedToContent;
    this.documentIndexAIStatus = json.documentIndexAIStatus ? new DocumentIndexAIStatusModel().fromJson(json.documentIndexAIStatus): null;
    this.isImported = json.isImported;
    this.state = json.state || 0;
    this.version = json.version;
    this.removalTime = json.removalTime ? new Date(json.removalTime) : null;
    this.updatedTime = json.updatedTime;
    this.creatorUserId = json.creatorUserId;
    this.lastModifierUserId = json.lastModifierUserId;
    this.creationTime = json.creationTime ? new Date(json.creationTime) : null;
    this.lastModificationTime = json.lastModificationTime ? new Date(json.lastModificationTime) : null;
    this.latestPublishedBranch = json.latestPublishedBranch ? new DocumentBranchModel().fromJson(json.latestPublishedBranch) : null;
    this.branches = json.branches ? json.branches.map((b: any) => new DocumentBranchModel().fromJson(b)).sort((db1: any, db2: any) => {
      // return compareVersion(db1.currentVersion, db2.currentVersion);
    }) : [];
    this.currentBranch = json.currentBranch ? new DocumentBranchModel().fromJson(json.currentBranch) : null;
    this.documentLogs = json.documentLogs;
    this.rate = json.rate;
    this.rateCount = json.rateCount;
    this.rootDocumentId = json.rootDocumentId;

    this.views = json.views || 0;
    this.commentsApproved = json.commentsApproved || 0;
    this.ownerName = json.ownerName;
    this.ownerEmail = json.ownerEmail;
    this.isDocumentFollowMe = json.isDocumentFollowMe;
    this.documentAdminBranch = json.documentAdminBranch ? new DocumentBranchModel().fromJson(json.documentAdminBranch) : null;
    if (json.url) {
      this.url = json.url;
    }
    return this;
  }

  get documentBranchesAreNotPublished(): DocumentBranchModel[] {
    let branches: DocumentBranchModel[] = [];
    this.branches.forEach(branch => {
      let isActivedproject: boolean = true;
      if (branch.task !== null) {
        isActivedproject = branch?.task?.project?.status === ProjectStatus.Active ? true : false;
      }
      if (branch.publishedToVersion === null && isActivedproject) {
        branches.push(branch);
      }
    });
    return branches;
  }

  get documentBranchesEdited(): DocumentRevision | null {
    let revision = null;
    if (Array.isArray(this.branches)) {
      this.branches.forEach(branch => {
        if (branch.lastContentRevision !== null) {
          revision = branch.lastContentRevision;
        }
      });
    }
    return revision;
  }

  get title(): string {
    return this.currentBranch ? this.currentBranch.title : '';
  }

  get description(): string {
    return this.currentBranch ? this.currentBranch.description : '';
  }

  get contexts(): DocumentContextModel[] {
    return this.currentBranch ? this.currentBranch.contexts : [];
  }

  get author(): string {
    return this.currentBranch ? this.currentBranch.author : '';
  }

  get owner(): string {
    return this.currentBranch ? this.currentBranch.ownerFullName : '';
  }

  get categories(): DocumentCategoryModel[] {
    return this.currentBranch && this.currentBranch.categories ? this.currentBranch.categories : [];
  }

  get departmentNames(): string {
    return this.currentBranch && this.currentBranch.departments ? this.currentBranch.departments.map(d => d?.department?.displayName).join(', ') : '';
  }

  get projects(): DocumentBasicProjectModel[] {
    let projects: DocumentBasicProjectModel[] = [];
    this.branches.forEach(branch => {
      if (branch.publishedToVersion === 0) {
        if (branch.task && branch.task.project) {
          let project = projects.find(p => p.id === branch?.task?.project?.id);
          if (!project) {
            projects.push(branch.task.project);
          }
        }
      }
    });
    return projects;
  }

  get tasks(): DocumentBasicTaskModel[] {
    let tasks: DocumentBasicTaskModel[] = [];
    this.branches.forEach(branch => {
      if (branch.publishedToVersion === 0 && branch.task) {
        tasks.push(branch.task);
      }
    });
    return tasks;
  }

  get lastContentRevision(): DocumentRevision | null {
    return this.currentBranch ? this.currentBranch.lastContentRevision : null;
  }

  get types(): DocumentDocTypeModel[] {
    return this.currentBranch ? this.currentBranch.documentTypes : [];
  }

  get documentBranchesAreEditing(): DocumentBranchModel[] {
    let documentBranches: DocumentBranchModel[] = [];

    this.branches.forEach(branch => {
      if (branch.publishedToVersion === null) {
        documentBranches.push(branch);
      }
    });

    return documentBranches;
  }

  get taskBranchesAreEditing(): DocumentBranchModel[] {
    return this.documentBranchesAreEditing.filter(b => !b.isAdminBranch);
  }

  getHierarchyCategories(): CategoryItemModel[] {
    if (!this.currentBranch || !this.currentBranch.categories) {
      return [];
    }

    let categoryItems: CategoryItemModel[] = this.currentBranch.categories.map(c => {
      let category: CategoryItemModel = new CategoryItemModel();
      category.id = c.categoryId;
      category.parentCategoryId = c.category.parentCategoryId || 0;
      category.name = c.category.name;
      category.children = [];

      return category;
    });

    if (categoryItems.length === 0) {
      return [];
    }

    return CategoryItemModel.mapToTree(categoryItems);
  }

  get documentViews(): number {
    return this.documentLogs.filter(d => d.actionType === 1 && d.actionPerformAt === ActionPerformAt.Document).length;
  }

  clone(): DocumentModel {
    let cloneItem: DocumentModel = new DocumentModel();
    cloneItem.id = this.id || 0;
    cloneItem.tenantId = this.tenantId || 0;
    cloneItem.name = this.name;
    cloneItem.isPublished = this.isPublished;
    cloneItem.isPublishedToContent = this.isPublishedToContent;
    cloneItem.documentIndexAIStatus = this.documentIndexAIStatus;
    cloneItem.isImported = this.isImported;
    cloneItem.version = this.version;
    cloneItem.removalTime = this.removalTime;
    cloneItem.creatorUserId = this.creatorUserId;
    cloneItem.lastModifierUserId = this.lastModifierUserId;
    cloneItem.creationTime = this.creationTime;
    cloneItem.lastModificationTime = this.lastModificationTime;
    cloneItem.latestPublishedBranch = this.latestPublishedBranch;
    cloneItem.branches = this.branches.slice();
    cloneItem.currentBranch = this.currentBranch;

    return cloneItem;
  }

  get isHasContent(): boolean {
    return this.currentBranch?.publishedToVersion !== null && this.currentBranch?.lastContentRevision?.type !== DocumentRevisionType.Metadata;
  }
}

export class SelectStepTitleItemModel {
  displayText: string = '';
  index = StepTitleType.WindowCaption;
  value: any = null;
}

export class DocumentMetadataModel {
  id: number = 0;
  name: string = '';
  isAdminBranch: boolean = false;
  isDocumentMetaData: boolean = false;
  title: string = '';
  description: string = '';
  lastContentRevision: string = ''
  lastContentRevisionName: string = ''
  version: number = 0;
  minorVersion: number = 0;
  creationTime: Date | null = null;
  modificationTime: Date | null = null;
  author: string | null = null;
  owner: number = 0;
  ownerFullName: string = '';
  contexts: DocumentMetadataContextModel[] = [];
  categories: DocumentMetadataCategoryModel[] = [];
  departments: DocumentMetadataDepartmentModel[] = [];
  types: DocumentMetadataDocType[] = [];
  attributes: DocumentMetadataAttributeModel[] = [];
  localeId: number = 0;
  locale: LocaleModel | null = null;
  roles: DocumentMetadataRole[] = [];
  importedMetadata: string = '';
  stepTitleType: StepTitleType = StepTitleType.WindowCaption;
  isDocumentFollowMe: boolean = false;
  IsCloneToLegacy: boolean = false;
  revisionType: DocumentRevisionType;
  url = '';
  isBook = false;
  get currentVersion(): string {
    return this.version + '.' + this.minorVersion;
  }

  get hierarchyCategories(): CategoryItemModel[] {
    if (!this.categories) {
      return [];
    }

    let categoryItems: CategoryItemModel[] = this.categories.map(c => {
      let category: CategoryItemModel = new CategoryItemModel();
      category.id = c.id;
      category.parentCategoryId = c.parentCategoryId || 0;
      category.name = c.name;
      category.children = [];

      return category;
    });

    if (categoryItems.length === 0) {
      return [];
    }

    return CategoryItemModel.mapToTree(categoryItems);
  }

  get departmentDisplayNames(): string {
    return this.departments.map(item => item.displayName).join(', ');
  }

  fromJson(json: any): DocumentMetadataModel {
    if (!json) {
      return this;
    }

    this.id = json.id || 0;
    this.name = json.name || '';
    this.isAdminBranch = json.isAdminBranch || false;
    this.isDocumentMetaData = json.isDocumentMetaData || false;
    this.title = json.title || '';
    this.version = +json.version || 0;
    this.minorVersion = +json.minorVersion || 0;
    this.description = json.description || '';
    this.creationTime = json.creationTime ? new Date(json.creationTime) : null;
    this.modificationTime = json.modificationTime ? new Date(json.modificationTime) : null;

    this.author = json.author;
    this.owner = json.owner;
    this.ownerFullName = json.ownerFullName;
    this.categories = json.categories ? json.categories.map((category: any) => new DocumentMetadataCategoryModel().fromJson(category)) : [];
    this.contexts = json.contexts ? json.contexts.map((context: any) => new DocumentMetadataContextModel().fromJson(context)) : [];
    this.departments = json.departments ? json.departments.map((department: any) => new DocumentMetadataDepartmentModel().fromJson(department)) : [];
    this.attributes = json.attributes ? json.attributes.map((attribute: any) => new DocumentMetadataAttributeModel().fromJson(attribute)) : [];
    this.types = json.types ? json.types.map((doctype: any) => new DocumentMetadataDocType().fromJson(doctype)) : [];
    this.localeId = json.localeId;
    this.locale = json.locale ? new LocaleModel().fromJson(json.locale) : null;
    this.roles = json.roles ? json.roles.map((role: any) => new DocumentMetadataRole().fromJson(role)) : [];
    this.importedMetadata = json.importedMetadata;
    this.stepTitleType = json.stepTitleType;
    this.revisionType = json.revisionType;
    this.url = json.url;
    this.isDocumentFollowMe = json.isDocumentFollowMe || false;
    return this;
  }

  toJson(): any {
    return {
      id: this.id,
      name: this.name,
      title: this.title,
      version: this.version,
      minorVersion: this.minorVersion,
      description: this.description,
      creationTime: this.creationTime,
      modificationTime: this.modificationTime,
      author: this.author,
      owner: this.owner,
      ownerFullName: this.ownerFullName,
      categories: this.categories ? this.categories.map((category: DocumentMetadataCategoryModel) => category.toJson()) : [],
      contexts: this.contexts ? this.contexts.map((context: DocumentMetadataContextModel) => context.toJson()) : [],
      departments: this.departments ? this.departments.map((department: DocumentMetadataDepartmentModel) => department.toJson()) : [],
      attributes: this.attributes ? this.attributes.map((attribute: DocumentMetadataAttributeModel) => attribute.toJson()) : [],
      types: this.types ? this.types.map((doctype: DocumentMetadataDocType) => doctype.toJson()) : [],
      localeId: this.localeId,
      locale: this.locale ? this.locale.toJson() : null,
      roles: this.roles ? this.roles.map((role: DocumentMetadataRole) => role.toJson()) : [],
      importedMetadata: this.importedMetadata,
      stepTitleType: this.stepTitleType
    };
  }
}

export class BulkDocumentMetadataModel {
  changeTitleType: number = 0;
  titleValue: string = '';
  findWhat: string = '';
  replaceWith: string = '';
  departmentsOption: number = 0;
  categoriesOption: number = 0;
  categories: DocumentMetadataCategoryModel[] = [];
  departments: DocumentMetadataDepartmentModel[] = [];
  owner: number = 0;

  fromJson(json: any): BulkDocumentMetadataModel {
    if (!json) {
      return this;
    }

    this.titleValue = json.titleValue || '';
    this.categories = json.categories ? json.categories.map((category: any) => new DocumentMetadataCategoryModel().fromJson(category)) : [];
    this.departments = json.departments ? json.departments.map((department: any) => new DocumentMetadataDepartmentModel().fromJson(department)) : [];
    this.changeTitleType = json.changeTitleType || 0;
    this.findWhat = json.findWhat || '';
    this.replaceWith = json.replaceWith || '';
    this.departmentsOption = json.departmentsOption || 0;
    this.categoriesOption = json.categoriesOption || 0;
    this.owner = json.owner || 0;
    return this;
  }

  toJson(): any {
    return {
      categories: this.categories ? this.categories.map((category: DocumentMetadataCategoryModel) => category.toJson()) : [],
      departments: this.departments ? this.departments.map((department: DocumentMetadataDepartmentModel) => department.toJson()) : []
    };
  }
}

export class DocumentBranchMetadataModel {
  documentBranchId: number = 0;
  documentMetadata: DocumentMetadataModel | null = null;

  fromJson(json: any): DocumentBranchMetadataModel {
    if (!json) {
      return this;
    }

    this.documentBranchId = +json.documentBranchId;
    this.documentMetadata = json.documentMetadata ? new DocumentMetadataModel().fromJson(json.documentMetadata) : null;

    return this;
  }

  toJson(): any {
    return {
      documentBranchId: this.documentBranchId,
      documentMetadata: this.documentMetadata ? this.documentMetadata.toJson() : null
    };
  }
}

export class BulkDocumentBranchMetadataModel {
  documentIds: any[] = [];
  proposedMetadata: BulkDocumentMetadataModel | null = null;

  toJson(): any {
    return {
      documentIds: this.documentIds,
      documentMetadata: this.proposedMetadata ? this.proposedMetadata.toJson() : null
    };
  }
}

export class DocumentMetadataDocType {
  id: number = 0;
  name: string = '';

  fromJson(json: any): DocumentMetadataDocType {
    this.id = json.id || 0;
    this.name = json.name || '';

    return this;
  }

  toJson(): any {
    return {
      id: this.id,
      name: this.name
    }
  }
}

export class DocumentAuthor {
  id: number = 0;
  userId: number = 0;
  emailAddress: string = '';
  fullName: string = '';

  fromJson(json: any) {
    if (!json) {
      return this;
    }

    this.id = json.id || 0;
    this.userId = json.userId || 0;
    this.emailAddress = json.emailAddress || '';
    this.fullName = json.fullName || '';

    return this;
  }

  toJson(): any {
    return {
      id: this.id,
      userId: this.userId,
      emailAddress: this.emailAddress,
      fullName: this.fullName
    }
  }
}

export class DocumentOwner {
  id: number = 0;
  userId: number = 0;
  emailAddress: string = '';
  fullName: string = '';
  name: string = '';

  fromJson(json: any) {
    if (!json) {
      return this;
    }

    this.id = json.userId || 0;
    this.userId = json.userId || 0;
    this.emailAddress = json.emailAddress || '';
    this.fullName = json.fullName || '';
    this.name = json.fullName || '';

    return this;
  }

  toJson(): any {
    return {
      userId: this.userId,
      emailAddress: this.emailAddress,
      fullName: this.fullName
    }
  }
}

export class DocumentCategoryModel {
  branchId: number = 0;
  categoryId: number = 0;
  category!: DocumentCategoryInfoModel;

  fromJson(json: any): DocumentCategoryModel {
    if (!json) {
      return this;
    }

    this.branchId = +json.documentId;
    this.categoryId = +json.categoryId;
    this.category = json.category ? new DocumentCategoryInfoModel().fromJson(json.category) : new DocumentCategoryInfoModel();

    return this;
  }

  toJson(): any {
    return {
      branchId: this.branchId,
      categoryId: this.categoryId,
      category: this.category ? this.category.toJson() : null
    }
  }
}

export class DocumentAttributeModel {
  branchId: number = 0;
  customAttributeId: number = 0;
  value: string = '';

  fromJson(json: any): DocumentAttributeModel {
    if (!json) {
      return this;
    }

    this.branchId = +json.documentId;
    this.customAttributeId = +json.customAttributeId;
    this.value = json.value;
    return this;
  }

  toJson(): any {
    return {
      branchId: this.branchId,
      customAttributeId: this.customAttributeId,
      value: this.value,
    };
  }
}

export class DocumentCategoryInfoModel {
  id: number = 0;
  name: string = '';
  parentCategoryId: number = 0;

  fromJson(json: any): DocumentCategoryInfoModel {
    if (!json) {
      return this;
    }

    this.id = +json.id;
    this.name = json.name;
    this.parentCategoryId = +json.parentCategoryId;

    return this;
  }

  toJson(): any {
    return {
      id: this.id,
      name: this.name,
      parentCategoryId: this.parentCategoryId
    };
  }
}

export class DocumentMetadataCategoryModel {
  id!: number;
  name!: string;
  parentCategoryId!: number;

  fromJson(json: any): DocumentMetadataCategoryModel {
    if (!json) {
      return this;
    }

    this.id = +json.id;
    this.name = json.name;
    this.parentCategoryId = +json.parentCategoryId;

    return this;
  }

  toJson(): any {
    return {
      id: this.id,
      name: this.name,
      parentCategoryId: this.parentCategoryId == null ? 0 : this.parentCategoryId
    };
  }

  static mapToTree(items: DocumentMetadataCategoryModel[]): CategoryItemModel[] {

    var itemModels = items.map(category => {
      let categoryItem: CategoryItemModel = new CategoryItemModel();
      categoryItem.id = category.id;
      categoryItem.parentCategoryId = category.parentCategoryId;
      categoryItem.name = category.name;

      return categoryItem;
    });

    return CategoryItemModel.mapToTree(itemModels);
  }

  static listFromTree(categories: CategoryItemModel[], resultCategories: DocumentMetadataCategoryModel[]) {
    for (let i = 0; i < categories.length; i++) {
      let cloneUnChildCategory: DocumentMetadataCategoryModel = new DocumentMetadataCategoryModel();
      cloneUnChildCategory.id = categories[i].id;
      cloneUnChildCategory.name = categories[i].name;
      cloneUnChildCategory.parentCategoryId = categories[i].parentCategoryId;

      resultCategories.push(cloneUnChildCategory);

      if (categories[i].children.length > 0) {
        this.listFromTree(categories[i].children, resultCategories);
      }
    }
  }

  static toCategoriesItemModel(items: DocumentMetadataCategoryModel[]): CategoryItemModel[] {
    var itemModels = items.map(category => {
      let categoryItem: CategoryItemModel = new CategoryItemModel();
      categoryItem.id = category.id;
      categoryItem.parentCategoryId = category.parentCategoryId;
      categoryItem.name = category.name;

      return categoryItem;
    });

    return itemModels;
  }
}

export class DocumentMetadataDepartmentModel {
  id!: number;
  displayName!: string;

  fromJson(json: any): DocumentMetadataDepartmentModel {
    if (!json) {
      return this;
    }

    this.id = +json.id;
    this.displayName = json.displayName;

    return this;
  }

  toJson(): any {
    return {
      id: this.id,
      displayName: this.displayName
    };
  }
}

export class DocumentMetadataRole {
  id: number = 0;
  name: string = '';

  fromJson(json: any): DocumentMetadataRole {
    this.id = json.id || 0;
    this.name = json.name || '';

    return this;
  }

  toJson(): any {
    return {
      id: this.id,
      name: this.name
    };
  }
}

export class DocumentMetadataAttributeModel {
  id!: number;
  value!: string;

  fromJson(json: any): DocumentMetadataAttributeModel {
    if (!json) {
      return this;
    }

    this.id = +json.id;
    this.value = json.value;

    return this;
  }

  toJson(): any {
    return {
      id: this.id,
      value: this.value
    };
  }
}

export class DocumentDepartmentModel {
  branchId: number = 0;
  departmentId: number = 0;
  department: DocumentDepartmentInfoModel | null = null;

  fromJson(json: any): DocumentDepartmentModel {
    if (!json) {
      return this;
    }

    this.branchId = +json.branchId;
    this.departmentId = +json.departmentId;
    this.department = json.department ? new DocumentDepartmentInfoModel().fromJson(json.department) : null;

    return this;
  }

  toJson(): any {
    return {
      branchId: this.branchId,
      departmentId: this.departmentId,
      department: this.department
    };
  }
}

export class DocumentDepartmentInfoModel {
  id: number = 0;
  displayName: string = '';

  fromJson(json: any): DocumentDepartmentInfoModel {
    if (!json) {
      return this;
    }

    this.id = +json.id;
    this.displayName = json.displayName;

    return this;
  }

  toJson(): any {
    return {
      id: this.id,
      displayName: this.displayName
    }
  }
}

export class DocumentBranchModel {
  id: number = 0;
  documentId: number = 0;
  task: DocumentBasicTaskModel | null = null;
  name: string = '';
  title: string = '';
  description: string = '';
  author: string = '';
  owner: number = 0;
  ownerFullName: string = '';
  ownerProfileImage: string = '';
  isAdminBranch: boolean = false;
  isDocumentMetaData: boolean = false;
  version: number = 0;
  minorVersion: number = 0;
  publishedToVersion?: number;
  lastContentRevision: DocumentRevision | null = null;
  lastRevision: DocumentRevision | null = null;
  lastModifiedTime: Date | null = null;
  categories: DocumentCategoryModel[] = [];
  departments: DocumentDepartmentModel[] = [];
  documentTypes: DocumentDocTypeModel[] = [];
  historyEvents: DocumentHistoryEventModel[] = [];
  contexts: DocumentContextModel[] = [];
  attributes: DocumentAttributeModel[] = [];
  localeId: number = 0;
  locale: LocaleModel | null = null;
  issues: ElementIssueModel[] = [];
  roles: DocumentRoleModel[] = [];
  isDocumentFollowMe: boolean = false;
  fromJson(json: any): DocumentBranchModel {
    if (!json) {
      return this;
    }

    this.id = +json.id || 0;
    this.documentId = +json.documentId || 0;
    this.task = json.task ? new DocumentBasicTaskModel().fromJson(json.task) : null;
    this.name = json.name;
    this.title = json.title;
    this.description = json.description;
    this.owner = json.owner;
    this.ownerFullName = json.ownerFullName;
    this.ownerProfileImage = json.ownerProfileImage;
    this.author = json.author;
    this.isAdminBranch = json.isAdminBranch;
    this.isDocumentMetaData = json.isDocumentMetaData;
    this.isDocumentFollowMe = json.isDocumentFollowMe;
    this.version = +json.version;
    this.minorVersion = +json.minorVersion;
    this.publishedToVersion = json.publishedToVersion !== null ? json.publishedToVersion : null;
    this.lastContentRevision = json.lastContentRevision ? new DocumentRevision().fromJson(json.lastContentRevision) : null;
    this.lastRevision = json.lastRevision ? new DocumentRevision().fromJson(json.lastRevision) : null;
    this.lastModifiedTime = json.lastModifiedTime ? new Date(json.lastModifiedTime) : null;
    this.categories = json.categories ? json.categories.map((c: any) => new DocumentCategoryModel().fromJson(c)) : [];
    this.departments = json.departments ? json.departments.map((d: any) => new DocumentDepartmentModel().fromJson(d)) : [];
    this.documentTypes = json.documentTypes ? json.documentTypes.map((d: any) => new DocumentDocTypeModel().fromJson(d)) : [];
    this.historyEvents = json.historyEvents ? json.historyEvents.map((h: any) => new DocumentHistoryEventModel().fromJson(h)) : [];
    this.contexts = json.contexts ? json.contexts.map((c: any) => new DocumentContextModel().fromJson(c)) : [];
    this.attributes = json.attributes ? json.attributes.map((c: any) => new DocumentAttributeModel().fromJson(c)) : [];
    this.localeId = json.localeId;
    this.locale = json.locale ? new LocaleModel().fromJson(json.locale) : null;
    this.issues = json.issues && json.issues.length ? json.issues.map((issue: any) => new ElementIssueModel().fromJson(issue)) : [];
    this.roles = json.roles ? json.roles.map((d: any) => new DocumentRoleModel().fromJson(d)) : [];
    return this;
  }

  toJson(): any {
    return {
      id: this.id,
      documentId: this.documentId,
      task: this.task,
      name: this.name,
      title: this.title,
      description: this.description,
      owner: this.owner,
      author: this.author,
      isAdminBranch: this.isAdminBranch,
      isDocumentMetaData: this.isDocumentMetaData,
      isDocumentFollowMe: this.isDocumentFollowMe,
      version: this.version,
      minorVersion: this.minorVersion,
      publishedToVersion: this.publishedToVersion,
      lastContentRevision: this.lastContentRevision ? this.lastContentRevision.toJson() : null,
      lastRevision: this.lastRevision ? this.lastRevision.toJson() : null,
      lastModifiedTime: this.lastModifiedTime,
      categories: this.categories ? this.categories.map((c: DocumentCategoryModel) => c.toJson()) : [],
      departments: this.departments ? this.departments.map((d: DocumentDepartmentModel) => d.toJson()) : [],
      documentTypes: this.documentTypes ? this.documentTypes.map((d: DocumentDocTypeModel) => d.toJson()) : [],
      historyEvents: this.historyEvents ? this.historyEvents.map((h: DocumentHistoryEventModel) => h.toJson()) : [],
      contexts: this.contexts ? this.contexts.map((c: DocumentContextModel) => c.toJson()) : [],
      localeId: this.localeId,
      locale: this.locale ? this.locale.toJson() : null,
      roles: this.roles ? this.roles.map((d: DocumentRoleModel) => d.toJson()) : []
    }
  }

  get currentVersion(): string {
    return this.version + '.' + this.minorVersion;
  }

  get hasContent(): boolean {
    return this.lastContentRevision?.type !== DocumentRevisionType.Metadata;
  }

  get isExternalFileContent(): boolean {
    return (this.lastContentRevision?.type === DocumentRevisionType.DocumentFile || this.lastContentRevision?.type === DocumentRevisionType.Webpage);
  }

  get isRecordingContent(): boolean {
    return this.lastContentRevision?.type === DocumentRevisionType.Recording;
  }
  get isFollowMeContent(): boolean {
    return this.lastContentRevision?.type === DocumentRevisionType.FollowMe;
  }
  get isEmptyRevision(): boolean {
    return !this.lastContentRevision
  }
  get isDocumentWebLink(): boolean {
    return this.lastContentRevision?.type === DocumentRevisionType.WebLink;
  }
  get isDocumentPPT(): boolean {
    return this.extentionExternalFileContent != null && this.extentionExternalFileContent === '.pptx';
  }
  get isDocumentXLSX(): boolean {
    return this.extentionExternalFileContent != null && this.extentionExternalFileContent === '.xlsx';
  }
  get isDocumentWord(): boolean {
    return this.extentionExternalFileContent != null && this.extentionExternalFileContent === '.docx';
  }
  get isDocumentPDF(): boolean {
    return this.extentionExternalFileContent != null && this.extentionExternalFileContent === '.pdf';
  }
  get isDocumentHTMP(): boolean {
    return this.extentionExternalFileContent != null && this.extentionExternalFileContent === '.htmp';
  }
  get isDocumentHTML(): boolean {
    return this.extentionExternalFileContent != null && this.extentionExternalFileContent === '.html';
  }
  get isDocumentWebpage(): boolean {
    return this.lastContentRevision?.type === DocumentRevisionType.Webpage;
  }
  get isDocumentMetadata(): boolean {
    return this.lastContentRevision?.type === DocumentRevisionType.Metadata;
  }
  get extentionExternalFileContent(): string {
    let ext = '';
    if (this.isExternalFileContent) {
      if (this.lastContentRevision?.name) {
        let arr = this.lastContentRevision.name.split(".");
        ext = "." + arr[arr.length - 1].toLowerCase();
      }
    }
    return ext;
  }
  get isAllRoles(): boolean {
    return (this.roles.some(r => r.roleId === ContentRole.EpilogueAdmin) && this.roles.some(r => r.roleId === ContentRole.EpilogueSupportUser))
      || (this.roles.some(r => r.roleId === ContentRole.CustomerAdmin)
        && this.roles.some(r => r.roleId === ContentRole.CustomerPowerUser)
        && this.roles.some(r => r.roleId === ContentRole.CustomerContentUser))
  }
}

export class DocumentRevision {
  branchId: number = -1;
  name: string = '';
  size: number = 0;
  type: DocumentRevisionType = DocumentRevisionType.Metadata;
  mimeType: string = '';
  commitId: string = '';
  version: number = 0;
  creatorUserId: number = -1;
  creationTime!: Date;

  fromJson(json: any): DocumentRevision {
    if (!json) {
      return this;
    }
    this.branchId = json.branchId;
    this.name = json.name;
    this.size = json.size;
    this.type = json.type;
    this.mimeType = json.mimeType;
    this.commitId = json.commitId;
    this.version = json.version ? json.version : 0;
    this.creatorUserId = json.creatorUserId;
    this.creationTime = new Date(json.creationTime);

    return this;
  }

  toJson(): any {
    return {
      branchId: this.branchId,
      name: this.name,
      size: this.size,
      type: this.type,
      mimeType: this.mimeType,
      commitId: this.commitId,
      version: this.version,
      creatorUserId: this.creatorUserId,
      creationTime: this.creationTime
    };
  }
}

export enum DocumentRevisionType {
  Recording = 1,
  DocumentFile = 2,
  Metadata = 3,
  Webpage = 4,
  WebLink = 5,
  FollowMe = 6
}
export enum DocumentRevisionTypeLabel {
  Recording = 'Recording',
  DocumentFile = 'DocumentFile',
  Metadata = 'Metadata',
  Webpage = 'Webpage',
  WebLink = 'WebLink',
  FollowMe = 'FollowMe'
}

export class DocumentBasicTaskModel {
  documentId: number = 0;
  projectId: number = -1;
  project: DocumentBasicProjectModel | null = null;
  assigneeId: number = 0;
  assignee: UserInfoModel | null = null;
  priority: TaskPriority = TaskPriority.Normal;
  state: TaskState = TaskState.Create;
  dueDate: Date | null = null;
  documentBranch: DocumentBranchModel | null = null;

  fromJson(json: any): DocumentBasicTaskModel {
    if (!json) {
      return this;
    }

    this.documentId = +json.documentId;
    this.projectId = +json.projectId;
    this.project = json.project ? new DocumentBasicProjectModel().fromJson(json.project) : null;
    this.assigneeId = +json.assigneeId;
    this.assignee = json.assignee ? new UserInfoModel().fromJson(json.assignee) : null;
    this.priority = json.priority ? +json.priority : TaskPriority.Normal;
    this.state = json.state ? +json.state : TaskState.Create;
    this.dueDate = json.dueDate ? new Date(json.dueDate) : null;
    this.documentBranch = json.documentBranch ? new DocumentBranchModel().fromJson(json.documentBranch) : null;

    return this;
  }

  toJson(): any {
    return {
      documentId: this.documentId,
      projectId: this.projectId,
      project: this.project ? this.project.toJson() : null,
      assigneeId: this.assigneeId,
      assignee: this.assignee ? this.assignee.toJson() : null,
      priority: this.priority,
      state: this.state,
      dueDate: this.dueDate,
      documentBranch: this.documentBranch ? this.documentBranch.toJson() : null
    }
  }
}

export class CloneTaskModel {
  taskId!: number;
  targetLocale!: number;
  clonedTitle: string = '';
}

export class DocumentBasicProjectModel {
  id: number = 0;
  name: string = '';
  status: ProjectStatus = ProjectStatus.Active;
  dueDate: Date | null = null;
  description: string = '';
  tenantId: number = 0;
  members: ProjectMemberModel[] = [];
  departments: ProjectDepartmentModel[] = [];
  categories: ProjectCategoriesModel[] = [];

  fromJson(json: any): DocumentBasicProjectModel {
    if (!json) {
      return this;
    }

    this.id = +json.id || 0;
    this.name = json.name || '';
    this.status = json.status ? +json.status : ProjectStatus.Active;
    this.dueDate = json.dueDate ? new Date(json.dueDate) : null;
    this.description = json.description || '';
    this.tenantId = json.tenantId || 0;
    this.members = json.members ? json.members.map((m: any) => new ProjectMemberModel().fromJson(m)) : [];
    this.departments = json.departments ? json.departments.map((d: any) => new ProjectDepartmentModel().fromJson(d)) : [];
    this.categories = json.categories ? json.categories.map((c: any) => new ProjectCategoriesModel().fromJson(c)) : [];

    return this;
  }

  toJson(): any {
    return {
      id: this.id,
      name: this.name,
      status: this.status,
      dueDate: this.dueDate,
      description: this.description,
      tenantId: this.tenantId,
      members: this.members ? this.members.map((m: ProjectMemberModel) => m.toJson()) : [],
      departments: this.departments ? this.departments.map((d: ProjectDepartmentModel) => d.toJson()) : [],
      categories: this.categories ? this.categories.map((c: ProjectCategoriesModel) => c.toJson()) : []
    }
  }
}

export class DocumentContextModel {
  id: number = 0;
  branchId: number = -1;
  caption: string = '';
  url: string = '';
  processName: string = '';
  isAddedManually!: boolean;
  additionalContext!: string;
  fromJson(json: any): DocumentContextModel {
    if (!json) {
      return this;
    }

    this.id = json.id;
    this.branchId = json.branchId;
    this.caption = json.caption;
    this.url = json.url;
    this.processName = json.processName;
    this.isAddedManually = json.isAddedManually;
    this.additionalContext = json.additionalContext;
    return this;
  }

  toJson(): any {
    return {
      id: this.id,
      branchId: this.branchId,
      caption: this.caption,
      url: this.url,
      processName: this.processName,
      isAddedManually: this.isAddedManually,
      additionalContext: this.additionalContext
    };
  }
}

export class MatchedDocumentContextModel {
  caption: string = '';
  url: string = '';
  processName: string = '';
  creationTime!: Date;
  threshold!: number;
  viewed!: boolean;
  additionalContext!: string;

  fromJson(json: any): MatchedDocumentContextModel {
    if (!json) {
      return this;
    }

    this.caption = json.caption || '';
    this.url = json.url || '';
    this.processName = json.processName || '';
    this.creationTime = json.creationTime ? new Date(json.creationTime) : new Date();

    if (json.threshold === '' || json.threshold === null || json.threshold === undefined) {
      this.threshold = 100;
    } else {
      this.threshold = +json.threshold;
    }

    this.viewed = json.viewed;
    this.additionalContext = json.additionalContext || '';

    return this;
  }

  get getTime(): string {
    if (this.creationTime) {
      return `${new DatePipe('en').transform(this.creationTime, 'mediumTime')}`;
    }
    return '';
  }
}

export enum DocumentManagerAction {
  None = 0,
  Information = 1,
  EditMetadata = 2,
  Delete = 3,
  Export = 4,
  RecordNewStep = 5,
  Editor = 6,
  Clone = 7,
  ChangeAdvisor = 8,
  PublishChange = 9,
  DiscardChange = 10,
  RestoreVersion = 11,
  DownloadOutput = 12,
  UnPublish = 13,
  AddWebLink = 14,
  ManageWebLink = 15,
  ConvertToLegacy = 16,
  BulkEditDocument = 17,
  PublishToAI = 18,
  UnpublishFromAI = 19
}

export enum DocumentMetadataTitle {
  IsPrefix = 1,
  IsPostfix = 2,
  IsReplace = 3
}

export enum DocumentMetadataOptions {
  DoNothing = 1,
  Append = 2,
  Replace = 3
}

export enum DocumentState {
  Imported = 1,
  Published = 2,
  Unpublished = 3,
  Branched = 4,
  Publishing = 5
}

export enum StepTitleType {
  None = 0,
  StepTitle = 1,
  WindowCaption = 2
}

export const DocumentStateText = [
  { id: DocumentState.Imported, text: 'Imported' },
  { id: DocumentState.Published, text: 'Published' },
  { id: DocumentState.Unpublished, text: 'Unpublished' }
];

export class SelectControlItemModel {
  displayText: string = '';
  index: number = 0;
  value: any = null;

}

export class DocumentMetadataContextModel {
  caption: string = '';
  url: string = '';
  processName: string = '';
  isAddedManually!: boolean;
  additionalContext: string = '';
  fromJson(json: any) {
    if (!json) {
      return this;
    }

    this.caption = json.caption || '';
    this.url = json.url || '';
    this.processName = json.processName || '';
    this.isAddedManually = json.isAddedManually;
    this.additionalContext = json.additionalContext || '';
    return this;
  }

  toJson(): any {
    return {
      caption: this.caption,
      url: this.url,
      processName: this.processName,
      isAddedManually: this.isAddedManually,
      additionalContext: this.additionalContext
    };
  }
}

export class DocumentRevisionRestoreModel {
  revision!: DocumentRevision;
  metadata!: DocumentMetadataModel;
  publishedToVersion: number = 0;
  documentId: number = 0;
  branchId: number = 0;

  fromJson(json: any) {
    if (!json) {
      return this;
    }

    this.revision = json.revision ? new DocumentRevision().fromJson(json.revision) : new DocumentRevision();
    this.metadata = json.metadata ? new DocumentMetadataModel().fromJson(json.metadata) : new DocumentMetadataModel();
    this.publishedToVersion = json.publishedToVersion || 0;
    this.documentId = json.documentId || 0;
    this.branchId = json.branchId || 0;

    return this;
  }
}

export enum DocumentColumn {
  Date = 3,
  Status = 4,
  Popularity = 7,
  Author = 8
}

export class DocumentErrorModel {
  document!: DocumentModel;
  errors: DocumentErrorMessage[] = [];
  errorStrCustom: string = '';

  fromJson(json: any) {
    if (!json) {
      return this;
    }

    this.document = new DocumentModel().fromJson(json.document);
    this.errors = json.errors;
    this.errorStrCustom = json.customErrors;

    return this;
  }
}

export enum DocumentErrorMessage {
  DocumentDoesNotHaveContent
}

export class Documentpdf {
  FileName!: string;
  DocVersion!: string;
  DocumentId!: number;
  DocumentBranchId!: number;
  DocTypeId!: number;
  TimeZoneOffset!: number
  LastModificationTime!: Date;
  Author!: string;
  Title!: string;
  LastContentRevisionName!: string;

  fromJson(json: any): Documentpdf {
    if (!json) {
      return this;
    }

    this.FileName = json.fileName;
    this.DocVersion = json.DocVersion;
    this.DocumentId = +json.DocumentId;
    this.DocumentBranchId = +json.DocumentBranchId;
    this.DocTypeId = +json.DocTypeId;
    this.TimeZoneOffset = +json.TimeZoneOffset;
    this.LastModificationTime = json.LastModificationTime;
    this.Author = json.Author
    this.Title = json.Title;
    this.LastContentRevisionName = json.LastContentRevision;
    return this;
  }
}

export class DocumentMinorVersionModel {
  id!: number;
  minorVersion!: number;

  fromJson(json: any): DocumentMinorVersionModel {
    if (!json) {
      return this;
    }

    this.id = json.id || 0;
    this.minorVersion = +json.minorVersion || 0;
    return this;
  }

}

export class DocumentIndexAIStatusModel {
  isPublishedToAI: boolean = false;
  publishedTime!: Date;

  fromJson(json: any): DocumentIndexAIStatusModel{
    if(!json){
      return this;
    }

    this.isPublishedToAI = json.isPublishedToAI;
    this.publishedTime = json.publishedTime;
    return this;
  }

  toJson(): any {
    return {
      isPublishedToAI: this.isPublishedToAI || false,
      publishedTime: this.publishedTime,
    };
  }
}




