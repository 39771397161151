export class ApiConstants {
  public static readonly GetDepartmentsOfTenant = 'departments';
  public static readonly AddDepartmentToTenant = 'departments';
  public static readonly UpdateUserConfigurableStatus = 'departments/user_configurable';

  public static readonly GetLearningActivities = 'learning_activities';
  public static readonly GetMyLearningActivities = 'learning_activities/own';
  public static readonly GetMoreLearningActivities = 'learning_activities/get_more';
  public static readonly ExportLearningActivities = 'learning_activities/export_json';
  public static readonly ExportMyLearningActivities = 'learning_activities/own/export_json';
  public static readonly GetAnonymouseLRSSetting = 'learning_activities/anonymous_setting';
  public static readonly ChangeAnonymouseLRSSetting = 'learning_activities/anonymous_setting';
  public static readonly ExportCsvLearningActivities = 'learning_activities/export_csv';
  public static readonly ExportCsvMyLearningActivities = 'learning_activities/own/export_csv';



  public static readonly projects = 'projects';
  public static readonly users = 'users';
  public static readonly categories = 'categories';
  public static readonly tenants = 'tenants';
  public static readonly host = 'host';
  public static readonly billing = 'billing';

  public static readonly book = 'books';
  public static readonly bookList = 'book-list';
  public static readonly bookType = 'book/type';
  public static readonly branch = 'branches';
  public static readonly bookElement = 'elements';
  public static readonly GetDocumentTypes = 'doc_types';
  public static readonly GetPredefinedThemes = 'themes';
  public static readonly GetThemeOfTenant = 'themes/theme';
  public static readonly GetAllThemesOfTenant = 'themes/allThemes';
  public static readonly GetCustomizedThemeofTenant = 'themes/CustomizedTheme'
  public static readonly SaveThemeOfTenant = 'themes/theme';
  public static readonly GetPredefinedFonts = 'fonts/predefined';
  public static readonly GetFontsOfTenant = 'fonts';
  public static readonly GetFont = 'fonts';
  public static readonly DeleteFontOfTenant = 'fonts';
  public static readonly UploadFontOfTenant = 'upload/font';
  public static readonly tasks = 'tasks';
  public static readonly GetAssignees = 'assignees';
  public static readonly BookProcess = 'processes';
  public static readonly BookSeriesVolumes = 'series_volumes'
  public static readonly Documents = 'documents';
  public static readonly SendAlertMessasge = 'notifications/alert';
  public static readonly Tenants = 'tenants';
  public static readonly LanguageImport = 'languages/import';
  public static readonly LanguageExport = 'languages/export';
  public static readonly Partners = 'partners';

  public static readonly ReInvite = 'reinvite';
  public static readonly UnLockUser = 'unlock';
  public static readonly Suggestion = 'suggestion';
  public static readonly HideSuggestion = 'hide_suggestion';
  public static readonly ResendInviteUsers = 'resend_invite_users';
  public static readonly ResendInviteHostUsers = 'resend_invite_host_users';
  public static readonly ManualSuspendUsers = 'manual_suspend_users';
  public static readonly ManualSuspendHostUsers = 'manual_suspend_host_users';
  public static readonly UpdateHostUsers = 'update_host_users';
  public static readonly GetEULAsPartner = 'get_eulas_partner';
  public static readonly AgreeEULAsPartner = 'send_eulas_agreement_partner';
  public static readonly SearchCustomerAdmins = 'search_customer_admins';
  public static readonly GetDefaultApplicationLanguage = 'get_default_application_language';
  public static readonly UserActionLocks = 'user_action_locks';
  public static readonly SCIMTokenUrl = 'tokenauth/scim-app-token';
  public static readonly TenantScimUrl = 'scim/v2';
  public static readonly IntercomHashCodeUrl = 'tokenauth/intercom/hash'
}
