import { FindItem } from '../post-login/shared/find-item/find-item';
import { Constants } from '../shared/helper/constants';
import { ContextIndex } from './v2/column-context';

export class CategoryContextModel extends FindItem<CategoryContextModel> {
  id: number = 0;
  caption: string = '';
  process: string = '';
  categoryId!: number;
  url: string = '';

  fromJson(json: any): CategoryContextModel {
    if (!json) {
      return this;
    }

    this.id = json.id;
    this.caption = json.caption;
    this.process = json.process;
    this.categoryId = json.categoryId;
    this.url = json.url;
    return this;
  }

  clone(): CategoryContextModel {
    let context: CategoryContextModel = new CategoryContextModel();

    context.id = this.id;
    context.caption = this.caption;
    context.url = this.url;
    context.process = this.process;
    context.categoryId = this.categoryId;
    return context;
  }

  toJson(): any {
    let json: any = {};
    json.id = this.id;
    json.caption = this.caption;
    json.process = this.process;
    json.categoryId = this.categoryId;
    json.url = this.url;
    return json;
  }

  hasSameContext(context: CategoryContextModel): boolean {
    if (!context) {
      return false;
    }
    let isEqualCaption = (!this.caption && !context.caption) ||
      (context.caption ?
      this.caption.toUpperCase().trim() === context.caption.toUpperCase().trim() :
      this.caption.toUpperCase().trim() === Constants.ASTERISK.toUpperCase());
    let isEqualUrl = !this.url ? !context.url : this.url.toUpperCase().trim() === context.url.toUpperCase().trim();
    let isEqualProcessName = (!this.process && !context.process) ||
      context.process ?
      this.process.toUpperCase().trim() === context.process.toUpperCase().trim() :
      this.process.toUpperCase().trim() === Constants.SPECIAL_BROWSER.toUpperCase();
    return isEqualCaption
      && isEqualUrl
      && isEqualProcessName;
  }

  getFieldName(fieldIndex: number): string {
    switch (fieldIndex) {
      case ContextIndex.caption:
        return 'caption';
      case ContextIndex.url:
        return 'url';
      case ContextIndex.processName:
        return 'process';
      case ContextIndex.additionalContext:
        return 'additionalContext';
      default:
        return '';
    }
  }

  isEmpty(): boolean {
    return !this.caption && !this.url && !this.process;
  }

  setValueByFieldIndex(index: ContextIndex, value: string) {
    switch (index) {
      case ContextIndex.caption:
        this.caption = value;
        break;
      case ContextIndex.url:
        this.url = value;
        break;
      case ContextIndex.processName:
        this.process = value;
        break;
      default:
        break;
    }
  }

  fieldsIndex(): string[] {
    return [this.caption, this.url, this.process];
  }

  fieldsIndexDisplay(): string[] {
    return [this.caption, this.url, this.process];
  }

  setDefaultProcessName() {
    if (!this.process) {
      this.process = Constants.SPECIAL_BROWSER;
    }
  }

  setAsteriskForBlankCaption() {
    if (!this.caption && this.process) {
      this.caption = Constants.ASTERISK;
    }
  }
}
