import { TaskDocumentRevision, TaskCategory, TaskDepartment } from './task.model';
import { CategoryTreeViewModel } from './category.model';
import { DocumentRevisionType, DocumentState } from "./document.model";
import { DocumentMetadataAttributeModel, TokenPlatform } from './index';
import moment from 'moment';
import { IPagination } from '../shared/interfaces';

export class ContentModel {
  id: number = 0;
  title: string = '';
  description: string = '';
  lastModificationTime!: Date;
  dueDate!: Date;
  departments: TaskDepartment[] = [];
  categories: TaskCategory[] = [];
  lastContentRevision!: TaskDocumentRevision;

  fromJson(json: any): ContentModel {
    this.id = json.id;
    this.title = json.title;
    this.lastModificationTime = json.lastModificationTime;
    this.dueDate = json.dueDate;
    this.description = json.description;
    this.departments = json.departments;
    this.categories = json.categories;
    this.lastContentRevision = json.lastContentRevision;
    return this;
  }

  get updatedDate(): string {
    return moment(this.lastModificationTime).format('MM/DD/YYYY');
  }

  get departmentDisplayNames(): string {
    return this.departments.map(d => d.department.displayName).join(', ');
  }

  get departmentNames(): string {
    return this.departments.map(d => d.department.displayName).join(' | ');
  }

  get hierarchyCategories(): CategoryTreeViewModel[] {
    //return CategoryItemModel.mapToTree(this.categories.map(c => c.category));
    let categoryMap: { [id: number]: CategoryTreeViewModel } = {};
    let categories = this.categories;
    let createTreeNode = (item: TaskCategory) => {
      let node = new CategoryTreeViewModel();
      node.id = item.categoryId;
      node.name = item.category.name;
      return node;
    };
    let tempNodes: CategoryTreeViewModel[] = [];
    while (categories.length) {
      let remaining: TaskCategory[] = [];

      categories.forEach(item => {
        if (!item.category.parentCategoryId) {
          let node = createTreeNode(item);
          tempNodes.push(node);
          categoryMap[node.id] = node;
        } else if (categoryMap[item.category.parentCategoryId]) {
          let node = createTreeNode(item);
          categoryMap[node.id] = node;
          categoryMap[item.category.parentCategoryId].children.push(node);
        } else {
          remaining.push(item);
        }
      });
      categories = remaining;
    }
    return tempNodes.slice();
  }
}

export class ContentFilterOptions implements IPagination {
  keyWord: string = '';
  departmentIds: number[] = [];
  categoryIds: number[] = [];
  documentTypeIds: number[] = [];
  customAttributes: DocumentMetadataAttributeModel[] = [];
  //paging
  author: string = "";
  currentPage: number = 1;
  itemsPerPage: number = 20;
  totalPages: number = 0;
  totalItemCount: number = 0;
  state!: DocumentState | null;
  documentRevisionType: DocumentRevisionType | null = null;
  contentLocation: ContentLocation = ContentLocation.AllContent;
  isPublishedPublicLibrary!: boolean | null;
  documentIds: number[] = [];
  owners: any;

  //sort
  currentSortColumn: number = 0;
  currentSortDirectionAsc: boolean = false;

  pageContext: any;
  tenantIds: number[] = [];
  userId!: number;
  isAllDocuments: boolean = false;
  isAdmin: boolean = false;
  allowFollowMeAdivsor: boolean = false;
  allowPublicLibraryDocs: boolean = false;

  clear() {
    this.keyWord = '';
    this.departmentIds = [];
    this.categoryIds = [];
    this.currentPage = 1;
    this.totalPages = 1;
    this.totalItemCount = 0;
    this.documentTypeIds = [];
    this.state = DocumentState.Unpublished;
    this.documentRevisionType = null;
    this.contentLocation = ContentLocation.AllContent;
    this.isPublishedPublicLibrary = null;
    this.owners = [];
    this.currentSortColumn = 5;
  }
}

export enum ContentLocation {
  AllContent,
  ContentLibrary,
  PublicLibrary
}

export enum ContentColumn {
  NONE = 0,
  TITLE,
  DEPARTMENT,
  APPLICATION,
  VIEWS,
  UPDATED,
  OWNER,
  RATING,
  COMMENTS,
  FUNCTIONALAREAS
}

export class PublicContentFilterOptions {
  keyWord: string = '';
  categoryId: number = 0;
  documentTypeId: number = 0;
  author: string = '';

  clear() {
    this.keyWord = '';
    this.categoryId = 0;
    this.documentTypeId = 0;
    this.author = '';
  }
}


export class DocumentFilterGeneralOptions {
  constructor(public keyWord: string = '',
    public departmentId: number = 0,
    public categoryId: number = 0,
    public author: string = '',
    public documentTypeId: number = 0,
    public documentState: number = 0,
    public documentRevisionType: number = 0,
    public includePublishedLibraries: boolean = true,
    public pageNumber: number = 1,
    public pageSize: number = 20) {
  }
}

export enum ContentViewAction {
  None = 0,
  RatingAndComment = 1,
  RelatedContent = 2,
  AddIssue = 3,
  Issues = 4,
  TableOfContents = 5,
  Information = 6
}
