export class TranslateDocumentModel {
  targetLocale: number = 0;
  currentLocale: number = 0;

  constructor(targetLocale: number, currentLocale: number) {
    this.targetLocale = targetLocale;
    this.currentLocale = currentLocale;
  }
}

export class TranslateListDocumentModel {
  targetLocale: number = 0;
  currentLocale: number = 0;
  documentBranchIds: number[] = [];

  constructor(targetLocale: number, currentLocale: number, documentBranchIds: number []) {
    this.targetLocale = targetLocale;
    this.currentLocale = currentLocale;
    this.documentBranchIds = documentBranchIds;
  }
}
