import { Utility } from '../shared/helper/utilities';

export class UploadInfo {
  File: any = null;
  InfoPath: string = '';
  FileIntent: string = '';
  Identity: string = '';
  RequestId: string = '';
  IsNewBranch: boolean = false;
}

export enum FileUploadStatus {
  None = 0,
  Uploading = 1,
  Pending = 2,
  Converting = 3,
  Imported = 4,
  Error = 5,
  Cancelled = 6,
  Clean = 7,
  Infected = 8,
  Verifying = 9,
  Uploaded = 10
}

export enum FileUploadErrorCode
{
  DocumentExisted = 1,
  FileNotFound = 2,
  FileNameNotFound = 3,
  UnknownError = 0,
  GitlabCreateBranchError = 4,
  GitlabMergeRequestError = 5,
  UploadError = 6,
  FileNotEncrypted = 7,
  FileSizeError = 8,
  CancelConverting = 9,
  FileInfected = 10,
  FileBlocked = 11,
  Unauthorized = 12
}

export class FileUploadProgress {
  public name: string = '';
  public size: number = 0;
  public fileObject: File | undefined;
  public progress: number = 0;
  public sizeUploaded: number = 0;
  public status: FileUploadStatus = FileUploadStatus.None;
  public xhrRequest: XMLHttpRequest | undefined;
  public requestId: string = Utility.Guid();
  public errorCode: number = 0;
  public overrideMetadata!: OverrideMetadata;
  public expanded: boolean = false;
  public parentRequestId: string = '';
  public extension: string = '';
  public cacheUserKey: string = '';
  public isCustomElementAppend: boolean = false;
  public files: FileUploadProgress[] = [];
  public static statusOrder(status: FileUploadStatus): number {
    switch (status) {
      case FileUploadStatus.Uploading:
        return 1;
      case FileUploadStatus.Converting:
        return 2;
      case FileUploadStatus.Error:
        return 3;
      case FileUploadStatus.Imported:
        return 4;
      case FileUploadStatus.Pending:
        return 5;
      case FileUploadStatus.Cancelled:
        return 6;
    }
    return 0;
  }

  public checkStatusChildren(): boolean {
    return this.files && this.files.filter(f => f.status !== FileUploadStatus.Imported && f.status !== FileUploadStatus.Error && f.status !== FileUploadStatus.Cancelled).length === 0;
  }
}

export class OverrideMetadata {
  Author!: string;
  Applications!: any[];
  Departments!: any[];
  Types!: any[];
  LocaleId!: string;
  roles: Array<DocumentMetadataRoleModel> = [];
  attributes: Array<ImportAttributeModel> = [];
  bookAttributes: Array<ImportAttributeModel> = [];
}

export class DocumentMetadataRoleModel {
  id!: number;
  roleId!: number;

  fromJson(json: any): DocumentMetadataRoleModel {
    this.id = json.id;
    this.roleId = json.roleId;
    return this;
  }

  toJson(): any {
    return {
      id: this.id,
      roleId: this.roleId
    };
  }

}

export class ImportAttributeModel {
  public customAttributeId: number = 0;
  public value: string = '';
  public isRequired: boolean = false;

  constructor () {
    this.customAttributeId = 0;
    this.value = '';
    this.isRequired = false;
  }

  fromJson(json: any): ImportAttributeModel {
    this.customAttributeId = json.customAttributeId;
    this.value = json.value;
    return this;
  }

  toJson(): any {
    return {
      customAttributeId: this.customAttributeId,
      value: this.value
    };
  }
}

export class ImportingFileStatus {
  public status: FileUploadStatus = FileUploadStatus.None;
  public requestId: string = '';
  public name: string = '';
  public size: string = '';
  public errorCode: number = 0;
  public haveChildren: boolean = false;
  public parentRequestId: string = '';
  public children: ImportingFileStatus[] = [];
  public isHidden: boolean = false;
  public cacheUserKey: string = '';
  public isCustomElementAppend: boolean = false;

  fromJson(json: any) {
    if (!json) {
      return this;
    }

    this.status = json.status || 0;
    this.requestId = json.requestId || '';
    this.name = json.fileName || '';
    this.size = json.fileSize || '';
    this.errorCode = json.errorCode || 0;
    this.haveChildren = json.haveChildren || false;
    this.parentRequestId = json.parentRequestId || '';
    this.children = json.children ? json.children.map(((f: any) => new ImportingFileStatus().fromJson(f))) : [];
    this.isHidden = json.isHidden || false;
    this.cacheUserKey = json.cacheUserKey || '';
    this.isCustomElementAppend = json.isCustomElementAppend;
    return this;
  }

  toJson(): any {
    return {
      status : this.status,
      requestId : this.requestId,
      fileName : this.name,
      fileSize : this.size,
      errorCode: this.errorCode
    };
  }
}
