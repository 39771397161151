import { FindItemPosition } from '../../../models/v2/find-item-position.model';
import { ContextIndex } from '../../../models/v2/column-context';

export abstract class FindItem<T> {
  abstract isEmpty(): boolean;

  abstract clone(): FindItem<T>;

  abstract setValueByFieldIndex(index: ContextIndex, value: string): any;

  abstract fieldsIndex(): string[];

  abstract fieldsIndexDisplay(): string[];

  abstract hasSameContext(item: any): boolean;

  abstract getFieldName(fieldIndex: number): string;

  abstract setDefaultProcessName(): any;

  abstract setAsteriskForBlankCaption(): any;

  findNext(currentPosition: FindItemPosition, key: string): FindItemPosition | null {
    if (!key) {
      return null;
    }
    key = key.toUpperCase();
    let startField = currentPosition ? currentPosition.fieldIndex : 0;
    let startPos = currentPosition ? currentPosition.to : 0;

    for (let fieldIndex = startField; fieldIndex < this.fieldsIndex().length; fieldIndex++) {
      let fieldValue = this.fieldsIndex()[fieldIndex];
      if (!fieldValue || startPos === fieldValue.length) {
        startPos = 0;
        continue;
      }
      let foundIndex = fieldValue.toUpperCase().indexOf(key, startPos);
      if (foundIndex > -1) {
        let foundPosition = new FindItemPosition();
        foundPosition.fieldIndex = fieldIndex;
        foundPosition.from = foundIndex;
        foundPosition.to = foundIndex + key.length;
        return foundPosition;
      }
      startPos = 0;
    }
    return null;
  }

  findPrevious(currentPosition: FindItemPosition, key: string): FindItemPosition | null {
    if (!key) {
      return null;
    }
    key = key.toUpperCase();
    let startField = currentPosition ? currentPosition.fieldIndex : this.fieldsIndex().length - 1;
    let startPos = currentPosition ? --currentPosition.from : this.fieldsIndex()[startField].length;
    for (let fieldIndex = startField; fieldIndex >= 0; fieldIndex--) {
      let fieldValue = this.fieldsIndex()[fieldIndex];
      if (!fieldValue || startPos < 0) {
        if (fieldIndex > 0) {
          startPos = this.fieldsIndex()[fieldIndex - 1].length;
        }
        continue;
      }

      let foundIndex = fieldValue.toUpperCase().lastIndexOf(key, startPos);
      if (foundIndex > -1) {
        let foundPosition = new FindItemPosition();
        foundPosition.fieldIndex = fieldIndex;
        foundPosition.from = foundIndex;
        foundPosition.to = foundIndex + key.length;
        return foundPosition;
      }
      if (fieldIndex > 0) {
        startPos = this.fieldsIndex()[fieldIndex - 1].length;
      }
    }
    return null;
  }

  getFieldValue(fieldIndex: number): string {
    if (fieldIndex > this.fieldsIndex().length) {
      return '';
    }
    return this.fieldsIndex()[fieldIndex];
  }

  getFieldDisplayValue(fieldIndex: number): string {
    if (fieldIndex > this.fieldsIndexDisplay().length) {
      return '';
    }
    return this.fieldsIndexDisplay()[fieldIndex];
  }

  setFieldValue(position: FindItemPosition, replacementValue: string) {
    if (position.fieldIndex > this.fieldsIndex().length) {
      return;
    }
    let fieldValue = this.fieldsIndex()[position.fieldIndex];
    if (!fieldValue.length) {
      return;
    }
    let value = fieldValue.substring(0, position.from) + replacementValue + fieldValue.substring(position.to);
    this.setValueByFieldIndex(position.fieldIndex, value);
  }
}
