import { UserProfile } from './user-profile.model';

export class ElementIssueModel {
  id!: number;
  description: string = '';
  includeContext: boolean = false;
  context: ElementIssueContextModel | null = new ElementIssueContextModel();
  status: ElementIssueStatus = ElementIssueStatus.Open;
  creationTime!: Date;
  creatorUser: UserProfile | null = null;
  creatorUserId: number = 0;
  isActive: boolean = true;
  stepDetails: any;
  isElementActive: boolean = true;

  fromJson(json: any) {
    if (!json) {
      return this;
    }

    this.id = json.id;
    this.description = json.description;
    this.includeContext = json.includeContext;
    this.context = json.context ? new ElementIssueContextModel().fromJson(json.context) : null;
    this.status = json.status;
    this.creationTime = new Date(json.creationTime);
    this.creatorUser = json.creatorUser ? new UserProfile().fromJson(json.creatorUser) : null;
    this.creatorUserId = json.creatorUserId;
    this.isActive = json.isActive;
    this.stepDetails = json.stepDetails;
    this.isElementActive = json.isElementActive;
    return this;
  }
}

export class ElementIssueContextModel {
  doctypeId: number = 0;
  elementId: string = '';

  fromJson(json: any) {
    if (!json) {
      return this;
    }

    this.doctypeId = json.doctypeId;
    this.elementId = json.elementId;

    return this;
  }
}

export enum ElementIssueStatus {
  Open = 1,
  ReadyToReview = 2,
  Close = 3
}
