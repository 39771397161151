export const environment:any = {
    ENV: 'PROD',
    OPUS_DOMAIN: 'qa-opuseps.com',
    IDENTITY_SERVER_DOMAIN: 'qa-opuseps.com',
    awsPartnerDomain: '.opusepspartner.com.',
    AWS_S3_BUCKET_LINK: 'https://s3.amazonaws.com',
    AWS_S3_ENV: 'qa',
    AWS_S3_SYSTEM: 'opus',
    RECORDER_EXTENSION_URL: 'https://chrome.google.com/webstore/detail/opus-web-recorder-qa/nncijejpifkkmpngofhoeofndcmphnop',
    ADVISOR_LISTENER_EXTENSION_URL: 'https://chrome.google.com/webstore/detail/opus-web-listener-qa/dpbfogjpladaijdfablbaimhkjhhmomg',
    isDev: true,
    WEB_RECORDER_EXTENSION_ID: 'nncijejpifkkmpngofhoeofndcmphnop',
    WEB_SOCKET_URL: 'ws://localhost:12346',
    ROOT: 'WEB_APP',
    IS_PARTNER: false,
    S3_ROOT_FOLDER: 'qa-opus',
    SISENSE_DOMAIN: 'https://analytics.dev-opuseps.com',
    EMBEDDED_ADVISOR_DOMAIN: 'https://dq5vsk83deboo.cloudfront.net',
    EMBEDDED_WIDGET_DOMAIN: 'https://d2izlecb97n35r.cloudfront.net',
    FROALA_SECRET: 'bMA6aC5A4C2E3C2F2G1yQNDMIJg1IQNSEa1EUAi1XVFQd1EaG3C2A5A3C4E3E2D4G2G2==',
    NEW_RELIC_SCRIPT: 'QA',
    FM_RECORDER_EXTENSION_URL: 'https://chrome.google.com/webstore/detail/stage-opus-followme-recor/dbopdcfpbddaejjhbalnhmfefapgdpod',
    AI_BOT_API_URL: 'https://258a56q9dk.execute-api.us-east-1.amazonaws.com/botV1',
    ANALYTICS_URL: "https://opusanalytics-uat.azurewebsites.net/"
  };
  
  environment.RECORDER_APP_URL = `${environment.AWS_S3_BUCKET_LINK}/${environment.S3_ROOT_FOLDER}-apps/${environment.AWS_S3_ENV}/${environment.AWS_S3_SYSTEM}/recorder/OpusRecorder.exe`;
  environment.ADVISOR_LISTENER_APP_URL = `${environment.AWS_S3_BUCKET_LINK}/${environment.S3_ROOT_FOLDER}-apps/${environment.AWS_S3_ENV}/${environment.AWS_S3_SYSTEM}/advisor/OpusAdvisorAgent.exe`;
  environment.AWS_S3_BUCKET_NAME_RECORDING = `${environment.S3_ROOT_FOLDER}-recordings/${environment.AWS_S3_ENV}/${environment.AWS_S3_SYSTEM}/recordings`;
  environment.AWS_S3_BUCKET_NAME_PUBLISHED_DOCUMENT = `${environment.S3_ROOT_FOLDER}-recordings/${environment.AWS_S3_ENV}/${environment.AWS_S3_SYSTEM}/published-documents`;
  environment.AWS_S3_BUCKET_NAME_IMAGES = `${environment.S3_ROOT_FOLDER}-uploads/${environment.AWS_S3_ENV}/${environment.AWS_S3_SYSTEM}/avatar`;
  environment.AWS_S3_BUCKET_NAME_SCREENSHOT_SHARED = `${environment.S3_ROOT_FOLDER}-uploads/shared/screenshot`;
  environment.AWS_S3_BUCKET_NAME_LOGO = `${environment.S3_ROOT_FOLDER}-uploads/${environment.AWS_S3_ENV}/${environment.AWS_S3_SYSTEM}/logo`;
  environment.AWS_S3_BUCKET_WEBSITE_STATIC_LINK = `${environment.S3_ROOT_FOLDER}-uploads/${environment.AWS_S3_ENV}/${environment.AWS_S3_SYSTEM}/html`;
  environment.AWS_S3_BUCKET_NAME_PDF = `${environment.S3_ROOT_FOLDER}-system/${environment.AWS_S3_ENV}/${environment.AWS_S3_SYSTEM}/pdf`;
  environment.awsDomain = `.${environment.OPUS_DOMAIN}.`;
  environment.OPUS_URL = `https://${environment.OPUS_DOMAIN}`;
  environment.API = `https://api.internal.${environment.OPUS_DOMAIN}/api`;
  environment.API_V2 = `https://api.internal.${environment.OPUS_DOMAIN}/v2`;
  environment.OPUS_API = environment.API;
  environment.OPUS_API_V2 = environment.API_V2;
  environment.SOCKETIO_URL = `https://socketio.internal.${environment.OPUS_DOMAIN}`;
  environment.TENANT_SCIM_URL = 'https://api-scim.test-opuseps.com/';
  