import { Point } from './point.model';
import { Rect } from './rect.model';

export enum ActionKind {
  StatusText = -2,
  LogText = -1,
  LogControl = 0,
  Deselect = 5,
  Drag = 8,
  Drop = 9,
  Unknown = 10,
  Insert = 12,
  Cut = 14,
  Copy = 15,
  Paste = 16,
  Select = 20,
  Click = 30,
  Press = 40,
  Change = 50,
  Type = 70,
  DoubleClick = 90,
  RightClick = 100,
  Tab = 140,
  Up = 160,
  Down = 170,
  Left = 180,
  Right = 190,
  Home = 200,
  End = 210,
  PageUp = 220,
  PageDown = 230,
  Delete = 250,
  Escape = 260,
  Size = 290,
  Verify = 320,
  Scroll = 400,
  Screenshot = 512,
  Expend = 530,
  Collapse = 540,
  ChangeFocus = 600,
  LeaveScreen = 610,
  UpdateScreen = 620,
}

export enum ControlTypeKind {
  Unknown = 0,
  Key = 1,
  Window = 10,
  ClientArea = 11,
  Dialog = 12,
  ChildWindow = 13,
  Tab = 140,
  Button = 340,
  TextBox = 430,
  RadioButton = 440,
  CheckBox = 450,
  TabButton = 460,
  TableRow = 479,
  TableColumn = 480,
  CloseBox = 550,
  TextEdit = 560,
  TitleBar = 565,
  ListBox = 570,
  PasswordField = 575,
  ComboBox = 580,
  Table = 590,
  VerticalScrollBar = 591,
  HorizontalScrollBar = 592,
  Application = 600,
  MenuItem = 610,
  TreeView = 620,
  TreeViewNode = 630,
  Item = 640,
  MatchCode = 650,
  Hyperlink = 660,
  PopupMenu = 670,
  Cell = 680,
  Icon = 690,
  Desktop = 700,
  FreeText = 710,
  MapPanel = 720,
  Heading = 730
}

export class RecorderEvent {
  action: ActionKind = ActionKind.LogControl;
  caption: string = '';
  duration: number = 0;
  eventType: string = 'RecorderEvent';
  imageContext: string = '';
  screenRect: Rect = new Rect();
  screenshot: string = '';
  sequence: number = 0;
  source: string = '';
  timestamp!: Date | null;
  windowChange: boolean = false;

  static deserializePayload(json: any): RecorderEvent {
    if (json.MessageType === 'ControlEvent') {
      return new ControlEvent().fromJson(json.Payload);
    } else if (json.MessageType === 'KeyEvent') {
      return new KeyEvent().fromJson(json.Payload);
    } else if (json.MessageType === 'MenuEvent') {
      return new MenuEvent().fromJson(json.Payload);
    } else if (json.MessageType === 'ImageEvent') {
      return new ImageEvent().fromJson(json.Payload);
    } else if (json.MessageType === 'DebugEvent') {
      return new DebugEvent().fromJson(json.Payload);
    } else if (json.MessageType === 'ProxyEvent') {
      return new RecorderEvent().fromJson(json.Payload);
    } else if(json.MessageType === 'ScreenConfigureEvent') {
      return new ScreenConfigureEvent().fromJson(json.Payload);
    }

    return new RecorderEvent();
  }

  static createInstance(obj: any): RecorderEvent {
    if (obj.eventType === 'ControlEvent') {
      return new ControlEvent().createFromObject(obj);
    } else if (obj.eventType === 'KeyEvent') {
      return new KeyEvent().createFromObject(obj);
    } else if (obj.eventType === 'MenuEvent') {
      return new MenuEvent().createFromObject(obj);
    } else if (obj.eventType === 'ImageEvent') {
      return new ImageEvent().createFromObject(obj);
    } else if (obj.eventType === 'DebugEvent') {
      return new DebugEvent().createFromObject(obj);
    } else if (obj.eventType === 'ProxyEvent') {
      return new RecorderEvent().createFromObject(obj);
    } else if(obj.eventType === 'ScreenConfigureEvent') {
      return new ScreenConfigureEvent().createFromObject(obj);
    }

    return new RecorderEvent();
  }

  fromJson(json: any): RecorderEvent {
    if (!json) {
      return this;
    }

    this.action = json.Action || 0;
    this.caption = json.Caption || '';
    this.duration = json.Duration || 0;
    this.imageContext = json.ImageContext || '';
    this.screenRect = json.ScreenRect ? new Rect().fromString(json.ScreenRect) : new Rect();
    this.screenshot = json.Screenshot || '';
    this.sequence = json.Sequence || 0;
    this.source = json.Source || '';
    this.timestamp = json.Timestamp ? new Date(json.Timestamp) : null;
    this.windowChange = json.WindowChange || false;

    return this;
  }

  toJson(): any {
    return {
      Action: this.action,
      Caption: this.caption,
      Duration: this.duration,
      EventType: this.eventType,
      ImageContext: this.imageContext,
      ScreenRect: this.screenRect.toString(),
      Screenshot: this.screenshot,
      Sequence: this.sequence,
      Source: this.source,
      Timestamp: this.timestamp ? this.timestamp.toISOString() : new Date().toISOString(),
      WindowChange: this.windowChange
    };
  }

  createFromObject(obj: any): RecorderEvent {
    if (!obj) {
      return this;
    }

    this.action = obj.action || 0;
    this.caption = obj.caption || '';
    this.duration = obj.duration || 0;
    this.imageContext = obj.imageContext || '';
    this.screenRect = obj.screenRect ? new Rect().fromString(obj.ScreenRect) : new Rect();
    this.screenshot = obj.screenshot || '';
    this.sequence = obj.sequence || 0;
    this.source = obj.source || '';
    this.timestamp = obj.timestamp ? new Date(obj.timestamp) : null;
    this.windowChange = obj.windowChange || false;

    return this;
  }
}

export class DebugEvent extends RecorderEvent {
  xml: string = '';
  override eventType: string = 'DebugEvent';

  override fromJson(json: any): DebugEvent {
    if (!json) {
      return this;
    }

    super.fromJson(json);
    this.xml = json.Xml || '';
    return this;
  }

  override toJson(): any {
    let json = super.toJson();
    json.Xml = this.xml;
    return json;
  }

  override createFromObject(obj: any): DebugEvent {
    if (!obj) {
      return this;
    }

    super.createFromObject(obj);
    this.xml = obj.xml || '';
    return this;
  }
}

export class ImageEvent extends RecorderEvent {
  override eventType: string = 'ImageEvent';

  override fromJson(json: any): ImageEvent {
    super.fromJson(json);
    return this;
  }

  override createFromObject(obj: any): ImageEvent {
    super.createFromObject(obj);
    return this;
  }
}

export class ControlEvent extends RecorderEvent {
  columnHeader: string = '';
  controlImage: string = '';
  controlRect: Rect = new Rect();
  controlType: ControlTypeKind = ControlTypeKind.Unknown;
  override eventType: string = 'ControlEvent';
  imageRect: Rect = new Rect();
  labelRect: Rect = new Rect();
  labelText: string = '';
  pointer: Point = new Point();
  processName: string = '';
  valueRect: Rect = new Rect();
  valueText: string = '';

  override fromJson(json: any): ControlEvent {
    if (!json) {
      return this;
    }

    super.fromJson(json);
    this.columnHeader = json.ColumnHeader || '';
    this.controlImage = json.ControlImage || '';
    this.controlRect = json.ControlRect ? new Rect().fromString(json.ControlRect) : new Rect();
    this.controlType = json.ControlType || 0;
    this.imageRect = json.ImageRect ? new Rect().fromString(json.ImageRect) : new Rect();
    this.labelRect = json.LabelRect ? new Rect().fromString(json.LabelRect) : new Rect();
    this.labelText = json.LabelText || '';
    this.pointer = json.Pointer ? new Point().fromString(json.Pointer) : new Point();
    this.processName = json.ProcessName || '';
    this.valueRect = json.ValueRect ? new Rect().fromString(json.ValueRect) : new Rect();
    this.valueText = json.ValueText || '';
    return this;
  }

  override toJson(): any {
    let json = super.toJson();
    json.ColumnHeader = this.columnHeader;
    json.ControlImage = this.controlImage;
    json.ControlRect = this.controlRect.toString();
    json.ControlType = this.controlType;
    json.ImageRect = this.imageRect.toString();
    json.LabelRect = this.labelRect.toString();
    json.LabelText = this.labelText;
    json.Pointer = this.pointer.toString();
    json.ProcessName = this.processName;
    json.ValueRect = this.valueRect.toString();
    json.ValueText = this.valueText;
    return json;
  }

  override createFromObject(obj: any): ControlEvent {
    if (!obj) {
      return this;
    }

    super.createFromObject(obj);
    this.columnHeader = obj.columnHeader || '';
    this.controlImage = obj.controlImage || '';
    this.controlRect = obj.controlRect ? new Rect().fromString(obj.controlRect) : new Rect();
    this.controlType = obj.controlType || 0;
    this.imageRect = obj.imageRect ? new Rect().fromString(obj.imageRect) : new Rect();
    this.labelRect = obj.labelRect ? new Rect().fromString(obj.labelRect) : new Rect();
    this.labelText = obj.labelText || '';
    this.pointer = obj.pointer ? new Point().fromString(obj.pointer) : new Point();
    this.processName = obj.processName || '';
    this.valueRect = obj.valueRect ? new Rect().fromString(obj.valueRect) : new Rect();
    this.valueText = obj.valueText || '';
    return this;
  }
}

export class MenuEvent extends RecorderEvent {
  override eventType: string = 'MenuEvent';
  valueText!: string;

  override fromJson(json: any): MenuEvent {
    if (!json) {
      return this;
    }

    super.fromJson(json);
    this.valueText = json.ValueText || '';
    return this;
  }

  override toJson(): any {
    let json = super.toJson();
    json.ValueText = this.valueText;
    return json;
  }

  override createFromObject(obj: any): MenuEvent {
    if (!obj) {
      return this;
    }

    super.createFromObject(obj);
    this.valueText = obj.valueText || '';
    return this;
  }
}

export class KeyEvent extends RecorderEvent {
  override eventType: string = 'KeyEvent';
  modifiers!: number;
  key!: number;

  override fromJson(json: any): KeyEvent {
    if (!json) {
      return this;
    }

    super.fromJson(json);
    this.modifiers = json.Modifiers || 0;
    this.key = json.Key || 0;
    return this;
  }

  override toJson(): any {
    let json = super.toJson();
    json.Modifiers = this.modifiers;
    json.Key = this.key;
    return json;
  }

  override createFromObject(obj: any): KeyEvent {
    if (!obj) {
      return this;
    }

    super.createFromObject(obj);
    this.modifiers = obj.modifiers || 0;
    this.key = obj.key || 0;
    return this;
  }
}

export class ScreenConfigureEvent extends RecorderEvent {
  Command: string = '';

  override fromJson(json: any): ScreenConfigureEvent{
    if(!json){
      return this;
    }

    super.fromJson(json);
    this.Command = json.Command;
    return this;
  }

  override toJson(): any {
    let json = super.toJson();
    json.Command = this.Command;
    return json;
  }

  override createFromObject(obj: any): ScreenConfigureEvent {
    if (!obj) {
      return this;
    }

    super.createFromObject(obj);
    this.Command = obj.Command || 0;
    return this;
  }
}
