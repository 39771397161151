export enum StepAction {
  Add_Department = '1',
  Add_Category = '2',
  Download_Chrome_Extension = '3',
  Add_Custom_Metadata = '4',
  DONE_ALL_STEP = '0',
}

export enum StepRouteLink {
  Add_Department = '/app/getting-started/user/user-getting-started-add-department',
  Add_Category = '/app/getting-started/user/user-getting-started-add-category',
  Download_Chrome_Extension = '/app/getting-started/user/user-getting-started-download-extension',
  Add_Custom_Metadata = '/app/getting-started/user/user-getting-started-add-custom-metadata'
}

