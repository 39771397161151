import { ApplicationModel } from './application.model';
import { CategoryItemModel } from './category-item.model';
import { UserCategoryItemModel } from './user-category-item.model';
import { CurrencyModel } from './country.model';
import { AddressModel } from './address.model';

export class RegisterModel {
  AccountInformation!: RegisterAccountInfoModel;
  UserInformation!: RegisterUserInfoModel;
  SelectYourPlan!: RegisterSelectYourPlanModel;
  BillingInformations!: RegisterBillingInfoModel;
}

export class AdminRegisterModel {
  AccountInformation!: RegisterAccountInfoModel;
  UserInformation!: RegisterUserInfoModel;
}

export class RegisterAccountInfoModel {
  TenancyName: string;
  Name: string;
  Address: string;
  Address2: string;
  City: string;
  county: string;
  State: string;
  ZipCode: string;
  PhoneNumber: string;
  Logo: string;
  AccountName: string;
  Country: any;
  States: any[];
  CountryCode: string;
  Countries: any[];
  addressFormat: number = 0;
  address: AddressModel = new AddressModel();

  constructor() {
    this.TenancyName = '';
    this.Name = '';
    this.Address = '';
    this.Address2 = '';
    this.City = '';
    this.county = '';
    this.State = '';
    this.ZipCode = '';
    this.PhoneNumber = '';
    this.Logo = '';
    this.AccountName = '';
    this.States = [];
    this.CountryCode = '';
    this.Countries = [];
  }
}

export class RegisterUserInfoModel {
  UserId: number = 0;
  TenantId: number = 0;
  FirstName: string = '';
  LastName: string = '';
  InitEmailAddress: string = '';
  EmailAddress: string = '';
  Password: string = '';
  ConfirmPassword: string = '';
  ProfileImage: string = '';
  TenantName: string = '';
  HelpLink: string = '';
  userAvailableLanguageIds: number[] = [];
  InternalUseOnly: boolean = false;
}

export class RegisterSelectYourPlanModel {
  public static readonly PLAN_NAME_FULL_ACCESS: string = 'Full Access';
  public static readonly PLAN_NAME_CREATOR_EDITOR: string = 'Creator/Editor';
  planName!: string;
  trialPeriod: number = 0;
  numberUsers: number = 0;
  numberConCurrentUsers: number = 0;
  planType: number = PlanType.PayMonthly;
  editionId: number = Edition.FullAccess;
  // planPrice: number = 0;
  planId: string = '';
  UserNumberRange: string = '';
  trialReminderAfterDays: number = 0;
  languages: number[] = [];
  defaultLanguage!: number;
  apiAccessChecked: boolean = false;
  localConCurrentNumber: number = 0;
  localUserNumber: number = 0;
  isApiAccessFeatureEnable: boolean = false;
  currency: CurrencyModel = new CurrencyModel();

  get isNumberUserCorrect(): boolean {
    let result: boolean = true;
    if (isNaN(this.numberUsers)) {
      this.numberUsers = 0;
    }
    if (this.numberUsers === 0) {
      result = false;
    }
    return result;
  }

  get numberConCurrentUserCorrect(): number {
    let result: number = 0;
    if (isNaN(this.numberConCurrentUsers)) {
      this.numberConCurrentUsers = 0;
    }
    if (this.numberConCurrentUsers < 1) {
      result = 1;
    }
    else if (this.numberConCurrentUsers > this.numberUsers)
    {
      result = 2;
    }
    return result;
  }

  get originalPrice(): number {
    let price: number = 0;
    switch (this.editionId) {
      case Edition.FullAccess:
        price = 10;
        if (this.numberUsers >= 1 && this.numberUsers <= 500) price = 10;
        else if (this.numberUsers >= 501 && this.numberUsers <= 2500) price = 8;
        else if (this.numberUsers >= 2501 && this.numberUsers <= 5000) price = 6;
        else if (this.numberUsers >= 5001 && this.numberUsers <= 10000) price = 4;
        else if (this.numberUsers >= 10001 && this.numberUsers <= 20000) price = 2;
        else if (this.numberUsers > 20000) price = 1;
        break;
      case Edition.CreatorEditor:
        price = 9;
        if (this.numberUsers >= 1 && this.numberUsers <= 20) price = 9;
        else if (this.numberUsers >= 21 && this.numberUsers <= 150) price = 8;
        else if (this.numberUsers >= 151) price = 7;
        break;
    }

    return price;
  }

  get discount(): number {
    return 5;
  }

  get planTypeUnitText(): string {
    let result: string = '';
    PlanTypeUnitText.forEach(planTypeUnitText => {
      if (this.planType === planTypeUnitText.id) {
        result = planTypeUnitText.text;
      }
    });

    return result;
  }

  get planTypeUnitId(): number {
    return this.planType;
  }

  // set price(price: number) {
  //   this.planPrice = price;
  // }

  // get totalPrice(): number {
  //   if (this.planType === PlanType.PayYearly) {
  //     return (this.originalPrice * 12 * ((100 - this.discount) / 100)) * (this.numberUsers || 0);
  //   }

  //   return this.originalPrice * (this.numberUsers || 0);
  // }

  // get price(): number {
  //   if (this.planType === PlanType.PayYearly) {
  //     return (this.originalPrice * 12 * ((100 - this.discount) / 100));
  //   }

  //   return this.originalPrice;
  // }
}

export enum Edition {
  FullAccess = 1,
  CreatorEditor = 2
}

export enum PlanType {
  PayMonthly = 1,
  PayYearly = 2
}

export enum OneTimeBillingType {
  TranslationFee = 1
}

export const PlanTypeText = [
  {id: PlanType.PayMonthly, name: 'PAY_MONTHLY', text: 'Pay Monthly'},
  {id: PlanType.PayYearly, name: 'PAY_YEARLY', text: 'Pay Yearly'}
];

export const PlanTypeUnitText = [
  {id: PlanType.PayMonthly, text: 'per user/ per month'},
  {id: PlanType.PayYearly, text: 'per user/ per year'}
];


export class RegisterBillingInfoModel {
  Address: string;
  Address2: string;
  City: string;
  county: string;
  State: string;
  ZipCode: string;
  Bank: string;
  InvoiceType: string;
  CreditCards: RegisterCreditCardModel[] = [];
  ACHs: RegisterACHModel[] = [];
  Country: any;
  CurrencyId: number;

  constructor() {
    this.Address = '';
    this.Address2 = '';
    this.City = '';
    this.county = '';
    this.State = '';
    this.ZipCode = '';
    this.Bank = '';
    this.InvoiceType = '';
    this.CurrencyId = 0;
  }
}

export class RegisterCreditCardModel {
  Id: string;
  NameOnCard: string;
  CardNumber: string;
  CVC: string;
  Expire: string;
  ExpMonth: string;
  ExpYear: string;
  Brand: string;

  constructor() {
    this.Id = '';
    this.NameOnCard = '';
    this.CardNumber = '';
    this.CVC = '';
    this.Expire = '';
    this.ExpMonth = '';
    this.ExpYear = '';
    this.Brand = '';
  }
}

export class RegisterACHModel {
  Id: string;
  NameOnAccount: string;
  PhoneNumber: string;
  AccountType: string;
  RoutingNumber: string;
  AccountNumber: string;
  EndingAccountNumber: string;
  SelectedAccountType!: string;

  constructor() {
    this.Id = '';
    this.NameOnAccount = '';
    this.PhoneNumber = '';
    this.AccountType = '';
    this.RoutingNumber = '';
    this.AccountNumber = '';
    this.EndingAccountNumber = '';
  }
}

export class RegisterDepartAppliModel {
  Departments: RegisterDepartmentModel[] = [];
  Categories: CategoryItemModel[] = [];
}

export class RegisterDepartmentModel {
  Id: string;
  DepartmentName: string;

  constructor() {
    this.Id = '';
    this.DepartmentName = '';
  }
}

export class RegisterApplicationModel {
  Id: string;
  ApplicationName: string;

  constructor() {
    this.Id = '';
    this.ApplicationName = '';
  }
}

export class InvitationUser {
  invitationKey = '';
  UserInfo!: RegisterUserInfoModel;
  Applications: ApplicationModel[] = [];
  UserCategories: UserCategoryItemModel[] = [];
}

export enum RegisterTabStatus {
  Register_Account_Info = 1,
  Register_User_Info = 2,
  Register_Select_Plan = 3,
  Register_Billing_Info = 4
}

export class RegisterTabModel {
  private static DisabledTabs: RegisterTabStatus[] = [RegisterTabStatus.Register_User_Info, RegisterTabStatus.Register_Select_Plan,
    RegisterTabStatus.Register_Billing_Info];

  public static resetDisabledTabs() {
    RegisterTabModel.DisabledTabs = [RegisterTabStatus.Register_User_Info, RegisterTabStatus.Register_Select_Plan,
      RegisterTabStatus.Register_Billing_Info];
  }

  public static getDisabledTabs() {
    return this.DisabledTabs;
  }

  public static setCurrentTab(tab: RegisterTabStatus) {
    if (this.DisabledTabs.length < 1)
      return;
    let newDisabledTabs: RegisterTabStatus[] = [];
    this.DisabledTabs.forEach(function (disabledTab) {
      if (tab !== disabledTab && disabledTab > tab) {
        newDisabledTabs.push(disabledTab);
      }
    });
    this.DisabledTabs = newDisabledTabs;
  }
}
