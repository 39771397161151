export class LoginModel {
  TenancyName: string;
  UsernameOrEmailAddress: string;
  Password: string;
  RememberMe: boolean;
  tenancyAsDefaultLogin: string = '';
  platform: TokenPlatform = TokenPlatform.PortalWeb;
  constructor() {
    this.TenancyName = '';
    this.UsernameOrEmailAddress = '';
    this.Password = '';
    this.RememberMe = false;
    this.tenancyAsDefaultLogin = '';
    this.platform = TokenPlatform.PortalWeb;
  }
}

export class UserResetPassModel {
  passwordResetCode: string = '';
  password: string = '';
}

export enum TokenPlatform {
  PortalWeb = 1,
  ImpersonateWeb = 2,
  WebRecorder = 3,
  AppRecorder = 4,
  WebAdvisor = 5,
  AppAdvisor = 6,
  ApiAccess = 7,
  Sso = 8,
  InternalSystem = 9,
  WebFMAdvisor = 10,
  Scim = 11
}
