import { DepartmentModel } from './department.model';

export class InvitationModel {
  name: string;
  surname: string;
  emailAddress: string;
  userType: string;
  department: DepartmentModel [] = [];
  languages: number[] = [0];

  constructor() {
    this.name = "";
    this.surname = "";
    this.emailAddress = "";
    this.userType = "";
    this.languages = [0];
  }
}

export enum InvitationTabStatus{
  Invite_User_Info = 1,
  Invite_Appli_Know = 2
}

export class InvitationTabModel {
  private static InviteDisabledTabs: InvitationTabStatus[] = [InvitationTabStatus.Invite_Appli_Know];

  public static getDisabledTabs(){
    return this.InviteDisabledTabs;
  }

  public static setCurrentTab(tab: InvitationTabStatus){
    if(this.InviteDisabledTabs.length < 1)
      return;
    let newDisabledTabs: InvitationTabStatus[] = [];
    this.InviteDisabledTabs.forEach(function (disabledTab) {
      if(tab !== disabledTab && disabledTab > tab){
        newDisabledTabs.push(disabledTab);
      }
    });
    this.InviteDisabledTabs = newDisabledTabs;
  }
}

export class ResendInviteModel extends InvitationModel {
  id: number;
  userId: number;
  emailAddressOld: string;
  constructor() {
    super();
    this.userId = 0;
    this.emailAddressOld = '';
    this.id = 0;
  }
}
