export class Point {
  constructor(
    public x: number = 0,
    public y: number = 0) {}

  fromString(p: string): Point {
    if (!p) {
      return this;
    }

    let values = p.split(',');
    this.x = parseInt(values[0], 10) || 0;
    this.y = parseInt(values[1], 10) || 0;
    return this;
  }

  toString(): string {
    return `${this.x}, ${this.y}`;
  }

  fromJson(json: any): Point {
    if (!json) {
      return this;
    }

    this.x = json.X || 0;
    this.y = json.Y || 0;
    return this;
  }
}
