import { SeriesVolumeModel } from './process.model';

export class LocaleModel{
  public static readonly defaultLocaleId: number = 1033;
  id: number = 0;
  code: string = '';
  isoCode: string = '';
  charset: number = 0;
  englishName: string = '';
  rootId: number = 0;
  isRightToLeft: boolean = false;
  isActive: boolean = false;
  // For MultiSelectOption
  name: string = '';
  isLabel?: boolean;
  parentId?: any;
  params?: any;
  leastOneUsed!: boolean;

  public static getBrowserISOLocale(localeId: number): string {
    switch (localeId) {
      case 1033:
        return 'en';
      case 1031:
        return 'de';
      case 1036:
        return 'fr';
      default:
        return '';
    }
  }

  fromJson(json: any) {
    if (!json) {
      return this;
    }

    this.id = json.id;
    this.code = json.code;
    this.isoCode = json.isoCode;
    this.charset = json.charset;
    this.englishName = json.englishName;
    this.name = json.englishName;
    this.rootId = json.rootId;
    this.isRightToLeft = json.isRightToLeft;
    this.isActive = json.isActive;

    return this;
  }

  toJson() {
    return {
      id: this.id,
      code: this.code,
      isoCode: this.isoCode,
      charset: this.charset,
      englishName: this.englishName,
      rootId: this.rootId,
      isRightToLeft: this.isRightToLeft
    }
  }

  static sortByStringNameDesc(a: LocaleModel, b: LocaleModel) {
    let nameA = a.englishName.toUpperCase();
    let nameB = b.englishName.toUpperCase();
    if (nameA < nameB) {
      return 1;
    }
    if (nameA > nameB) {
      return -1;
    }
    return 0;
  }

  static sortByStringNameAsc(a: LocaleModel, b: LocaleModel) {
    let nameA = a.englishName.toUpperCase();
    let nameB = b.englishName.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  static sortByStringNameAscEnglishToTop(langA: any, langB: any, columnName: string) {
    columnName = columnName ? columnName : 'name';
    if ((langA.id && (langA.id === LocaleModel.defaultLocaleId || langA.id === 1))
      || (langA.localeId && langA.localeId === LocaleModel.defaultLocaleId)) {
      return -1;
    }
    if ((langB.id && (langB.id === LocaleModel.defaultLocaleId || langB.id === 1))
      || (langB.localeId && langB.localeId === LocaleModel.defaultLocaleId)) {
      return 1;
    }

    let nameA: string = langA[columnName] ? langA[columnName].toUpperCase() : '';
    let nameB: string = langB[columnName] ? langB[columnName].toUpperCase() : '';

    return nameA.localeCompare(nameB);
  }

  static sortByStringIsoCodeAsc(a: LocaleModel, b: LocaleModel) {
    let nameA = a.isoCode.toUpperCase();
    let nameB = b.isoCode.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }
}

export enum BrowserLocale {
  English = 1033,
  French = 1036,
  Germany = 1031
}
