export class BccEmailNotificationModel {
  id: number;
  userId: string;
  isEnabled: boolean;
  emailType: EnumMailType;
  isPersonalItem: boolean;

  static fromJson(json: any): BccEmailNotificationModel {
    return new BccEmailNotificationModel().fromJson(json);
  }

  fromJson(json: any): BccEmailNotificationModel {
    if (!json) {
      return this;
    }

    this.id = json.id;
    this.userId = json.userId;
    this.isEnabled = json.isEnabled;
    this.emailType = json.emailType;
    this.isPersonalItem = json.isPersonalItem;
    return this;
  }
}

export enum EnumMailType {
  ConfirmRegistration = 1,
  EpilogueConfirmUser = 2,
  ResetYourEpiloguePassword = 3,
  PasswordResetSuccessfully = 4,
  RegistrationPending = 5,
  ProjectDueDatesChanged = 6,
  ProjectDueDatePassed = 7,
  ProjectDueDateApproaching = 8,
  ProjectPublished = 9,
  NewAdminAssignedToProject = 10,
  TaskDueDateApproaching = 11,
  TaskDueDateHasPassed = 12,
  TaskAssignedToMe = 13,
  TaskPublished = 14,
  SystemAlert = 15
}
